import Modal from 'components/Global/Modal'
import { formatDecimal, formatShares } from 'utils'

const PricingIndicatorModal = ({ hideModal, tiers }) => (
  <Modal
    modalHeader='Pricing Tiers'
    hideModal={hideModal}
    crossToClose
    clickToClose
    innerStyle='text-left'
  >
    <div className='medium_1 content'>
      {tiers.map((t, i) => <p key={i}>Tier {i + 1} - ${formatShares(t.minPurchase)} - {formatDecimal(t.sharePrice)}/unit</p>)}
    </div>
  </Modal>
)

export default PricingIndicatorModal
