import { useSelector } from 'react-redux'
import Button from 'components/Global/Button'

const FBOTotalFunds = ({ addFunds, withdraw }) => {
  const { availableBalance } = useSelector((state) => state.walletSlice)

  const totalInvestmentValueArr = availableBalance.toString().split('.')

  let decimal = totalInvestmentValueArr[1]
  if (decimal && decimal.length === 1) {
    decimal += '0'
  }

  return (
    <div className='total-investment-container'>
      <div className='investment-total-container'>
        <p className='giant_3 margin-0 text-text-hyperlink'>
          ${(+totalInvestmentValueArr[0]).toLocaleString('en-US')}
          <sup className='medium_1 super-script text-text-hyperlink'>{decimal || '00'}</sup>
        </p>
        <span className='text-text-default'>Available Balance</span>
      </div>
      <div className='flex gap-4'>
        <Button size='sm' mode={availableBalance > 0 ? 'secondary' : 'primary'} onClick={addFunds}>
          Add Funds
        </Button>
        {availableBalance > 0 && <Button size='sm' onClick={withdraw} >
          Withdraw
        </Button>}
      </div>
    </div>
  )
}

export default FBOTotalFunds
