import { useState } from 'react'
import Tooltip from 'components/Global/Tooltip'
import { formatDecimal, formatShares } from 'utils'
import { availableSharesToSellTooltip } from 'components/data'

const PortfolioDetailSummary = ({ company }) => {
  const [showAvailableSharesHover, setAvailableSharesHover] = useState(false)

  return (
    <div className='flex-col items-end'>
      <div className='border border-stroke-on-white-light order-list-summary text-text-default'>
        <div className='order-list-summary-row'>
          <div>Investment Amount</div>
          <div className='number'>{formatDecimal(company.purchased)}</div>
        </div>
        <div className='order-list-summary-row'>
          <div>Average Cost/Unit</div>
          <div className='number'>{formatDecimal(company.averageSharePrice)}</div>
        </div>
        <div className='order-list-summary-row'>
          <div>Units</div>
          <div className='number'>{formatShares(company.shares)
          }</div>
        </div>
        <div className='flex items-center text-text-default order-list-summary-row-tooltip'>
          <div className='flex items-center'>Units Available to Sell
            <Tooltip
              content={availableSharesToSellTooltip}
              showHover={showAvailableSharesHover}
              setShowHover={setAvailableSharesHover}
            />
          </div>
          <div className='number'>{formatShares(company.sharesAvailableToSell || 0)
          }</div>
        </div>
      </div>
    </div>
  )
}

export default PortfolioDetailSummary
