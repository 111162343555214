import PropTypes from 'prop-types'
import { images } from 'assets'

/**
 * Article list item component that displays an article title with a clickable link
 * @component
 * @param {Object} props - Component props
 * @param {string} props.title - The title of the article
 * @param {string} props.url - The URL or ID of the article
 * @param {Function} props.onClick - Callback function when article is clicked
 * @returns {React.ReactElement} A clickable article item
 */
const ArticleListItem = ({ title, url, onClick }) => {
  return (
    <div 
      className='flex items-center justify-between px-8 py-4 border rounded-lg cursor-pointer border-stroke-on-white-light hover:bg-gray-50'
      onClick={() => onClick(url)}
    >
      <div className='flex flex-col'>
        <span className='medium_2'>{title}</span>
      </div>
      <img src={images['arrow_forward']} alt={title} />
    </div>
  )
}

ArticleListItem.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired
}

export default ArticleListItem 