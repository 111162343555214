import { useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import { images } from 'assets'
import { PageLoading } from 'components'
import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE, useLDFlags } from 'utils'
import { getChartData, getPlaceholderData } from './partials/queries'
import { PriceHistoryChartRenderer } from './partials/PriceHistoryChartRenderer'
import { contentfulContentIds } from 'components/data'
import { PriceHistoryChartBanner } from './partials/PriceHistoryChartBanner'
import { getLabelContent, getBannerContent, getModalContent } from 'utils/Contentful'
import useContentful from 'hooks/useContentful'

const CONTENT_FIELDS = {
  COMING_SOON: 'comingSoonLabel',
  ERROR: 'errorLabel',
  UPDATE: 'updateLabel',
  BANNER: 'footnoteBanner',
  MODAL: 'footnoteModal'
}

const ChartPlaceholder = ({ copy, icon, alt='' }) => {
  const placeholderData = getPlaceholderData()
  return (
    <div className='relative'>
      <div className='opacity-40'>
        {PriceHistoryChartRenderer(placeholderData, false)}
      </div>
      <div data-testid='chart-overlay' className='absolute inset-0 flex items-center justify-center bg-background-default/50 backdrop-blur-sm'>
        <div className='flex flex-col items-center w-11/12 md:w-[330px] px-8 md:px-6 py-6 bg-background-default rounded-2xl border border-stroke-on-white-light gap-4'>
          <img src={icon} alt={alt} />
          <p className='text-center medium_1 gray-5'>
            {copy}
          </p>
        </div>
      </div>
    </div>
  )
}

const PriceHistoryChart = ({ companyUrlName, promoFlag }) => {
  const { showPriceHistoryChart } = useLDFlags(['showPriceHistoryChart'])
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE
  const { content: contentfulData } = useContentful(contentfulContentIds.priceHistoryChatId)
  const [contentLabels, setContentLabels] = useState({
    comingSoonLabel: '',
    errorLabel: '',
    updateLabel: '',
    banner: null
  })
  const isComingSoon = promoFlag === 'COMING_SOON'

  const { 
    data: chartData, 
    isLoading,
    isError 
  } = useQuery({
    queryKey: ['chartData', companyUrlName],
    queryFn: () => getChartData(companyUrlName),
    enabled: !!companyUrlName && showPriceHistoryChart,
    select: (data) => ({
      priceHistoryData: {
        priceHistory: data.priceHistory.map(item => {
          const date = new Date(item.date)
          return {
            date: item.date,
            price: item.sharePrice,
            dateString: item.dateString,
            month: date.getMonth(),
            year: date.getFullYear()
          }
        }).sort((a, b) => a.date - b.date)
      },
      lastUpdateData: {
        lastPriceChange: data.lastPriceChange
      },
      companyName: data.companyName
    })
  })

  useEffect(() => {
    if (!contentfulData) return

    const items = contentfulData?.fields?.items || []
   
    setContentLabels({
      comingSoonLabel: getLabelContent(CONTENT_FIELDS.COMING_SOON, items),
      errorLabel: getLabelContent(CONTENT_FIELDS.ERROR, items),
      updateLabel: getLabelContent(CONTENT_FIELDS.UPDATE, items),
      banner: getBannerContent(CONTENT_FIELDS.BANNER, items, { companyName: chartData?.companyName }),
      modal: getModalContent(CONTENT_FIELDS.MODAL, items, {}, false) 
    })
  }, [contentfulData, chartData])


  const renderContent = () => {
    if (isComingSoon) {
      return (
        <div className='w-full px-4 md:px-8'>
          <ChartPlaceholder copy={contentLabels.comingSoonLabel} icon={images.price_history_chart_coming_soon} alt='Price History coming soon' />
        </div>
      )
    }

    if (isLoading) {
      return (
        <div className='flex items-center h-64 md:h-96'>
          <PageLoading testId='chart-loading' type='chart' />
        </div>
      )
    }
    
    if (isError || chartData?.priceHistoryData?.priceHistory.length === 0) {
      return (
        <div className='w-full px-4 md:px-8'>
          <ChartPlaceholder copy={contentLabels.errorLabel} icon={images.price_history_chart_error} alt='Price History Error' />
        </div>
      )
    }
   
    return PriceHistoryChartRenderer(chartData, isMobile, contentLabels.updateLabel)
  }

  if (!showPriceHistoryChart) {
    return null
  }
  return (
    <div id='pricingHistory' data-testid='pricing-history-chart' className='mt-10'>
       <h1 className='text-text-default'>
        Price History
      </h1>
      <div className='w-full md:border md:py-8 md:rounded-2xl border-stroke-on-white-light'>
        {renderContent()}
      </div>
      {contentLabels.banner && chartData?.companyName && !isComingSoon && (
        <PriceHistoryChartBanner 
          banner={contentLabels.banner}
          modal={contentLabels.modal}
        />
      )}
    </div>
  )
}

export default PriceHistoryChart