import { useSelector } from 'react-redux'

const LinqtoWalletTotalFunds = () => {
  const { accounts, linqtoBucks, purchaseCredits } = useSelector(state => state.walletSlice)
  const accountTotal = (filteredAccounts) => {
    return filteredAccounts.reduce((total, obj) => obj.amountAvailable + total, 0)
  }

  const accountCashTotal = accountTotal(accounts?.filter((item) => !('entityId' in item)))
  const totalInvestmentValueArr = (accountCashTotal + linqtoBucks + purchaseCredits).toString().split('.')

  let decimal = totalInvestmentValueArr[1]
  if (decimal && decimal.length === 1) {
    decimal += '0'
  }

  return (
    <div className='total-investment-container'>
      <div className='investment-total-container'>
        <p className='giant_3 margin-0 text-text-hyperlink'>${(+totalInvestmentValueArr[0]).toLocaleString('en-US')}<sup className='medium_1 super-script text-text-hyperlink'>{decimal || '00'}</sup></p>
        <span className='text-text-default'>Total Funds Available at Linqto</span>
      </div>
    </div>
  )
}

export default LinqtoWalletTotalFunds
