import OrderModalAccountItem from 'components/ConfrimOrder/partials/OrderModalAccountItem'
import { images } from 'assets'
import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'

const UpholdAccounts = ({
  hideModal,
  setSelectedAccount,
  insufficientError,
  setInsufficientError,
  setShowWireInstruction,
  addMargin,
  type
}) => {
  const { featureFlags } = useSelector(state => state.userSlice)

  const { upholdError, upholdCards, upholdUnderReview } = useSelector((state) => state.walletSlice)

  const handleOnSelect = (acc) => {
    setSelectedAccount({ ...acc, method: 'Uphold' })

    if (insufficientError) {
      setInsufficientError(null)
    }
    hideModal()

    if (type === 'funding') {
      setShowWireInstruction(false)
      Mixpanel.track('Select Funding Method', { 'Funding Method': 'Uphold', Currency: acc.currency })
    }
  }

  if (type === 'withdraw' && !featureFlags.UpholdWithdrawalEnabled) {
    return null
  }

  return (
    <div className='order-uphold'>
      <div className={`flex items-center justify-between p-4 ${!upholdUnderReview ? null : 'bg-background-mid-gray rounded-xl h-[80px]'} ${addMargin ? 'margin' : null}`}>
        <div className='flex items-center medium_2'>
          <img className='w-10 h-10' src={images['wallet-uphold']} alt='uphold' />
          <span className='ml-2 -mt-3 medium_2 md:mt-0'>Uphold</span>
        </div>
        <span className='text-text-default note'>{!upholdUnderReview ? 'Fees may apply' : 'Under Review'}</span>
      </div>
      {upholdError && (
        <div className='mt-2 medium_1 text-text-default'>
          Please check your email. {upholdError}
        </div>
      )}
      {upholdCards?.map((account) => (
        <OrderModalAccountItem
          key={account.id}
          account={account}
          onSelect={(acc) => handleOnSelect(acc)}
        />
      ))
      }
    </div>
  )
}

export default UpholdAccounts
