import React from 'react'

const HistoryColumns = ({ showCompany }) => {
  return (
    <div className={`history-grid-container${showCompany ? '__columns' : '__columns no_company'}`}>
      <span className='medium_1 text-left'>DATE</span>
      {showCompany && <span className='medium_1 text-left'>PRODUCT</span>}
      <span className='medium_1 text-center'>TYPE</span>
      <span className='medium_1 text-right'>UNITS</span>
      <span className='medium_1 text-right'>UNIT PRICE</span>
      <span className='medium_1 text-right'>TOTAL</span>
    </div>
  )
}

export default HistoryColumns
