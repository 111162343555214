import { formatDecimal } from 'utils'
import { images } from 'assets'
import { Button } from 'components'

const OrderModalAccountItem = ({ account, onSelect, disabled = false, customClass = '' }) => {
  return (
    <Button
      className={`border border-stroke-on-white-light order-modal-account-item min-w-full ${customClass} ${disabled && 'disabled'} ${account.label === 'Wire Transfer' ? 'wire-account' : ''} hover:text-text-default`}
      onClick={() => !disabled && onSelect && onSelect(account)}
      ariaLabel={`Select ${account.label} account`}
    >
      <div className='medium_2'>
        <img alt={account.label} src={account.iconUrl} className='mr-2 account-icon' />
        {account.label}
      </div>
      <div className='medium_2'>
        {account.balance !== null && formatDecimal(account.balance)}
        {!account?.balance && account?.usdBalance !== null && formatDecimal(account.usdBalance)}
        {account?.description && <span className='text-left medium_1 text-text-default'>{account.description}</span>}
        <img src={images['right-arrow']} alt={account.description} className='account-arrow' />
      </div>
    </Button>
  )
}

export default OrderModalAccountItem
