import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE } from 'utils'

const SignUpHeader = ({ pathname }) => {
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE
  const showHeader = (pathname === '/signup') || (pathname === '/signup/create-account' && !isMobile)

  if (!showHeader) return null
  return (
    <div className='flex justify-center items-center'>
      <h1 className='mt-0 mb-4 lg:mb-9 lg:mt-14 text-text-default large_1'>Create An Account</h1>
    </div>
  )
}

export default SignUpHeader
