import Button from 'components/Global/Button'

const SecondaryButton = ({ text, onClick }) => {
  return (
    <Button className='medium_2 text-text-default secondary-button' onClick={onClick} ariaLabel={text}>
      <span className='medium_2 key-facts__more-facts text-text-default border-b-[3px] gradient-border-turq-mint'>{text}</span>
    </Button>
  )
}

export default SecondaryButton
