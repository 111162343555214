import { useFormContext } from 'react-hook-form'
import TooltipLabel from '../TooltipLabel'

const Checkbox = ({
  label,
  name,
  value,
  disabled = false,
  ariaLabel = null,
  onChange = () => {},
  checkboxClass = '',
  required = false,
  children,
  content = '',
  extraLabel=''
}) => {
  const { register, formState: { errors } } = useFormContext()

  return (
    <div className='flex justify-center'>
      <div className={`input-group checkbox ${errors[name] ? 'error' : ''} ${checkboxClass !== '' && checkboxClass}`} role='button'>
        <label className='text-text-default checkbox-label'>
          <input
            type='checkbox'
            {...register(name, {
              onChange,
              required
            })}
            value={value}
            disabled={disabled}
            aria-label={ariaLabel || label || ''}
            data-testid={name}
            className="peer"
          />
          <div className={`checkmark bg-background-default after:border-icon-on-dark-bg peer-disabled:bg-background-mid-gray peer-checked:peer-disabled:bg-background-dark-gray peer-disabled:border-stroke-on-white-light peer-disabled:border-[1.5px] border-[1.5px] border-stroke-on-white-dark peer-checked:border-stroke-cobalt-blue peer-checked:bg-fill-cobalt-primary ${errors[name] ? 'border-stroke-alert-red' : ''}`}/>
          <span className={`medium_1 checkbox-label__text ${errors[name] ? 'text-text-error' : ''}`}>{label || children}</span>
        </label>
      </div>
      {content && <TooltipLabel content={content} label='' extraLabel={extraLabel} htmlFor={name} header={label} />}
    </div>
  )
}

export default Checkbox
