import { images } from 'assets'
import { useHistory } from 'react-router-dom'
import { Button } from 'components'

const Breadcrumb = ({ items }) => {
  const history = useHistory()
  return (
    <div className='flex py-1 mb-4'>
      {items.map((item, i) => {
        const isLastItem = i === items.length - 1
        return (
          <div className='flex' key={`bc-${i}`}>
            <Button
              onClick={() => history.push(item.route)}
              className='cursor-pointer text-text-hyperlink'
              ariaLabel={`Navigate to ${item.name}`}
              disabled={isLastItem}
            >
              {item.name}
            </Button>
            <img className='mx-4 w-1 lg:mx-6' src={images['breadcrumb-arrow']} alt={item.name} />
          </div>
        )
      })}
    </div>
  )
}

export default Breadcrumb
