import Breadcrumb from 'components/Global/Breadcrumb'
import SeoMeta from 'components/Global/SeoMeta'
import { Button, PageLoading } from 'components'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { getSellOrderDetails } from 'slices/sellOrderSlice'
import { seoTitleTemplate, Mixpanel, formatDecimal, formatShares } from 'utils'
import SellOrderItem from './partials/SellOrderItem'
import SellOrderSummaryContainer from './partials/SellOrderSummaryContainer'
import SellOrderPurchaseCredit from './partials/SellOrderPurchaseCredit'

const SellOrderDetail = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const { companyUrlName, companyName, pageLoading, sellOrder, companyIconUrl } = useSelector(state => state.sellOrder)
  const { id } = useParams()
  const items = [{ name: 'My Portfolio', route: '/portfolio' }, { name: companyName, route: `/portfolio/${companyUrlName}` }]
  const { featureFlags } = useSelector(state => state.userSlice)

  useEffect(() => {
    dispatch(getSellOrderDetails(id)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Sell Order Details Page (ATS)', { 'Company Name': payload?.company?.name, Amount: payload?.sellOrder?.amount, 'Purchase Credits': payload?.sellOrder?.purchaseCreditsEarned })
      }
    })
  }, [id])

  const linkToOrder = (id) => history.push(`/order/${id}`)

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Sell Order Details')} />
        <PageLoading />
      </>
    )
  }

  const { breakdown, offer } = sellOrder || {}
  const { buyOrders } = breakdown || []

  let { avgCostPerShare, totalCost, totalGain } = breakdown || {}
  avgCostPerShare = formatDecimal(avgCostPerShare)
  totalCost = formatDecimal(totalCost)
  totalGain = formatDecimal(totalGain)

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Offer Confirmed')} />
      <div className='page-container sell-order-container'>
        <div className='inner-container'>
          <Breadcrumb items={items} />
          <div className='sell-order-header'>
            <h1>Sell Order</h1>
            <div className='sell-order-company-name'>
              <img className='border border-stroke-on-white-light' src={companyIconUrl} alt={companyName}/>
              <p className='giant_1'>{companyName}</p>
            </div>
          </div>
          <div className='sell-order-page-container'>
            <SellOrderSummaryContainer />
            <div className='sell-order-summary-container__bottom-container top'>
              <header className='text-text-default sell-order-page-container__sub-header large_1'>Cost Basis and Gains</header>
              {buyOrders && buyOrders.length ? buyOrders.map(transaction => (
                <div className='sell-order-summary-container__row' key={transaction?.orderId}>
                  <div className='sell-order-summary-container__row__cost-basis order-container'>
                    <Button className='medium_2 sell-order-summary-container__row__cost-basis text-text-hyperlink pointer' onClick={() => linkToOrder(transaction?.orderId)}>{transaction?.name}</Button>
                    <span className='sell-order-summary-container__row__cost-basis text-text-default'>{formatShares(transaction?.shares)} units</span>
                  </div>
                  <span className='sell-order-summary-container__row__copy'>{formatDecimal(transaction?.amount)}</span>
                </div>
              )) : null}
              <div className='m-4 sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__cost-basis text-text-default'>Average Cost / Unit</span>
                <span className='sell-order-summary-container__row__cost-basis text-text-default'>{avgCostPerShare}</span>
              </div>
              <div className='m-4 sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__cost-basis text-text-default'>Total Cost</span>
                <span className='sell-order-summary-container__row__cost-basis text-text-default'>{totalCost}</span>
              </div>
              <div className='sell-order-summary-container__row footer'>
                <span className='medium_2 sell-order-summary-container__row__cost-basis text-text-default'>Total Gain</span>
                <span className='medium_2 sell-order-summary-container__row__cost-basis text-text-default'>{totalGain}</span>
              </div>
              {featureFlags?.PurchaseCreditEnabled && <SellOrderPurchaseCredit />}
            </div>
            <div className='sell-order-summary-container__bottom-container'>
              <header className='text-text-default sell-order-page-container__sub-header large_1'>Sell Offer Details</header>
              <span className='sell-order-summary-container__row__cost-basis text-text-default'>
                Your sell offer may be executed over multiple sell orders if an investor does not buy the full amount. View your complete sell offer details below. All sales are final.
              </span>
              {offer ? <SellOrderItem order={offer} handleClick={() => history.push(`/offer/${offer?.sellOfferId}`)} /> : null}
            </div>
            <div className='medium_1 space-above-sm'>
            Have a question about your order?{' '}
              <Button onClick={() => {
                history.push(`/contact?topic=Order Support&message=Re: Sell Order ID ${id}`)
                Mixpanel.track('Click Contact Us', { Location: 'Sell Order Details' })
              }
              }
              className='link text-text-hyperlink contact-us'>
                Contact Us
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SellOrderDetail
