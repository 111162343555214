import { useSelector } from 'react-redux'
import { Mixpanel } from 'utils'
const CountryDropdown = ({
  setIdAllowed,
  selectedCountry,
  setSelectedCountry
}) => {
  const { countries, dropDownLoading } = useSelector(state => state.commonSlice)
  const { countriesWithAlternateId } = useSelector(state => state.kyc)

  return (
    <div className='input-group'>
      <select
        value={selectedCountry}
        disabled={dropDownLoading}
        onChange={(e) => {
          Mixpanel.track('Change Country on Choose ID Type Page', { Country: e.target.value })
          setSelectedCountry(e.target.value)
          if (countriesWithAlternateId.filter(country => country === e.target.value).length === 1) {
            setIdAllowed(true)
          } else {
            setIdAllowed(false)
          }
        }}
        className='border bg-background-default border-stroke-on-white-dark text-text-default hover:border-stroke-cobalt-blue hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue'
      >
        {countries &&
          countries.map((country, i) => (
            <option value={country} key={`countries-${i}`}>
              {country}
            </option>
          ))}
      </select>
    </div>
  )
}

export default CountryDropdown
