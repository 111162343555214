import { images } from 'assets'
import { Button } from 'components'

const TopBanner = ({
  hideBanner,
  bannerAction,
  title,
  body,
  CTAText = '',
  type = 'confirmation'
}) => {
  return (
    <div className={`bg-background-mid-gray border-l-8 border-l-logo-cobalt account-banner-container top-banner-contanier ${type === 'error' ? 'border-l-8 border-l-stroke-alert-red' : ''}`}>
      <div className='account-banner-header'>
        <div className='large_1'>{title}</div>
      </div>
      <div className='account-banner-body'>
        <div className='medium_1 text-text-default'>{body}</div>
        {CTAText && (
          <Button className='cursor-pointer medium_1 text-text-hyperlink' onClick={bannerAction}>
            {CTAText}
          </Button>
        )}
      </div>
      <Button className='account-banner-close' onClick={hideBanner} ariaLabel='Close Banner'>
        <img className='about-hero' alt='Close Banner' src={images['banner-close']} />
      </Button>
    </div>
  )
}

export default TopBanner
