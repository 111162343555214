import { Modal, Button } from 'components'
import { useLDFlags, Mixpanel } from 'utils'
import { useHistory } from 'react-router-dom'
import { images } from 'assets'

const verfiedIcon = images['accreditation-verified-status']
const connectSpecialistImage = images['investor-status-specialist']
const USSuccessModal = ({ hideModal }) => {
  const history = useHistory()

  const { uploadDocumentModal } = useLDFlags(['uploadDocumentModal'])

  const modalCTA = (interested) => {
    Mixpanel.track(`Click ${interested ? 'Interested' : 'Not Interested'} to speak to Investment Specialist`)
    hideModal()
    if (uploadDocumentModal) {
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('navigateToInvest')
      } else {
        history.push('/products')
      }
    }
  }

  return (
    <>
      <Modal
        clickToClose
        pressToClose
        crossToClose
        hideModal={hideModal}
        innerStyle='us-success-modal'
      >
        <div className='large_1 text-text-default centered us-success-modal__header'>
          <img width={24} height={24} src={verfiedIcon} alt='Successful Document Upload'/>
          Successful Document Upload
        </div>
        <p className='medium_1 centered'>
        We will now review your submission and email you once you&apos;re verified.
        </p>
        <div className='us-success-modal__connect-specialist-image centered'>
          <img src={connectSpecialistImage} alt='connect-specialist'/>
        </div>
        <div className='large_1 text-text-default centered us-success-modal__header'>
        Have questions getting started? Speak with an Investment Specialist.
        </div>
        <div className='medium_1 centered'>
        A Linqto Investment Specialist can call you for a free consultation
        (if your accreditation is approved).
        </div>
        <div className='items-center mt-4 ml-auto mr-auto md:flex'>
          <Button mode='tertiary' onClick={() => modalCTA(false)}>
            No, Thanks
          </Button>
          <Button onClick={() => modalCTA(true)}>
            Yes, I&apos;m Interested
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default USSuccessModal
