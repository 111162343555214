import { images } from 'assets'
import CountryDropdown from './CountryDropdown'
import { useHistory } from 'react-router-dom'
import { Mixpanel } from 'utils'

const DocumentType = ({ selectIDType, idAllowed, setIdAllowed, selectedCountry, setSelectedCountry }) => {
  const history = useHistory()

  const goToContact = () => {
    Mixpanel.track('Click Contact Us on Choose ID Type Page')
    history.push('/contact')
  }

  const goToManualVerfication = () => {
    Mixpanel.track('Onboarding Choose ID Type', { 'ID Type': 'Manual Verification' })
    history.push('/identity/manual-verification/')
  }

  return (
    <>
      <div>
        Select your country then select one document to use.
      </div>
      <CountryDropdown setIdAllowed={setIdAllowed} selectedCountry={selectedCountry} setSelectedCountry={setSelectedCountry} />
      <div className='ID-group'>
        {idAllowed && <div
          className='border item medium_1 item-with-desc border-stroke-on-white-light'
          onClick={() => selectIDType('DL')}
        >
          <div className='item-row'>
            <div className='bg-background-mid-gray item-img'>
              <img alt='License or PhotoID' src={images.DL} />
            </div>
            <div className='text-text-default'>License or Photo ID</div>
          </div>
          <div className='item-row item-description'>
            Driver&apos;s License or other government-issued Photo ID-Card
          </div>
        </div>}
        <div className='border item medium_1 border-stroke-on-white-light' onClick={() => selectIDType('Passport')}>
          <div className='bg-background-mid-gray item-img'>
            <img alt='Passport' src={images.passport} />
          </div>
          <div className='text-text-default'>Passport</div>
        </div>
      </div>
      {!idAllowed && (
        <div className='medium_1 space-above-sm'>
          Don&apos;t have a passport?{' '}
          <a onClick={goToManualVerfication} className='primary link contact-us'>
          Click to upload other photo ID here.
          </a>
        </div>
      )}
      <div className='medium_1 space-above-sm'>
        Need help?{' '}
        <a onClick={goToContact} className='link text-text-hyperlink contact-us'>
          Contact Us
        </a>
      </div>
    </>
  )
}

export default DocumentType
