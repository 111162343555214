import SignUpForm from './partials/SignUpForm'
import { FormProvider, useForm } from 'react-hook-form'
import SignInOAuthBtns from './partials/SignInOAuthBtns'
import { schema } from 'schemas/signUpSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { images } from 'assets'
import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE } from 'utils'

const SignUpTest = ({
  oAuthToken,
  onOAuthSuccess,
  onSubmit,
  fromOrigination,
  setShowModal,
  submitLoading
}) => {
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema), context: { fromOrigination, oAuthToken } })
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE

  return (
    <div className='bg-background-light-gray flex h-[110vh]'>
      {!isMobile && <div className='z-10 test-form-container bg-background-default'>
        <div className='h-[800px] mt-[50px] w-full border-r border-stroke-on-white-light'>
          <div className='text-center rounded-xl border border-stroke-on-white-light bg-background-default max-w-[447px] mt-[150px] ml-auto mr-auto'>
            <h6 className='my-8'>Join our Community</h6>
            <div className='flex flex-col items-center mb-8'>
              <div className='flex items-center mb-2'>
                <h1 className='mb-0 mr-2 giant_1 source-serif-4'>$464.6M</h1>
                <img alt='blue-arrow-up' src={images['blue-arrow-up']} />
              </div>
              <span className='body_1'>Total Investments</span>
            </div>
            <div className='flex flex-col items-center'>
              <div className='flex items-center mb-2'>
                <h1 className='mb-0 mr-2 giant_1 source-serif-4'>126</h1>
                <img alt='blue-arrow-up' src={images['blue-arrow-up']} />
              </div>
              <span className='body_1'>Companies Offered Since Inception</span>
              <p className='mt-4 small_1 text-text-secondary'>Data as of 3/10/2025</p>
            </div>
            <img alt='wave' src={images.wave} />
          </div>
        </div>
      </div>}
      <div className='z-10 test-form-container bg-background-default'>
        <h1 className='!mt-12 large_1 text-text-default justify-center'>Create Your Account</h1>
        <SignInOAuthBtns
          oAuthToken={oAuthToken}
          onOAuthSuccess={onOAuthSuccess}
        />
        <FormProvider {...methods}>
          <SignUpForm
            onSubmit={onSubmit}
            isFromOrigination={fromOrigination}
            setShowModal={setShowModal}
            submitLoading={submitLoading}
            oAuthToken={oAuthToken}
          />
        </FormProvider>
      </div>
    </div>
  )
}

export default SignUpTest
