import { useEffect } from 'react'
import { Braze } from 'utils'

/**
 * This is a consolidated hook that will init braze when the app is initialized
 * @param {Config} config
 * @param userId
 */
function useBraze(config, userId = 0) {
  useEffect(() => {
    if(config) {
      Braze.initialize(config.getBrazeConfig());
      if(userId > 0) {
        Braze.identify(userId);
        console.log("Braze identity established with user " + userId);
      }
    }
  }, [config, userId])
}

export default useBraze;