import useWindowSize from 'hooks/useWindowSize'
import React from 'react'
import { images } from 'assets'
import { formatDate, formatDecimal, MOBILE_WIDTH_SIZE } from 'utils'
import Pill from 'components/Global/Pill'
import { useHistory } from 'react-router-dom'

const HistoryItem = ({ showCompany, order: { createdAt, companyName, sharePrice, shares, tag, price, orderId, sellOrderId }, type = 'BUY' }) => {
  const history = useHistory()
  const { width } = useWindowSize()

  let rowComponent = null
  const id = orderId || sellOrderId

  const goToDetails = (type, id) => {
    if (type === 'BUY') {
      history.push(`/order/${id}`)
    } else if (type === 'PROMO') {
      history.push(`/order/${id}`)
    } else if (type === 'SELL') {
      history.push(`/sell-order/${id}`)
    } else {
      history.push(`/offer/${id}`)
    }
  }

  if (width > MOBILE_WIDTH_SIZE) {
    rowComponent = (
      <div
        className={`border border-stroke-on-white-light history-grid-container${showCompany ? '__row' : '__row no_company'}`}
        onClick={() => goToDetails(type, id)}
        data-testid={`${type}-${type === 'SELL' ? sellOrderId : orderId}`}
        role='button'
        tabIndex={0}
        aria-label='Go to Order Details'
      >
        {tag === 'CANCELLED' && <Pill className='body_1 flag bg-fill-light-red text-text-error' status='CANCELLED' />}
        {tag === 'ORDER_IN_PROGRESS' && <Pill className='body_1 flag bg-legacy-citron text-text-default' status='PENDING' />}
        <span className='medium_1'>{formatDate(createdAt)}</span>
        {showCompany && <span className='medium_2'>{companyName}</span>}
        <span className='text-center medium_1'>{type}</span>
        <span className='text-right medium_1'>{formatDecimal(shares, false, 0)}</span>
        <span className='text-right medium_1'>{formatDecimal(sharePrice)}</span>
        <span className='text-right medium_2'>{formatDecimal(price)}</span>
        <div className='flex-end'>
          <img src={images['right-arrow']} alt={companyName} />
        </div>
      </div>)
  } else {
    rowComponent = (
      <div
        className='border border-stroke-on-white-light history-grid-container__row'
        onClick={() => goToDetails(type, id)}
        data-testid={`${type}-${type === 'SELL' ? sellOrderId : orderId}`}
        role='button'
        tabIndex={0}
        aria-label='Go to Order Details'
      >
        {tag === 'CANCELLED' && <Pill className='body_1 flag bg-fill-light-red text-text-error' status='CANCELLED' />}
        {tag === 'ORDER_IN_PROGRESS' && <Pill className='body_1 flag bg-legacy-citron text-text-default' status='PENDING' />}
        <div className='history-grid-container__row__flex'>
          <div className='history-grid-container__row__flex__date-info'>
            <span className='medium_1'>{formatDate(createdAt)}</span>
            <span>{type}</span>
          </div>
        </div>
        <div className='history-grid-container__row__flex'>
          <div className='history-grid-container__row__flex__company-info'>
            {showCompany && <span className='medium_2'>{companyName}</span>}
            <span className='medium_1'>{formatDecimal(price)}</span>
          </div>
          <img src={images['right-arrow']} alt={companyName}/>
        </div>
      </div>
    )
  }

  return rowComponent
}

export default HistoryItem
