import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'
import parsingCoreUser from '../actions/parsing/parsingCoreUser'
import ReactGA from 'react-ga4'
import { Mixpanel, Braze, Fullstory } from 'utils'
import User from '../utils/auth/User'
import AppMonitor from 'utils/monitoring/AppMonitor.js'

const initialState = {
  accessToken: '',
  amountUntilApollo: 0,
  canImpersonate: false,
  cashPaymentEnabled: false,
  isDebugAdmin: false,
  email: '',
  kycRequired: true,
  mfaRequired: false,
  redirectUrl: '',
  userId: 0,
  pageLoading: false,
  firstName: '',
  lastName: '',
  isSignedIn: false,
  hasSubmittedMfa: true,
  isImpersonating: false,
  signInLoading: false,
  submitLoading: false,
  mfas: [],
  resetPasswordStatus: 'initial',
  isUserReturned: false,
  referrerName: '',
  riaFirmName: '',
  hasReferrer: false,
  signUpBonus: '',
  referrerLogoUrl: '',
  accreditationTerms: '',
  errMsg: '',
  err: '',
  isPreferredCustomer: false,
  googleReCaptchaKeyId: '',
  aiCompanies: [],
  digitalAssetCompanies: [],
  needToCompleteInvestorProfile: false,
  featureFlags: {
    AccreditationThroughTestAllowed: false,
    WirePurchaseAllowed: false,
    FundingFromUpholdAllowed: false,
    PurchaseWithSharesAllowed: false,
    CanManuallyCreateWireCashExternalAccount: false,
    InstantAchFundingEnabled: false,
    ModernTreasuryTransactionMonitoringEnabled: false,
    UpholdPurchaseAllowed: false,
    AchFundingEnabled: false,
    EnableFraudPreventionForACH: false,
    CashPurchaseAllowed: false,
    UpholdWithdrawalEnabled: false,
    PlaidLinkingAllowed: false,
    BundlePurchaseEnabled: false,
    RetailPurchaseAllowed: false,
    PurchaseCreditEnabled: false,
    CashWithdrawalEnabled: false,
    CrsCheckEnabled: false
  }
}

export const getSignupReferral = createAsyncThunk('getSignupReferral', ({ referralCode, referralType }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  const url = (referralType === 'linqto-referral') ? `/register?referralCode=${referralCode}` : referralType === 'advisor-referral' ? `/register?riaReferralCode=${referralCode}` : '/register'
  return api
    .get(url)
    .then((res) => {
      return fulfillWithValue(res.data)
    })
    .catch(err => {
      dispatch(handleServerError(err?.response?.data?.error))
      return rejectWithValue(err.response)
    })
})

export const getReferAFriend = createAsyncThunk('getReferAFriend', async (referralCode, { dispatch, fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await api.get(`/referrer?referralCode=${referralCode}`)
    return fulfillWithValue(response.data)
  } catch (err) {
    dispatch(handleServerError(err?.response?.data?.error))
    return rejectWithValue(err.response)
  }
})

/**
 *
 * @param {User} user
 * @param {string} oAuthType
 */
const userSetup = (user, oAuthType = '') => {
  const userId = user.getUserId()
  if (userId) {
    ReactGA.event('login', { user_id: userId })
    Mixpanel.identify(userId)
    if (!Braze.isInitialized) {
      Braze.initialize()
    }
    Braze.identify(userId)
    Mixpanel.setPeople({ 'User ID': userId })
    AppMonitor.setUser(user)
  }
  if (oAuthType) {
    Mixpanel.setPeople({ 'Sign-Up Method': oAuthType })
  }
}

export const signIn = createAsyncThunk('signIn', async (formValues, { dispatch, fulfillWithValue, rejectWithValue }) => {
  try {
    const { data } = await api.post('/signIn', { ...formValues })
    Mixpanel.register({ 'Team Apollo': data?.isPreferredCustomer })
    Fullstory.setUserProperty({ is_team_apollo: data?.isPreferredCustomer })
    const userId = data?.userId
    const email = data?.email
    const user = new User(data)
    if (userId && email) {
      userSetup(user)
    }
    return fulfillWithValue(data)
  } catch (err) {
    if (err.response && err.response.status === 403) {
      Mixpanel.track('Sign In Error')
      return rejectWithValue(403)
    }
    if (err.response) {
      dispatch(handleServerError(err?.response?.data?.error))
    }
  }
})

export const signUp = createAsyncThunk('signUp', (formValues, { dispatch, fulfillWithValue, rejectWithValue }) => {
  const endPoint = formValues.oAuthType === 'Google' ? '/googleRegister' : formValues.oAuthType === 'Apple' ? '/appleRegister' : '/register'
  return api
    .post(endPoint, { ...formValues })
    .then((res) => {
      if (formValues.googleAccessToken || formValues.appleAccessToken) {
        const user = new User(res.data);
        userSetup(user, formValues.oAuthType)
      }
      const type = (formValues.googleAccessToken || formValues.appleAccessToken) ? 'auth' : 'email'
      return fulfillWithValue({ ...res.data, type })
    })
    .catch((err) => {
      if (err?.response?.data?.error && err.response.data.error === 'REGISTRATION_EMAIL_ALREADY_ACTIVATED') {
        Mixpanel.track('Onboarding Error', { 'Error Type': 'Account already exists' })
      }
      if (err.response) {
        dispatch(handleServerError(err.response?.data?.error))
        return rejectWithValue(err.response)
      }
    })
})

export const signInWithCode = createAsyncThunk('signInWithCode', (code, { dispatch, fulfillWithValue }) => {
  return api
    .post('/signInWithCode', code)
    .then((res) => {
      if (res.data.userId) {
        Mixpanel.identify(res.data.userId)
        if (!Braze.isInitialized) {
          Braze.initialize()
        }
        Braze.identify(res.data.userId)
        Mixpanel.setPeople({ 'User ID': res.data.userId })
      }
      Mixpanel.register({ 'Team Apollo': res?.data?.isPreferredCustomer })
      Fullstory.setUserProperty({ is_team_apollo: res?.data?.isPreferredCustomer })
      if (res.status === 200) {
        return fulfillWithValue(200)
      }
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err.response?.data?.error))
      }
    })
})

export const signOut = createAsyncThunk('signOut', async (_, { dispatch, rejectWithValue, fulfillWithValue }) => {
  try {
    await api.post('/signOut')
    const { payload } = await dispatch(getCoreUser())
    Mixpanel.reset()
    return fulfillWithValue(payload)
  } catch (err) {
    if (err?.response) {
      if (err.response?.status === 403) {
        localStorage?.removeItem('linqto_token')
      }
      dispatch(handleServerError(err?.response?.data?.error))
      rejectWithValue(err.response?.status)
    }
  }
})

export const getSignInMFAs = createAsyncThunk('getSignInMFAs', async (_, { dispatch, fulfillWithValue }) => {
  try {
    const res = await api.get('/page/security/signIn')
    if (res) {
      return fulfillWithValue(res.data)
    }
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err?.response?.data?.error))
    }
  }
})
// NOTE: hardcoded trust device to true so backend can use the value.
export const verifySignInMFA = createAsyncThunk('verifySignInMFA', ({ userMfaId, otp, trustDevice }, { dispatch }) => {
  return api
    .post(`/page/security/signIn/mfas/${userMfaId}/verify`, { userMfaId, otp, trustDevice })
    .then(() => {
      Mixpanel.register({ 'Signed In': true })
      Mixpanel.track('Sign In Successful', { 'MFA Success': true })
      return 'success'
    })
    .catch(err => {
      Mixpanel.track('Sign In Successful', { 'MFA Success': false })
      if (err.response && err?.response?.data?.error === 'MFA_VERICATION_INCORRECT') {
        return { err: 'The one time password is incorrrect. Try again.' }
      }
      // TODO: handle MFA_TOO_MANY_FAILED_ATTEMPTS
      dispatch(handleServerError(err?.response?.data?.error))
    })
})

export const sendSignInMFA = createAsyncThunk('sendSignInMFA', (userMfaId) => {
  return api
    .post(`/page/security/signIn/mfas/${userMfaId}/send`)
    .catch(err => {
      if (err) {
        return { err: 'Something went wrong.' }
      }
    })
})

export const resendRegistrationEmail = createAsyncThunk('resendRegistrationEmail', (email, { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api
    .get(`/register/resendEmail?email=${encodeURIComponent(email)}`)
    .then((res) => {
      return fulfillWithValue(res.data)
    })
    .catch((err) => {
      if (err.response) {
        dispatch(handleServerError(err?.response?.data?.error))
        return rejectWithValue(err.response)
      }
    })
})

export const verifyEmail = createAsyncThunk(
  'verifyEmail',
  (info, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .post('/confirmRegistration', info)
      .then((res) => {
        Mixpanel.track('Confirm Registration-Success')
        Fullstory.track('Confirm Registration-Success')
        if (res.data.userId) {
          Mixpanel.identify(res.data.userId)
          Mixpanel.setPeople({ 'User Id': res.data.userId, 'Sign-Up Method': 'Email' })
          if (!Braze.isInitialized) {
            Braze.initialize()
          }
          Braze.identify(res.data.userId)
        }
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        Mixpanel.track('Confirm Registration-Fail')
        Fullstory.track('Confirm Registration-Fail')
        if (err.response) {
          dispatch(handleServerError(err?.response?.data?.error))
          return rejectWithValue(err.response)
        }
      })
  }
)

export const getCoreUser = createAsyncThunk('getCoreUser', async (_, { dispatch, fulfillWithValue, rejectWithValue }) => {
  try {
    const res = await api.get('/core')
    if (res.data) {
      Mixpanel.register({ 'Wealth Advisor': res.data.isImpersonating })

      const userId = res?.data?.userId
      const appInstallId = res?.data?.appInstallId
      if (appInstallId) {
        localStorage?.setItem('appInstallId', appInstallId)
      }
      if (userId) {
        ReactGA.event('login', { user_id: userId })
        Mixpanel.identify(userId)
        Mixpanel.setPeople({ 'User ID': userId })
        if (!Braze.isInitialized) {
          Braze.initialize()
        }
        Braze.identify(userId)
        await dispatch(getFeatureFlags())
      }
      if (!res.data.isLoggedIn) {
        localStorage?.removeItem('linqto_token')
      }
      return fulfillWithValue(parsingCoreUser(res.data))
    }
  } catch (err) {
    if (err.response) {
      dispatch(handleServerError(err?.response?.data?.error))
      return rejectWithValue(err.response)
    }
  }
})

/**
 * GET global feature flag with overrides
 * @function getFeatureFlags
 * @returns - feature flag list
 */
export const getFeatureFlags = createAsyncThunk('getFeatureFlags', (_, { fulfillWithValue }) =>
  api.get('/page/featureFlagStatus')
    .then(res => {
      // Fullstory only accepts arrays of strings
      const flatArray = Object.entries(res?.data?.featureFlags).flatMap(([key, value]) => [key, value])
      const stringArray = flatArray.map(item => String(item))
      Fullstory.setUserProperty({ feature_flags_enabled: stringArray })
      return fulfillWithValue(res.data)
    })
    .catch(err => {
      // NOTE: we want this API to fail quietly
      console.log(err.response)
    })
)

export const resetPasswordEmail = createAsyncThunk(
  'resetPasswordEmail',
  ({ email }, { dispatch }) => {
    return api
      .post('/resetPassword', { email })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err?.response?.data?.error))
        }
      })
  }
)

export const resetPassword = createAsyncThunk(
  'resetPassword',
  (info, { dispatch, fulfillWithValue, rejectWithValue }) => {
    return api
      .post('/confirmResetPassword', info)
      .then((res) => {
        return fulfillWithValue(res.data)
      })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err?.response?.data?.error))
          return rejectWithValue(err.response)
        }
      })
  }
)

export const connectWealthAdvisor = createAsyncThunk(
  'connectWealthAdvisor',
  ({ code }, { dispatch }) => {
    return api
      .post('/connectWealthAdvisor', { code })
      .catch((err) => {
        if (err.response) {
          dispatch(handleServerError(err?.response?.data?.error))
        }
      })
  }
)

export const userSlice = createSlice({
  name: 'userSlice',
  initialState,
  reducers: {
    clearErrorState: (state) => {
      state.err = null
      state.errMsg = null
    },
    setUserIsSignedIn: (state) => {
      state.isSignedIn = true
    },
    resetUserState: (state) => {
      // eslint-disable-next-line no-unused-vars
      state = initialState
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(verifyEmail.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(verifyEmail.fulfilled, (_, { payload }) => {
        return {
          pageLoading: false,
          isSignedIn: true,
          isUserReturned: true,
          ...payload
        }
      })
      .addCase(verifyEmail.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getCoreUser.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getCoreUser.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          isUserReturned: true,
          ...payload
        }
      })
      .addCase(getCoreUser.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getFeatureFlags.fulfilled, (state, { payload }) => {
        return { ...state, ...payload }
      })
      .addCase(signIn.pending, (state) => {
        state.signInLoading = true
      })
      .addCase(signIn.fulfilled, (state, { payload }) => {
        if (payload?.err) {
          return { ...state, ...payload }
        }
        if (window.location.hostname === 'localhost' && payload?.accessToken) {
          localStorage?.setItem('linqto_token', payload.accessToken)
        }
        return { ...state, ...payload, err: {}, errMsg: '', hasSubmittedMfa: !payload.mfaRequired }
      })
      .addCase(signIn.rejected, (state, { payload }) => {
        if (payload === 403) {
          return {
            ...state,
            signInLoading: false,
            errMsg: 'Wrong email or password',
            err: payload
          }
        }
        return {
          signInLoading: false
        }
      })
      .addCase(resetPasswordEmail.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(resetPasswordEmail.fulfilled, (state) => {
        state.submitLoading = false
      })
      .addCase(resetPasswordEmail.rejected, (state) => {
        state.submitLoading = false
      })
      .addCase(verifySignInMFA.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(verifySignInMFA.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          hasSubmittedMfa: true,
          isSignedIn: !payload?.err,
          ...payload
        }
      })
      .addCase(verifySignInMFA.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(getSignInMFAs.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getSignInMFAs.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(getSignInMFAs.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(sendSignInMFA.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(sendSignInMFA.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload
        }
      })
      .addCase(sendSignInMFA.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(resetPassword.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(resetPassword.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload,
          submitLoading: false,
          isSignedIn: true,
          resetPasswordStatus: 'success'
        }
      })
      .addCase(resetPassword.rejected, (state) => {
        state.submitLoading = false
        state.resetPasswordStatus = 'failed'
      })
      .addCase(connectWealthAdvisor.fulfilled, (state, { payload }) => {
        return {
          ...state,
          submitLoading: false,
          ...payload
        }
      })
      .addCase(connectWealthAdvisor.rejected, (state) => {
        state.submitLoading = false
      })
      .addCase(connectWealthAdvisor.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(getSignupReferral.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(getSignupReferral.fulfilled, (state, { payload }) => {
        return {
          ...state,
          pageLoading: false,
          ...payload,
          err: '',
          errMsg: ''
        }
      })
      .addCase(getSignupReferral.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(signUp.pending, (state) => {
        state.submitLoading = true
      })
      .addCase(signUp.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload,
          submitLoading: false,
          err: '',
          errMsg: '',
          isSignedIn: payload.type === 'auth',
          hasSubmittedMfa: payload.type === 'auth'
        }
      })
      .addCase(signUp.rejected, (state, { payload }) => {
        state.submitLoading = false
        state.errMsg = payload?.data.error
        state.err = payload?.data.status
      })
      .addCase(signInWithCode.pending, (state) => {
        state.pageLoading = true
      })
      .addCase(signInWithCode.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload,
          pageLoading: false,
          isSignedIn: true,
          hasSubmittedMfa: true
        }
      })
      .addCase(signInWithCode.rejected, (state) => {
        state.pageLoading = false
      })
      .addCase(signOut.fulfilled, (state, { payload }) => {
        return {
          ...initialState,
          ...payload
        }
      })
      .addCase(signOut.rejected, (state, { payload }) => {
        if (payload === 403) {
          return {
            ...initialState
          }
        }
      })
      .addCase(getReferAFriend.fulfilled, (state, { payload }) => {
        const { referrerNameFirst, referrerNameLast, linqtoBucksToReferee } = payload || {}
        state.referrerName = `${referrerNameFirst} ${referrerNameLast}`
        state.signUpBonus = linqtoBucksToReferee
      })
  }
})

export const { clearErrorState, setUserIsSignedIn, resetUserState } = userSlice.actions

export default userSlice.reducer
