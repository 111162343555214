import useCampaignVariation from 'hooks/useCampaignVariation'
import Drawer from 'components/Global/Drawer/Drawer'

const CampaignDrawer = ({ flag }) => {
  const [showDrawer, handleClose, data] = useCampaignVariation(flag)

  return <Drawer visible={showDrawer} data={data} handleClose={handleClose} />
}

export default CampaignDrawer
