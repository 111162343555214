import useWindowSize from 'hooks/useWindowSize'
import { formatDecimal, MOBILE_WIDTH_SIZE } from 'utils'
import { images } from 'assets'
import { Button } from 'components'

const AddFundsModalItem = ({ account, onSelect, disabled = false, customClass = '', readOnly = false }) => {
  const { width } = useWindowSize()
  const isMobile = width < MOBILE_WIDTH_SIZE
  return (
    <Button
      className={`border border-stroke-on-white-light order-modal-account-item add-funding-modal-item text-text-default min-w-full ${customClass} ${disabled ? 'disabled' : ''} ${readOnly ? 'cursor-default bg-background-mid-gray read-only group' : ''}`}
      onClick={() => !disabled && onSelect && onSelect(account)}
      disabled={disabled}
      ariaLabel={`Select ${account.label} account`}
    >
      <div className='group-[.read-only]:text-text-default'>
        <img alt={account.label} src={account.iconUrl} className='account-icon' />
      </div>
      {!isMobile && <div className='ml-2 medium_2 add-funding-modal-item-name group-[.read-only]:text-text-default'>{account.label}</div>}
      <div className='add-funding-modal-item-right group-[.read-only]:text-text-default'>
        {isMobile && <div className='medium_2 add-funding-modal-item-name-sm'>{account.label}</div>}
        {account.balance !== null && formatDecimal(account.balance)}
        {!account?.balance && account?.usdBalance !== null && formatDecimal(account.usdBalance)}
        {account?.description && <span className='text-left order-modal-account-desc'>{account.description}</span>}
        {!readOnly && <img src={images['right-arrow']} alt={account.label} className='account-arrow' />}
      </div>
    </Button>
  )
}

export default AddFundsModalItem
