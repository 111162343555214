import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { signOut } from 'slices/userSlice'
import NavHeader from './partials/NavHeader'
import MobileNav from './partials/MobileNav'
import WebNav from './partials/WebNav'
import { Mixpanel } from 'utils'
import { Button } from 'components'
import { images } from 'assets'
import { useHistory } from 'react-router-dom'
import { contentfulContentIds } from 'components/data'
import useContentful from 'hooks/useContentful'
import { useLDFlags } from 'utils'

const renderNavigation = (menuItems, isSignedIn, helpCenter) => {
  if(menuItems?.length === 0) return { parentItems: [] }

  const filteredMenuItems = helpCenter 
    ? menuItems 
    : menuItems.filter(item => item.fields.linkUrl !== '/help-center')

  const parentItems = filteredMenuItems
    ?.filter(item => item.fields.parentMenuItem === 'None' && (item.fields.showFor === 'All users' || item.fields.showFor === (isSignedIn ? 'Authenticated users' : 'Unauthenticated users')))
    .map(parent => ({
      ...parent,
      children: filteredMenuItems
        .filter(item => item.fields.parentMenuItem === parent.fields.title)
        .sort((a, b) => {
          const orderA = parseInt(a.fields.sortOrder || '0')
          const orderB = parseInt(b.fields.sortOrder || '0')
          return orderA - orderB
        })
    }))
    .sort((a, b) => {
      const orderA = parseInt(a.fields.sortOrder || '0')
      const orderB = parseInt(b.fields.sortOrder || '0')
      return orderA - orderB
    })
  return parentItems
}

const Nav = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { helpCenter } = useLDFlags(['helpCenter'])
  
  const { isSignedIn, isUserReturned } = useSelector((state) => state.userSlice)
  
  const [showDropdown, setShowDropdown] = useState(false)
  const [toggleStatus, setToggleStatus] = useState(false)
  const [headerEntry, setHeaderEntry] = useState({})
  const [navigationItems, setNavigationItems] = useState({})

  const { content, isLoading } = useContentful(contentfulContentIds.navId)

  if (window.ReactNativeWebView) {
    return null
  }

  useEffect(() => {
    if (isLoading || !content) return
    
    if (content?.fields) {
      setHeaderEntry(content.fields)
    }
    if(isUserReturned) {
      setNavigationItems(renderNavigation(content.fields.menuItems, isSignedIn, helpCenter))
    }
  }, [content, isLoading, isUserReturned, isSignedIn, helpCenter])

  const logOut = async () => {
    Mixpanel.track('Click Sign out')
    await dispatch(signOut())
    const host = window.location.hostname
    // if domain is www.linqto.com, beta.linqto.com, alpha.linqto.com, dev.linqto.io
    if (!host.includes('app')) {
      window.location = '/'
      // if domain is app.linqto.com => We need to test on this domain
    } else if (host === 'app.linqto.com') {
      window.location = 'https://www.linqto.com/'
      // if domain is app.beta.linqto.com, app.alpha.linqto.com, app.dev.linqto.io => We need to test on this domain
    } else {
      const hostname = host.substring(4, window.location.hostname.length)
      window.location = `https://${hostname}`
    }
  }

  const goToRewardsPage = () => {
    history.push('/rewards')
    Mixpanel.track('Click Get $1000 in Main Nav')
    setToggleStatus(false)
  }

  const LinqtoBucksBtn = () => {
    if(!isUserReturned || !isSignedIn) return null
    return <Button mode='secondary' ariaLabel='Get $1,000 Rewards' customClass='nav-rewards' onClick={goToRewardsPage} data-testid='get1000Btn'><img src={images.rewards_gifts} alt='Linqto $1,000 rewards' />Get $1,000</Button>
  }

  if (isLoading) {
    return <div className="nav-container bg-background-default shadow-ambient-shadow">Loading...</div>
  }

  return (
    <div id='header-div' className='bg-background-default shadow-ambient-shadow nav-container' data-testid='header-div'>
      <NavHeader
        setToggleStatus={setToggleStatus}
        toggleStatus={toggleStatus}
        headerEntry={headerEntry}
      />
      {isUserReturned && 
        <>
          {!toggleStatus && ( <WebNav
            setToggleStatus={setToggleStatus}
            toggleStatus={toggleStatus}
            setShowDropdown={setShowDropdown}
            showDropdown={showDropdown}
            navigationItems={navigationItems}
            customNavItem={LinqtoBucksBtn}
            logOut={logOut}
          />)}
          {toggleStatus && (
            <MobileNav
              setToggleStatus={setToggleStatus}
              toggleStatus={toggleStatus}
              items={navigationItems}
              customNavItem={LinqtoBucksBtn}
              logOut={logOut}
            />
          )}
        </>
      }
    </div>
  )
}

export default Nav