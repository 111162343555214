import { useSelector } from 'react-redux'
const InvestDetailAboutHeader = () => {
  const { details } = useSelector(state => state.investSlice)
  const maxDescriptionLength = 620 // 6 lines & breaks into 2 praphas on the 7th line
  const { aboutVideoUrl } = details || {}

  return (
    <div className='row'>
      <div className={`column sixteen text-text-default margin-bottom ${details.description && details.description.length > maxDescriptionLength ? 'two-cols-text' : ''}`}>
        <p className='medium_1 text-text-secondary'>
          {details.description}
        </p>
      </div>
      {aboutVideoUrl && <div className='margin-bottom__flex-center' data-testid='aboutVideoUrl'>
        <iframe src={aboutVideoUrl}
          id='aboutVideoIFrame'
          height={400}
          width={708}
          allow='autoplay; encrypted-media'
          allowFullScreen
          title='video'
        />
      </div>}
    </div>
  )
}
export default InvestDetailAboutHeader
