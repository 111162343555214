import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import AppMonitor from 'utils/monitoring/AppMonitor.js'

/**
 *
 * @param {Config} config
 * @returns {{initializeAppMonitoring: (function(): Promise<void> | Promise<unknown>)}}
 */
function useAppMonitor(config) {
  let location = useLocation()
  useEffect(() => {
    AppMonitor.onPageView(location.pathname)
  }, [location])

  useEffect(() => {
    initializeAppMonitoring()
  }, [])

  const initializeAppMonitoring = () => {
    AppMonitor.init(config)
    return Promise.resolve()
  }

  return { initializeAppMonitoring }
}

export default useAppMonitor