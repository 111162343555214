import React, { useEffect, useState } from 'react'
import { seoTitleTemplate, Mixpanel, getDomain } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import { useDispatch, useSelector } from 'react-redux'
import { getContactUs, submitContactUs } from 'slices/contactUsSlice'
import { PageLoading, Button } from 'components'
import TextInput from 'components/Global/Inputs/TextInput'
import TextArea from 'components/Global/Inputs/TextArea'
import Select from 'components/Global/Inputs/Select'
import PhoneNoInput from 'components/Global/Inputs/PhoneNoInput'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { schema } from 'schemas/contactUsSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'
import { handleReCaptchaVerify } from 'utils/googleRecaptcha'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'
import { useQuery } from 'hooks/useQuery'

const Contact = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const query = useQuery()
  const topic = query.get('topic')
  const message = query.get('message')
  const [loading, setLoading] = useState(false)
  const [removedHeadquarters, setRemovedHeadquarters] = useState([])
  const { pageLoading, addresses, topics, initialValues, submitLoading } = useSelector((state) => state.contactUs)
  const methods = useForm({ mode: 'onTouched', resolver: yupResolver(schema) })
  const { handleSubmit, control, register, setValue } = methods
  const { executeRecaptcha } = useGoogleReCaptcha()
  
  useEffect(() => {
   if (addresses && addresses.length > 0) {
     setRemovedHeadquarters(addresses.filter(address => address.label !== 'Headquarters Office'))
  }
  }, [addresses])

  useEffect(() => {
    dispatch(getContactUs()).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Contact Us Page')
      }
    })
  }, [])

  const onSubmit = async (formValues) => {
    setLoading(true)
    const token = await handleReCaptchaVerify(executeRecaptcha, 'contactUs')
    dispatch(submitContactUs({ ...formValues, token }))
      .then(({ meta }) => {
        setLoading(false)
        if (meta.requestStatus === 'fulfilled') {
          Mixpanel.track('Submit Contact Us Form', { 'Support Topic': formValues.topic })
          history.push('/contact/success')
        }
      })
  }

  useEffect(() => {
    methods.reset(initialValues)
  }, [initialValues])

  useEffect(() => {
    if (topic) {
      setValue('topic', topic)
    }
  }, [topic])

  useEffect(() => {
    if (message) {
      setValue('message', message)
    }
  }, [message])

  if (pageLoading) {
    return <PageLoading />
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Contact Us')} />
      <div className='page-container flex flex-col min-h-[90vh]'>
        <div className='inner-container'>
          <h1>Contact Us</h1>
          <span>Our expert team is here to help. Need a quicker response? Browse <Button ariaLabel='Browse FAQ' onClick={() => { window.location.href = getDomain('/faq', true) }} className='underline text-text-default'>FAQs.</Button></span>
          <div className='px-1'>
            <FormProvider {...methods}>
              <form className='grid-old' onSubmit={handleSubmit(onSubmit)}>
                <div className='column eight'>
                  <TextInput
                    name='firstName'
                    label='First Name'
                    ariaLabel='First Name'
                    type='text'
                    autocomplete='given-name'
                    disabled={submitLoading}
                  />
                </div>
                <div className='column eight'>
                  <TextInput
                    name='lastName'
                    label='Last Name'
                    ariaLabel='Last Name'
                    type='text'
                    autoComplete='family-name'
                    disabled={submitLoading}
                  />
                </div>
                <div className='column eight sixteen-tablet sixteen-mobile'>
                  <TextInput
                    name='email'
                    label='Email'
                    ariaLabel='Email'
                    type='email'
                    disabled={submitLoading}
                  />
                </div>
                <div className='column eight sixteen-tablet sixteen-mobile'>
                  <PhoneNoInput
                    label='Phone'
                    name='phone'
                    control={control}
                    register={register}
                    ariaLabel='Phone'
                    disabled={submitLoading}
                  />
                </div>
                <div className='column eight sixteen-tablet sixteen-mobile'>
                  <Controller
                    control={control}
                    name='topic'
                    setValue={setValue}
                    render={({ field }) => (
                      <Select
                        field={field}
                        label='Topic'
                        name='topic'
                        ariaLabel='Topic'
                        options={topics}
                        ref={null}
                        defaultOption={{ value: '', label: 'Select Topic' }}
                        disabled={submitLoading}
                        setValue={setValue}
                      />
                    )}
                  />
                </div>
                <div className='column sixteen'>
                  <TextArea
                    name='message'
                    label='Message'
                    ariaLabel='Message'
                    type='textarea'
                    disabled={submitLoading}
                    aria-labelledby='message'
                  />
                </div>
                <div className='column sixteen'>
                  <p className='mt-2 mb-10 body_1 text-text-secondary'>By providing a telephone number and submitting the form you are consenting to be contacted by SMS text message. Message & data rates may apply. Reply STOP to opt out of further messaging.</p>
                </div>
                <div className='column sixteen'>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type='submit'
                  >Send</Button>
                </div>
              </form>
            </FormProvider>
            <div className='mt-[30px] flex lg:flex-row flex-col w-full'>
              {(addresses && removedHeadquarters.length > 0) ? removedHeadquarters.map((address, i) => (<div key={i} className='w-1/2 py-4'>
                <div className='medium_2 mb-[20px]'>{address.label}</div>
                <div className='medium_1'>{address.street1}</div>
                <div className='medium_1'>{address.city}, {address.state} {address.zip}</div>
              </div>
              )) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Contact
