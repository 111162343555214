import { useSelector } from 'react-redux'
import moment from 'moment'
import { formatDecimal, toCamalCase } from 'utils'

const PurchaseCreditsHistory = () => {
  let { creditPurchaseEntries } = useSelector((state) => state.purchaseCredits)
  creditPurchaseEntries = creditPurchaseEntries.slice().sort((a, b) => b.createdAt - a.createdAt)
  return (
    <>
      <div className='border-t grid-old transactions-table desktop-content border-t-stroke-on-white-dark md:border-t-0'>
        <div className='border-b row border-b-stroke-on-white-dark transactions-table-header'>
          <div className='column four medium_1 text-text-default'>DATE</div>
          <div className='column eight medium_1 text-text-default'>TRANSACTION</div>
          <div className='column four medium_1 text-text-default right'>AMOUNT</div>
        </div>
        {creditPurchaseEntries && creditPurchaseEntries.map((f, i) =>
          <div key={`currentPurchaseCredits_${i}`} className='border-b row border-b-stroke-on-white-light transactions-table-body'>
            <div className='column four medium_1 text-text-default'>{moment(f?.createdAt).format('MM/DD/YYYY')}</div>
            <div className='column eight medium_1 text-text-default'>{f.transactionType && toCamalCase(f?.transactionType)}</div>
            <div className='column four medium_1 text-text-default right'>{formatDecimal(f?.amount)}</div>
          </div>
        )}
      </div>
      <div className='border-t grid-old border-t-stroke-on-white-dark md:border-t-0 transactions-table mobile-content'>
        {creditPurchaseEntries && creditPurchaseEntries.map((f, i) =>
          <div key={`currentPurchaseCredits_${i}`} className='border-b row border-b-stroke-on-white-light transactions-table-body'>
            <div className='column ten-mobile medium_1 text-text-default'>{moment(f?.createdAt).format('MM/DD/YYYY')}<br/>{f.transactionType && toCamalCase(f?.transactionType)}</div>
            <div className='column six-mobile medium_1 text-text-default right'>{formatDecimal(f.amount)}</div>
          </div>
        )}
      </div>
    </>
  )
}

export default PurchaseCreditsHistory
