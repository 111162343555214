import { useSelector } from 'react-redux'

const NotifyMeCopy = () => {
  const { details } = useSelector(state => state.investSlice)
  const { isSoldOut, isInterested, promoFlag } = details

  if (!isInterested || !isSoldOut) return null
  else {
    return (
      <div className='text-text-secondary'>You will be notified {promoFlag === 'COMING_SOON' ? 'when' : 'if'} units of this company become available. </div>)
  }
}

export default NotifyMeCopy
