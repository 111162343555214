import { useSelector, useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { getLabelContent, getTooltipContent, getImageContent } from 'utils/Contentful'
import { contentfulContentIds } from 'components/data'
import { getAddFundsPage, getPlaidLinkToken } from 'slices/walletSlice'
import { Mixpanel } from 'utils'
import qs from 'qs'
import useContentful from 'hooks/useContentful'

/**
 * @function useAddFundsRedesign hook
 * Handles data fetching and logic for the AddFundsRedesign component
 */

const CONTENT_FIELDS = {
  ADD_FUNDS_ICON: 'addFundsIcon',
  ADD_FUNDS_TITLE: 'addFunds',
  DEPOSIT_AMOUNT: 'depositAmount',
  DEPOSIT_AMOUNT_TOOLTIP: 'depositAmountTooltip',
  FUNDING_SOURCE_LABEL: 'fundingSoruceLabel',
  REVIEW_BUTTON: 'reviewButton'
}

const useAddFundsRedesign = () => {
  const dispatch = useDispatch()
  const query = location.search
  const entityId = qs.parse(query, { ignoreQueryPrefix: true })?.entityId || null

  const { achLimit } = useSelector(state => state.walletSlice)
  const { content: contentfulData } = useContentful(contentfulContentIds.addFundsModal)

  const [showUnderReviewModal, setShowUnderReviewModal] = useState(false)
  const [showTopBanner, setShowTopBanner] = useState(null)
  const [upholdAPIError, setUpholdAPIError] = useState('')
  const [selectedAccount, setSelectedAccount] = useState(null)
  const [insufficientError, setInsufficientError] = useState(
    location?.state?.error === 'UPHOLD_INSUFFICIENT_FUNDS' || null
  )
  const [contentLabels, setContentLabels] = useState({
    addFundsIcon: '',
    addFunds: '',
    depositAmount: '',
    depositAmountTooltip: '',
    fundingSourceLabel: '',
    reviewButton: ''
  })

  useEffect(() => {
    if (!contentfulData) return

    const items = contentfulData?.fields?.items || []
    const fetchContentLabels = async () => {
      const addFundsIcon = await getImageContent(CONTENT_FIELDS.ADD_FUNDS_ICON, items)
      const addFunds = getLabelContent(CONTENT_FIELDS.ADD_FUNDS_TITLE, items)
      const depositAmount = getLabelContent(CONTENT_FIELDS.DEPOSIT_AMOUNT, items)
      const depositAmountTooltip = getTooltipContent(
        CONTENT_FIELDS.DEPOSIT_AMOUNT_TOOLTIP,
        items,
        {},
        false
      )
      const reviewButton = getLabelContent(CONTENT_FIELDS.REVIEW_BUTTON, items)

      setContentLabels({
        addFundsIcon,
        addFunds,
        depositAmount,
        depositAmountTooltip,
        reviewButton
      })
    }

    fetchContentLabels()
  }, [contentfulData])

  useEffect(() => {
    dispatch(getAddFundsPage(entityId)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        if (payload.plaidLinkingAllowed) dispatch(getPlaidLinkToken(entityId))
        const isBankLinked =
          payload?.cashExternalAccounts?.filter(a => a.amlStatus === 'APPROVED').length > 0
            ? true
            : payload.cashExternalAccounts?.filter(a => a.amlStatus === 'NEEDS_REVIEW').length > 0
              ? 'Under Review'
              : false

        Mixpanel.track('View Add Funds Page', {
          'Uphold Linked': payload.hasUphold
            ? payload.upholdError
              ? 'Email Verification'
              : true
            : false,
          'Bank Account Linked': isBankLinked
        })
        if (payload && payload.hasUphold && sessionStorage.getItem('connectUpholdAddFunds')) {
          if (!payload.upholdError && !payload.upholdUnderReview) {
            setShowTopBanner({
              title: 'Uphold Successfully Linked',
              body: 'Your Uphold Account can now be used as a funding method.'
            })
            Mixpanel.track('Funding Account Successfully Linked', { 'Account Type': 'Uphold' })
          }
          if (payload.upholdUnderReview) {
            setShowUnderReviewModal(true)
          }
          if (payload.upholdError) {
            setShowTopBanner({
              title: 'Almost there!',
              body: `Please check your email. ${payload.upholdError}`
            })
          }
          sessionStorage.removeItem('connectUpholdAddFunds')
        }
        if (insufficientError) {
          const upholdCards = payload.upholdCards
          setSelectedAccount({
            ...upholdCards.filter(a => a.id === location?.state?.cardId)[0],
            method: 'Uphold'
          })
        }
      } else if (meta.requestStatus === 'rejected') {
        if (payload) {
          setUpholdAPIError(payload)
        }
      }
    })
  }, [])

  return {
    contentLabels,
    achLimit,
    selectedAccount,
    insufficientError,
    setInsufficientError,
    showUnderReviewModal,
    showTopBanner,
    upholdAPIError
  }
}

export default useAddFundsRedesign
