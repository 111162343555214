import { useSelector } from 'react-redux'
import { formatDecimal, formatWholeNumber, getDomain, renderTotalsOrDash } from 'utils'
import Toggle from 'components/Global/Toggle'
import { images } from 'assets'
import TooltipLabel from 'components/Global/TooltipLabel'
import { useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import CustomSelect from 'components/Global/Inputs/CustomSelect'
import ErrorMessage from 'components/Global/Inputs/ErrorMessage'
import { availableSharesToSellTooltip, transactionFeeCopy } from 'components/data'
import { Tooltip, ConditionalRender, Button } from 'components'

const Payment = ({
  selectedAccount,
  linqtoBucksEnabled,
  promoCode,
  promoCodeError,
  purchaseCreditsEnabled,
  purchaseWithShares,
  purchaseWithCash,
  toggleCash,
  handlePurchaseAsChange,
  toggleShares,
  onDropDownChange,
  togglePurchaseCredits,
  toggleLinqtoBucks,
  applyPromoCode,
  removePromoCode,
  onPromoCodeChange,
  selectedOptions
}) => {
  const { accounts, totalPurchaseCredits, maxOrderLinqtoBucksPercent, linqtoBucks, order, confirmOrderLoading } = useSelector((state) => state.placeOrderSlice)
  const {
    amount, linqtoFee, linqtoBucksUsed, promotionalCredit, total,
    discountAmount, discountPercent, commitLoading, creditsUsed, isPromoCodeValid, sellAmount
  } = order || {}
  const { featureFlags: { PurchaseCreditEnabled, PurchaseWithSharesAllowed } } = useSelector(state => state.userSlice)
  const { control, getValues } = useFormContext()
  const [showHover, setShowHover] = useState(false)
  const [showHoverFee, setShowHoverFee] = useState(false)
  const promoCodeDisabled = linqtoBucksUsed > 0

  const accountLabel = getValues('sellCompanyId') ? JSON.parse(getValues('sellCompanyId'))?.label : null
  const sellCompanies = selectedAccount?.sellCompanies?.map(s => ({
    value: s.companyId,
    label: s.name,
    icon: s.iconUrl,
    numberOfShares: s.numberOfshares,
    pricePerShare: s.pricePerShare,
    customLabel: `${formatDecimal(s.totalSharePrice)} (${formatWholeNumber(s.numberOfshares)} Units)`
  })) || []

  return (
    <div className='buy-order-section wih-margin top-aligned'>
      <div className='large_1 buy-order-section-title'>
        <span className='text-text-hyperlink border-[3px] border-logo-cobalt'>2</span> Payment
      </div>
      <div className='buy-order-section-detail'>
        <span className='medium_2'>Apply Discounts</span>
        <div className='border border-stroke-on-white-light box invest-order-box payment-invest-order-box'>
          <ConditionalRender isVisible={PurchaseCreditEnabled && totalPurchaseCredits > 0}>
            <div className='linqto-bucks-row'>
              <div className='buy-order-row'>
                <div className='linqto-bucks-wrapper'>
                  <div className='linqto-img'>
                    <img src={images['wallet-purchase-credit-account']} alt='Purchase Credits' />
                  </div>
                  <div className={`linqto-copy ${purchaseWithShares ? 'text-text-secondary' : ''}`}>
                    <TooltipLabel
                      content='Purchase Credits are valid for 30 days and can be combined with Linqto Bucks and other offers.'
                      label='Purchase Credits'
                      customClass='medium_2' />
                    <span className='no-padding'>{formatDecimal(totalPurchaseCredits)}</span>
                    {purchaseWithShares && <span className='medium_1 text-text-secondary no-padding'>Cannot be combined when paying with Units</span>}
                  </div>
                </div>
                <Toggle handleChange={togglePurchaseCredits} checked={purchaseCreditsEnabled} ariaLabel='puchaseCreditsbToggleSwitch' disabled={purchaseWithShares} />
              </div>
            </div>
          </ConditionalRender>
          <ConditionalRender isVisible={linqtoBucks > 0 && maxOrderLinqtoBucksPercent > 0}>
            <div className='linqto-bucks-row'>
              <div className='buy-order-row'>
                <div className='linqto-bucks-wrapper'>
                  <div className='linqto-img'>
                    <img src={images['bucks-icon']} alt='Linqto Bucks' />
                  </div>
                  <div className='linqto-copy'>
                    <span className='medium_2 no-padding'>Linqto Bucks</span>
                    <span className='no-padding'>{formatDecimal(linqtoBucks)}</span>
                  </div>
                </div>
                <Toggle handleChange={toggleLinqtoBucks} checked={linqtoBucksEnabled} ariaLabel='lqbToggleSwitch' />
              </div>
              {linqtoBucksEnabled && <div className='linqto-bucks-terms medium_1'>
                {linqtoBucks < (maxOrderLinqtoBucksPercent * amount) && 'Linqto Bucks cannot be combined with other promotions. '}
                {linqtoBucks >= (maxOrderLinqtoBucksPercent * amount) && `Linqto Bucks can be used for up to ${maxOrderLinqtoBucksPercent * 100}% of the order and cannot be combined with other promotions. `}
                By using Linqto Bucks, I have read and agree to be bound by the <a className='text-text-hyperlink inline-text-link medium_1' href={getDomain('/reward-terms-of-use', true)} target='_blank' rel='noreferrer'>Linqto Bucks Terms and Conditions.</a>
              </div>}
              {discountAmount > 0 && <div className='linqto-bucks-terms medium_1'>Linqto Bucks cannot be combined with other promotions.</div>}
            </div>
          </ConditionalRender>
          <div className='linqto-bucks-row'>
            {(() => {
              if (!isPromoCodeValid) {
                return (
                  <div className={`input-group promo-input-wrapper ${promoCodeDisabled || confirmOrderLoading ? 'disabled' : ''} ${promoCodeError ? 'error' : ''}`}>
                    <label className='text-text-default'>Promo Code</label>
                    <input
                      disabled={promoCodeDisabled || confirmOrderLoading}
                      type='text'
                      aria-label='promoCodeInput'
                      className={`border border-stroke-on-white-dark hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue promo-input ${promoCodeError ? 'border border-stroke-alert-red' : ''}`}
                      value={promoCode}
                      onChange={onPromoCodeChange}
                    />
                    <Button 
                      className='text-text-hyperlink apply-btn medium_2 [.disabled_&]:cursor-default [.disabled_&]:text-text-secondary' 
                      onClick={applyPromoCode} 
                    >
                      Apply
                    </Button>
                    {(promoCodeError) && <ErrorMessage message='Promo code not found' />}
                    {promoCodeDisabled && <span>To enter a promo code, please turn off Linqto Bucks.</span>}
                  </div>
                )
              } else {
                return (
                  <div className='input-group promo-input-wrapper promo-input-success'>
                    <label className='text-text-default'>Promo Code</label>
                    <input
                      disabled={true}
                      type='text'
                      aria-label=''
                      className={`promo-input bg-background-default ${confirmOrderLoading ? 'bg-background-mid-gray' : ''}`}
                    />
                    <span className='promo-name-wrapper medium_2 bg-background-mid-gray text-text-default'>{promoCode} <Button onClick={removePromoCode} ariaLabel='Remove Promo Code'><img src={images['promo-close']} alt='Remove Promo Code' /></Button></span>
                    <span className='text-text-secondary promo-applied-wrapper medium_2'>Applied <img src={images['promo-check']} alt='Apply Promo Code'/></span>
                  </div>
                )
              }
            })()}
          </div>
        </div>
        <span className='medium_2'>Pay With</span>
        <div className='border border-stroke-on-white-light box invest-order-box payment-invest-order-box__with-share-dropdown'>
          {accounts?.length > 1 &&
            <div className='buy-order-row'>
              <span className='select-input'>
                <span className='medium_1'>Transact as</span>
                <select
                  className='border bg-background-default border-stroke-on-white-dark text-text-default hover:border-stroke-cobalt-blue hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue' aria-label='purchase-as' onChange={handlePurchaseAsChange} disabled={commitLoading}>
                  {accounts?.length > 0 && accounts.map((e, i) => <option value={i} key={i}>{e.accountName}</option>)}
                </select>
              </span>
            </div>}
          <ConditionalRender isVisible={PurchaseWithSharesAllowed && selectedAccount?.sellCompanies?.length > 0}>
            <div className='buy-order-row'>
              <div className='linqto-bucks-wrapper'>
                <div className='linqto-img'>
                  <img src={images['shares-icon']} alt='Pay with Units' />
                </div>
                <span className='medium_2'>Units</span>
              </div>
              <Toggle handleChange={toggleShares} checked={purchaseWithShares} ariaLabel='payWithSharesToggleSwitch' />
            </div>
            {purchaseWithShares &&
              <Controller
                control={control}
                name='sellCompanyIdControl'
                render={({ field }) => (
                  <CustomSelect
                    name='sellCompanyId'
                    ariaLabel='sellCompanyId-input'
                    field={field}
                    label='Units available to sell'
                    placeholder='No Company Holdings Selected'
                    extraLabel=' '
                    selectedOptions={selectedOptions}
                    optionsList={sellCompanies}
                    onChange={onDropDownChange}
                    className='shares-drop-down'
                    content={availableSharesToSellTooltip}
                  />
                )}
              />}
            <ConditionalRender isVisible={selectedOptions?.length === 1} className='shares-sell-data'>
              <div className='buy-order-row'>
                <div className='medium_1 text-text-default'>Estimated Units To Be Sold:</div>
                <div className='medium_1 text-text-default'>{renderTotalsOrDash(order?.sellShares, formatWholeNumber(order?.sellShares))}</div>
              </div>
              <div className='buy-order-row'>
                <div className='medium_1 text-text-default'>Remaining Units:</div>
                <div className='medium_1 text-text-default'>{renderTotalsOrDash(order?.remainingUnsoldShares, formatWholeNumber(order?.remainingUnsoldShares))}</div>
              </div>
              <div className='buy-order-row'>
                <div className='medium_1 text-text-default'>Estimated Unit Price:</div>
                <div className='medium_1 text-text-default'>{renderTotalsOrDash(order?.sellSharePrice, formatDecimal(order?.sellSharePrice))}</div>
              </div>
              <div className='buy-order-row'>
                <div className='medium_1 text-text-default'>Cost Basis of Units:</div>
                <div className='medium_1 text-text-default'>{renderTotalsOrDash(order?.totalOriginalHeldSharePrice, formatDecimal(order?.totalOriginalHeldSharePrice))}</div>
              </div>
              {order?.sellTransactionFee > 0 && <div className='buy-order-row'>
                <div className='medium_1 text-text-default'>Transaction Fee:<Tooltip content={transactionFeeCopy(order.sellTransactionFeeRate)} showHover={showHoverFee} setShowHover={setShowHoverFee} /></div>
                <div className='medium_1 text-text-default'>{renderTotalsOrDash(order?.sellTransactionFee, formatDecimal(order?.sellTransactionFee * -1))}</div>
              </div>}
              <div className='buy-order-row'>
                <div className='medium_1 text-text-default'>Your Projected Gain:<Tooltip content='These gains/losses are estimated. Consult a tax advisor for potential tax implications.' showHover={showHover} setShowHover={setShowHover} /></div>
                <div className='medium_1 text-text-default'>{renderTotalsOrDash(order?.projectedGain, formatDecimal(order?.projectedGain) || '-')}</div>
              </div>
            </ConditionalRender>
            <div className='border-b border-b-stroke-on-white-light space-above-sm' />
          </ConditionalRender>
          <div className='linqto-bucks-row'>
            <div className='buy-order-row'>
              <div className='linqto-bucks-wrapper'>
                <div className='linqto-img'>
                  <img src={images['cash-icon-dollar']} alt='Cash Balance' />
                </div>
                <div className='linqto-copy'>
                  <span className='medium_2 no-padding'>Cash Balance</span>
                  <span className='no-padding'>{formatDecimal(selectedAccount?.amountAvailable)}</span>
                  {PurchaseWithSharesAllowed && <span className='body_1 no-padding'>Covers balance after selling units and any discounts applied. </span>}
                </div>
              </div>
              {PurchaseWithSharesAllowed && <Toggle handleChange={toggleCash} checked={purchaseWithCash} ariaLabel='cashBalanceToggleSwitch' />}
            </div>
          </div>
        </div>
        <div className='buy-order-row'>
          <span>Investment Amount</span>
          <span>{formatDecimal(amount)}</span>
        </div>
        {sellAmount > 0 && accountLabel && <div className='buy-order-row'>
          <span>Units to Sell: {accountLabel}</span>
          <span>-{formatDecimal(sellAmount)}</span>
        </div>
        }
        {creditsUsed > 0 && <div className='buy-order-row'>
          <span>Purchase Credits</span>
          <span>-{formatDecimal(creditsUsed)}</span>
        </div>
        }
        {discountAmount > 0 && <div className='buy-order-row'>
          <span>Promo Discount</span>
          {discountPercent > 0 && <div className='text-text-hyperlink'>-{formatDecimal(discountPercent * 100, false, 0)}% ({formatDecimal(discountAmount)})</div>}
          {(!discountPercent && discountAmount > 0) && <div className='text-text-hyperlink'>-{formatDecimal(discountAmount)}</div>}
        </div>
        }
        <div className='buy-order-row'>
          <span>Linqto Fee</span>
          <span>{formatDecimal(linqtoFee)}</span>
        </div>
        {linqtoBucksUsed > 0 && <div className='buy-order-row'>
          <span>Linqto Bucks</span>
          <span> -{formatDecimal(linqtoBucksUsed)}</span>
        </div>
        }
        {promotionalCredit > 0 && <div className='buy-order-row'>
          <span>Promotional Credit</span>
          <span> -{formatDecimal(promotionalCredit)}</span>
        </div>
        }
        <div className='buy-order-row medium_2'>
          <span>Total Amount Due</span>
          <span>{formatDecimal(total < 0 ? 0 : total)}</span>
        </div>
        {total < 0 &&
          <div className='buy-order-row'>
            <span>Credit to Cash Account</span>
            <span>{formatDecimal(total * -1)}</span>
          </div>
        }
      </div>
    </div>
  )
}

export default Payment
