
import { formatDecimal } from 'utils'
import PropTypes from 'prop-types'

const NumberHeaderAmount = ({ value, cents, ariaLabel, className }) => {
return (
    <p className={`flex items-start justify-center large_number ${className}`} aria-label={ariaLabel}>
    <span className='large_1'>$</span>
    {value ? formatDecimal(+value, false, 0) : '0'}
    <span className=' large_1'>{cents ? '.' + cents : '.00'}</span>
  </p>
)
}

export default NumberHeaderAmount 

NumberHeaderAmount.propTypes = {
  value: PropTypes.string.isRequired, // read only value
  cents: PropTypes.string.isRequired, // read only cents
  ariaLabel: PropTypes.string.isRequired, // aria label
  className: PropTypes.string, // custom styling
}
