import { createContext, useEffect, useState } from 'react'
import Config from './config.js'
import { useLDClient } from 'launchdarkly-react-client-sdk'

/**
 *
 * @type {React.Context<Config>}
 */
export const ConfigContext = createContext()

/**
 * Provides a configuration context to its children.
 * Initializes the configuration and supplies it to the children via context.
 *
 * For now, it is not blocking and will allow child components to render. This will allow for reducing risk in deployment
 *
 * @param {Object} props - The properties passed to the ConfigProvider component.
 * @param {React.ReactNode} props.children - The child components that will have access to the configuration context.
 * @return {JSX.Element} The configuration context provider wrapping the specified children.
 */
export function ConfigProvider({ children }) {
  const ldClient = useLDClient()
  const config = new Config(ldClient)
  const [resolvedConfig, setResolvedConfig] = useState(null)
  useEffect(() => {
    // https://launchdarkly.com/docs/sdk/features/flag-changes#expand-javascript-code-sample
    ldClient.on('change', updates => {
      const newConfig = config.merge(updates)
      setResolvedConfig(newConfig)
    })
    config.init().then(newConfig => {
      setResolvedConfig(newConfig)
    })
    return () => {
      ldClient.off('change')
    }
  }, [])

  if (resolvedConfig === null) {
    return null
  }

  return <ConfigContext.Provider value={resolvedConfig}>{children}</ConfigContext.Provider>
}
