const OriginationDetailCompanyLogo = ({ company }) => {
  return (
    <div className='page-header-company-info section-wide origination-detail-logo'>
      <div className='page-header-company-logo'>
        <div className='page-header-company-logo-wrapper'>
          <div className='page-header-company-logo-bg bg-background-default'>
            {company && company.logoUrl && <img src={company.logoUrl} alt={company.name} />}
          </div>
        </div>
        <div className='page-header-company-title-container'>
          <h1 className='page-header-company-title'>{company && company.name}</h1>
          <div className='large_1 text-text-default'>{company && company.legalName}</div>
        </div>
      </div>
    </div>
  )
}

export default OriginationDetailCompanyLogo
