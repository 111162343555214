import { useSelector } from 'react-redux'

const OrderConfirmationHeader = () => {
  const { company } = useSelector(
    (state) => state.placeOrderSlice
  )
  const { name, iconUrl } = company

  return (
    <div className='buy-order-header'>
      <h1>Buy Order</h1>
      <div className='buy-order-company-name'>
        <img className='border border-stroke-on-white-light buy-order-company-icon' src={iconUrl} alt={name} />
        <div className='company-name'>{name}</div>
      </div>
    </div>
  )
}

export default OrderConfirmationHeader
