import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConditionalRender, PageLoading } from 'components'
import { MOBILE_WIDTH_SIZE, Mixpanel, seoTitleTemplate } from 'utils'
import useWindowSize from 'hooks/useWindowSize'
import SeoMeta from 'components/Global/SeoMeta'
import OrderHistory from '../Portfolio/partials/History/OrderHistory'
import PortfolioDetailHeader from './partials/PortfolioDetailHeader'
import PortfolioDetailSummary from './partials/PortfolioDetailSummary'
import { images } from 'assets'
import { getProductOrders } from 'slices/portfolioSlice'
import { useParams } from 'react-router-dom'
import MessagingCarousel from 'components/Global/MessagingCarousel'
import RecurringInvestmentCTA from 'components/Portfolio/partials/RecurringInvestmentCTA'
import RecurringInvestmentEvents from 'components/Portfolio/partials/History/RecurringInvestmentEvents'

const PortfolioDetail = () => {
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const { company, entities, recurringEvents, companyMessages } = useSelector((state) => state.portfolioSlice)
  const { portfolioDetailContentCards } = useSelector(state => state.commonSlice)
  const [pageLoading, setPageLoading] = useState(true)
  const { companyId } = useParams()

  useEffect(() => {
    fetchGetProductOrders()
  }, [])

  const fetchGetProductOrders = async () => {
    const res = await dispatch(getProductOrders({ companyId }))
    setPageLoading(false)
    const { payload } = res
    if (payload && payload?.company) {
      Mixpanel.track('View Portfolio Company Page', { 'Company Name': payload?.company?.name })
    }
  }

  const CompanyUpdateSection = () => (
    <div className='bg-background-mid-gray text-text-default rounded-lg p-4 mt-12 mx-0 mb-6'>
      <div className='flex items-center'>
        <img src={images['update-exclamation']} alt='Update Exclamation' />
        <div className='ml-1 text-text-default medium_4'>{companyMessages?.[0]?.title}</div>
      </div>
      <div className='lg:mt-4 mt-2 text-text-default'>{companyMessages?.[0]?.message}</div>
    </div>
  )

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('My Portfolio')} />
        <PageLoading />
      </>
    )
  }
  if (!company) {
    return null
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate(company.name)} />
      <div className='page-container page-with-breadcrumb'>
        <div className='inner-container'>
          {!!portfolioDetailContentCards?.length > 0 ? (
            <div className={`messaging-carousel-container ${portfolioDetailContentCards?.length === 1 ? 'mb-4 md:mb-6' : ''}`}>
              <MessagingCarousel data={portfolioDetailContentCards} page='Portfolio Detail' />
            </div>) : null}
          <PortfolioDetailHeader company={company} />
          <ConditionalRender isVisible={companyMessages?.length > 0 && width <= MOBILE_WIDTH_SIZE}>
            <CompanyUpdateSection />
          </ConditionalRender>
          <PortfolioDetailSummary company={company} />
          <ConditionalRender isVisible={companyMessages?.length > 0 && width > MOBILE_WIDTH_SIZE}>
            <CompanyUpdateSection />
          </ConditionalRender>
          {recurringEvents?.length === 0 && <RecurringInvestmentCTA company={company} />}
          <RecurringInvestmentEvents showCompany={false} groupBy={entities.length > 0 ? 'account' : 'investment'} />
          <OrderHistory showCompany={false} groupBy={entities.length > 0 ? 'account' : 'investment'} />
        </div>
      </div>
    </>
  )
}

export default PortfolioDetail
