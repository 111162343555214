const InvestDetailOrderPill = ({ active, amount, onClick }) => {
  return (
    <span
      onClick={onClick}
      role='button'
      aria-label='Quick Buy Button'
      tabIndex={0}
      className={`
        flex h-10 items-center justify-center 
        cursor-pointer w-[103px] md:w-[93px] max-w-full
        rounded-full
        ${active 
          ? 'p-0.5 bg-gradient-citron-turq'
          : 'p-4 bg-background-mid-gray'
        }
      `}
    >
      {active ? (
        <span className="flex items-center justify-center w-full h-full px-4 bg-white rounded-full text-text-default body_2">
          {amount}
        </span>
      ) : (
        <span className="text-text-default body_2">
          {amount}
        </span>
      )}
    </span>
  )
}

export default InvestDetailOrderPill
