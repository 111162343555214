export const CONFIG_FULLSTORY = 'configFullstory'

/**
 *
 * @param {Config} config
 * @returns {FullstoryConfig}
 * @constructor
 */
function FullstoryConfig (config) {
  Object.assign(this, config.get(CONFIG_FULLSTORY) || {})
  return this
}

export default FullstoryConfig

FullstoryConfig.prototype.getOrgId = function () {
  return this.orgId
}

FullstoryConfig.prototype.isValid = function () {
  return !!this.getOrgId()
}