import { images } from 'assets'
import { Wrapper, Image } from 'components'
import useWatchListContentful from 'components/Invest/useWatchListContentful'
import useWindowSize from 'hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { MOBILE_WIDTH_SIZE, useLDFlags } from 'utils'

const MobileInvestDetailHeader = ({ handleFavorite, isInterested, watchListNotifyLabel }, showWatchList = false) => {
  if (!showWatchList) return null
   return ( <div className='border shadow-none box border-stroke-on-white-light flex items-center justify-between p-5 cursor-pointer box invest-detail-box hover:bg-gray-100 !mt-4 !mb-0' onClick={handleFavorite}>
      <span className='body_1'>{watchListNotifyLabel}</span>
      <img src={images[isInterested ? 'watchlist-save-full' : 'watchlist-save-empty']} alt='star' />
    </div>
  )
}

const WebInvestDetailHeader = (vertical, founded, headquarters, website) => (
  <>
    <span className='medium_2 company-details__vertical text-text-secondary'>{vertical}</span>
    <div><span className='medium_2 text-text-secondary'>Founded:</span> <span className='medium_1 text-text-secondary'>{founded}</span></div>
    <div><span className='medium_2 text-text-secondary'>Headquarters:</span> <span className='medium_1 text-text-secondary'>{headquarters?.city}, {headquarters?.state}</span></div>
    <a target='_blank' rel='noopener noreferrer' href={`https://${website}`} className='medium_1 text-text-secondary'>{website}</a>
  </>
)

const InvestDetailHeader = ({ handleFavorite }) => {
  const watchListContentfulData = useWatchListContentful()
  const { showWatchList } = useLDFlags(['showWatchList'])
  const { watchListNotifyLabel } = watchListContentfulData || {}
  const { width } = useWindowSize()
  const { details } = useSelector(state => state.investSlice)

  const { founded, vertical, headquarters, website, isInterested } = details || {}

  let additionalCopy = ''

  if (width <= MOBILE_WIDTH_SIZE) {
    additionalCopy = MobileInvestDetailHeader({ handleFavorite, isInterested, watchListNotifyLabel }, showWatchList)
  } else {
    additionalCopy = WebInvestDetailHeader(vertical, founded, headquarters, website)
  }

  return (
    <Wrapper className='invest-header'>
      <div className='company-available'>
        <div className='company-details'>
          <Image
            className='invest-logo'
            alt={details.name}
            src={details.logoUrl}
          />
          {additionalCopy}
        </div>
      </div>
    </Wrapper>
  )
}
export default InvestDetailHeader
