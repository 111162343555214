import * as yup from 'yup'

/**
 * Schema is the builder we use for validation
 * 1) Define your field values and add any validation + required fields, with the error message
 * 2) Connect your schema with React-Hook-Form's useForm() hook: { resolver: yupResolver(schema) }
 */
export const schema = yup.object().shape({
  firstName: yup.string().required('Please enter first name.'),
  lastName: yup.string().required('Please enter last name.'),
  occupation: yup.string().required('Please enter occupation.'),
  companyName: yup.string().required('Please enter company name.'),
  advisorLicense: yup.string().required('Please enter financial professional license/CRD #'),
  street1: yup.string().required('Please enter address.'),
  country: yup.string().required('Please enter country.'),
  state: yup.string().required('Please enter state.'),
  city: yup.string().required('Please enter city.'),
  postalCode: yup.string().required('Please enter postal code.'),
  optionId: yup.string().required('Please enter option.'),
  licenseNumber: yup.string().when('optionId', {
    is: 'United States|3|SECURITIES_LICENSE',
    then: (schema) => schema.required('Please enter a license number.')
  }),
  verificationConfirmed: yup.bool().oneOf([true], 'Please confirm.')
})
