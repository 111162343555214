import { Modal, Button, TextInput, Tooltip } from 'components'
import { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { schema } from 'schemas/addFundsSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import InsufficientFundsErrors from '../partials/InsufficientFundsErrors'
import { MAX_UPHOLD_LIMIT } from 'components/FBOAddFunds/FBOAddFunds'
import { getNumericDollar, formatDecimal } from 'utils'
import useAddFundsRedesign from 'components/FBOAddFunds/AddFundsRedesign/useAddFundsRedesign'

const AddFundsRedesignDesktopModal = ({ hideModal }) => {
  const { contentLabels, achLimit, selectedAccount, insufficientError, setInsufficientError } =
  useAddFundsRedesign()
  const [showHover, setShowHover] = useState(false)

  const methods = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
    context: { achLimit: selectedAccount?.method === 'Uphold' ? MAX_UPHOLD_LIMIT : achLimit }
  })

  const {
    setValue,
    clearErrors,
    formState: { errors }
  } = methods

  const validatePriceValues = inputVal => {
    setValue('amount', formatDecimal(getNumericDollar(inputVal)))
  }

  return (
    <Modal crossToClose hideModal={hideModal}>
      <div className='flex flex-col justify-center '>
        <FormProvider {...methods}>
          <img
            src={contentLabels?.addFundsIcon?.media?.url}
            alt={contentLabels?.addFundsIcon?.media?.title}
            className='h-12'
          />
          <p className='mb-12 large_1 mt-0.5'>{contentLabels?.addFunds}</p>
          <div className='self-center'>
            <p className='text-left'>
              {contentLabels?.depositAmount}
              <Tooltip
                header={contentLabels?.depositAmountTooltip?.tooltipTitle}
                content={contentLabels?.depositAmountTooltip?.tooltipBody}
                showHover={showHover}
                setShowHover={setShowHover}
              />
            </p>
            <TextInput
              className='w-[444px] self-center'
              name='amount'
              ariaLabel='Amount'
              placeholder='$0.00'
              onChange={() => {
                if (insufficientError) {
                  setInsufficientError(null)
                  clearErrors('amount')
                }
              }}
              onBlur={e => {
                validatePriceValues(e.target.value)
              }}
              handleKeyPress={e => {
                const invalidEntry =
                  (e.key !== '.' && Number.isNaN(Number(e.key))) ||
                  e.key === null ||
                  e.key === ' ' ||
                  e.key === '-'
                if (e.key === 'Enter') {
                  e.target.blur()
                } else if (invalidEntry) {
                  e.preventDefault()
                }
              }}
            />
          </div>
          {insufficientError && <InsufficientFundsErrors />}
        </FormProvider>
        <div className='flex items-center justify-center'>
          <Button
            customClass='max-w-[444px] w-full'
            onClick={() => console.log('update')}
            disabled={Object.keys(errors)?.length > 0}
          >
            {contentLabels?.reviewButton}
          </Button>
        </div>
      </div>
    </Modal>
  )
}

export default AddFundsRedesignDesktopModal
