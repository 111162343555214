import { useEffect, useState, useRef } from 'react'
import { PageLoading, Wrapper } from 'components'
import ProductList from './partials/ProductList'
import ProductFilters from './partials/ProductFilters'
import { CircleLoader } from '../Global/ImageWithLoader'
import { useHistory } from 'react-router-dom'
import SeoMeta from 'components/Global/SeoMeta'
import _ from 'lodash'
import { seoTitleTemplate, Mixpanel, Fullstory } from 'utils'
import InvestAccountBanner from './partials/InvestAccountBanner'
import { useDispatch, useSelector } from 'react-redux'
import { getBundles, getInvestPage } from 'slices/investSlice'
import useFirstRender from 'hooks/useFirstRender'
import MessagingCarousel from 'components/Global/MessagingCarousel'
import useScrollEndHeight from 'hooks/useScrollEndHeight'
import BundleList from './partials/BundleList'
import LinqtoCapitalPlacementCopy from 'components/Global/LinqtoCapitalPlacementCopy'
import CampaignDrawer from './partials/CampaignDrawer'
import ReSignUpUserDrawer from 'components/Global/ReSignUpUserDrawer'

const Invest = () => {
  const [pageLoading, setPageLoading] = useState(true)
  const pageTitle = seoTitleTemplate('Invest')
  const pageDescription = 'Linqto is private investing made simple. Browse our product list to learn more about our offerings and to begin your journey in private equity investing.'
  const { needToCompleteInvestorProfile, cashPaymentEnabled, wirePaymentEnabled, hasWalletDeposit, isLoggedIn, verticals, loading, accreditedStatus } = useSelector(state => state.investSlice)
  const { featureFlags } = useSelector(state => state.userSlice)
  const { investContentCards, isMixpanelExist } = useSelector(state => state.commonSlice)
  const history = useHistory()
  const url = new URL(window.location)
  const params = new URLSearchParams(url.search)
  const intialSort = params.get('sort') || ''
  const intialVertical = params.get('vertical') || ''
  const intialTerm = params.get('term') || ''
  const [query, setQuery] = useState(intialTerm)
  const [vertical, setVertical] = useState(intialVertical)
  const [sort, setSort] = useState(intialSort)
  const searchInput = useRef()
  const sectorSelect = useRef()
  const sortSelect = useRef()
  const [showBanner, setShowBanner] = useState(false)
  const firstRender = useFirstRender()
  const heightRef = useScrollEndHeight()
  const dispatch = useDispatch()

  const { BundlePurchaseEnabled } = featureFlags || {}

  useEffect(() => {
    fetchInvestPage()
    setPageLoading(false)
  }, [vertical, query, sort])

  useEffect(() => {
    return () => {
      if (heightRef?.current > 0) {
        Mixpanel.track('Scroll on Product Invest Page', { 'Amount scrolled': `${heightRef?.current}%` })
      }
    }
  }, [])

  useEffect(() => {
    if (sessionStorage.getItem('dismissAccountBanner')) {
      setShowBanner(false)
    } else if (isLoggedIn && (needToCompleteInvestorProfile || (!hasWalletDeposit && cashPaymentEnabled && !wirePaymentEnabled))) {
      setShowBanner(true)
    } else {
      setShowBanner(false)
    }
    if (isMixpanelExist) {
      Mixpanel.setPeople({ 'Is Accredited': accreditedStatus === 'ACCREDITED' })
    }
    Fullstory.setUserProperty({ is_accredited: accreditedStatus === 'ACCREDITED' })
  }, [isLoggedIn, needToCompleteInvestorProfile, hasWalletDeposit, cashPaymentEnabled, wirePaymentEnabled])

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.value = query
    }
  })

  const fetchInvestPage = async () => {
    await dispatch(getInvestPage({ vertical, query, sort, firstRender }))
    await dispatch(getBundles())
    // after fetching data, go down to the users last scroll position
    const investFeedScroll = localStorage?.getItem('investFeedScroll')
    if (investFeedScroll) {
      window.scrollTo({ top: parseInt(investFeedScroll) })
    } else {
      window.scrollTo({ top:0 })
    }
  }

  const buildQueryURL = (sort, vertical, term) => {
    let urlString = '/products/'
    if ((sort && sort !== '') || vertical || term) {
      urlString += '?'
      if (sort && sort !== '') {
        urlString += `sort=${sort}`
      }
      if (vertical && vertical !== '') {
        urlString += `&vertical=${encodeURIComponent(vertical)}`
      }
      if (term && term !== '') {
        urlString += `&term=${term}`
      }
    }
    return urlString
  }

  const getParams = (type, value) => {
    const url = new URL(window.location)
    const params = new URLSearchParams(url.search)
    const vertical = (type === 'vertical') ? value : params.get('vertical')
    const term = (type === 'term') ? value : params.get('term')
    const sort = (type === 'sort') ? value : params.get('sort')
    return { sort, vertical, term }
  }

  const changeSearchFilter = (item, type) => {
    if (type === 'sort') setSort(item)
    if (type === 'vertical') setVertical(item)
    const { sort, vertical, term } = getParams(type, item)
    window.history.replaceState(null, null, buildQueryURL(sort, vertical, term))
  }

  const debounceFun = _.debounce(function (val) {
    Mixpanel.track('Search on Invest Page', { 'Search Term': val })
    searchInvestList(val)
  }, 250, {})

  const searchInvestList = (val) => {
    setQuery(val)
    searchInput.current.focus()
    const { sort, vertical, term } = getParams('term', val)
    window.history.replaceState(null, null, buildQueryURL(sort, vertical, term))
  }

  const showProductDetails = (urlName, companyName, sharePrice) => {
    Mixpanel.track('Click Company on Product Invest Page', { 'Company Name': companyName, 'Share Price': sharePrice })
    Fullstory.track('Card Click', { name: 'Company', company: companyName, share_price: sharePrice })
    // save the scroll position before navigating to the product details page
    localStorage?.setItem('investFeedScroll', window.scrollY)
    history.push(`/products/${urlName}`)
  }

  if (pageLoading) {
    return <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <PageLoading />
    </>
  }
  const showBundle = BundlePurchaseEnabled && !query && !vertical && !sort
  return (
    <>
      <SeoMeta title={pageTitle} description={pageDescription} />
      <Wrapper className='page-container invest-container'>
        <div className='inner-container'>
          <div className='container'>
            {showBanner && <InvestAccountBanner setShowBanner={setShowBanner} />}
            {isLoggedIn && investContentCards?.length ? (
              <div className={`messaging-carousel-container ${!showBanner ? '-mt-5 md:-mt-9' : 'mt-4'}`}>
                <MessagingCarousel data={investContentCards} page='Invest' />
              </div>) : null}
            <ProductFilters
              changeSearchFilter={changeSearchFilter}
              verticals={verticals}
              debounceFun={debounceFun}
              searchInputRef={searchInput}
              sectorSelectRef={sectorSelect}
              sortSelectRef={sortSelect}
              vertical={vertical}
              sort={sort}
              term={query}
              setQuery={setQuery}
              loading={loading}
            />
            {showBundle && <BundleList />}
            {!loading && <ProductList showProductDetails={showProductDetails} hasFilter={vertical || query}/>}
            {loading && <CircleLoader /> }
          </div>
        </div>
      </Wrapper>
      <CampaignDrawer flag='campaignShowNewCompanyDatabricks' />
      <LinqtoCapitalPlacementCopy marginTop={0}/>
      <ReSignUpUserDrawer />
    </>
  )
}

export default Invest
