import moment from 'moment'
import React from 'react'
import { useSelector } from 'react-redux'
import { formatNumberWithLetter } from 'utils'

const InformationContainer = ({ isSummary = false }) => {
  const { details } = useSelector(state => state.investSlice)
  const { totalRaised, postMoney, lastRound, industry, vertical, employeeCount } = details || {}
  const { amount, round, date } = lastRound || {}
  const { industry: primaryIndustry } = industry || {}

  if (!isSummary) {
    return (
      <div className='border border-stroke-on-white-light column eight row valuation-block min-w-[320px] shrink-[2]'>
        <div className='valuation-block__top'>
          {round && <div className='valuation-block__info'>
            <span className='body_1 text-text-secondary'>Last Round</span>
            <span className='medium_2 text-wrap'>{moment.utc(date).format('MMM. YYYY')}, {round}</span>
          </div>}
          {postMoney && <div className='valuation-block__info'>
            <span className='body_1 text-text-secondary'>Valuation Post-Money</span>
            <span className='medium_2'>{formatNumberWithLetter(postMoney, true, true, 2, true)}</span>
          </div>}
          {amount && <div className='valuation-block__info'>
            <span className='body_1 text-text-secondary'>Amount Raised</span>
            <span className='medium_2'>{formatNumberWithLetter(amount, true, true, 2, true)}</span>
          </div>}
          {totalRaised && <div className='valuation-block__info'>
            <span className='body_1 text-text-secondary'>Total Funds Raised</span>
            <span className='medium_2'>{formatNumberWithLetter(totalRaised, true, true, 2, true)}</span>
          </div>}
        </div>
      </div>
    )
  }

  return (
    <div className='border column eight row valuation-block border-stroke-on-white-light'>
      <div className='valuation-block__top'>
        {primaryIndustry && <div className='valuation-block__info'>
          <span className='body_1 text-text-secondary'>Industry</span>
          <span className='medium_2'>{primaryIndustry}</span>
        </div>}
        {vertical && <div className='valuation-block__info'>
          <span className='body_1 text-text-secondary'>Primary Vertical</span>
          <span className='medium_2'>{vertical}</span>
        </div>}
        {employeeCount && <div className='valuation-block__info'>
          <span className='body_1 text-text-secondary'>Employees</span>
          <span className='medium_2'>{employeeCount}</span>
        </div>}
      </div>
    </div>
  )
}

export default InformationContainer
