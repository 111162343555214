import USDocumentUpload from '../partials/USDocumentUpload'
import { USDoceumentTypes, InitialDocumentsToUpload } from '../../data'

const ManualUploadDocument = ({ type }) => {
  return (
    <>
      <USDocumentUpload
        dropDownList={USDoceumentTypes[type]}
        intialDocsToUpload={InitialDocumentsToUpload[type] || InitialDocumentsToUpload['NONE']}
      />
    </>
  )
}

export default ManualUploadDocument
