import { useState, useEffect } from 'react'
import { images } from 'assets'
import { formatDecimal, Mixpanel, getDomain } from 'utils'
import { useSelector } from 'react-redux'
import { Button } from 'components'

const RewardShare = ({ positionIndex }) => {
  const { referralCode, signUpBonus } = useSelector(state => state.rewards)
  const [showCopiedMessage, setShowCopiedMessage] = useState(false)
  const referralUrl = `${getDomain('/refer-friend', false)}?r=${referralCode}`
  const shareTitle = 'Join Linqto with my personal link 💵'
  const shareTxt =
    signUpBonus > 0
      ? `Join Linqto with my personal link and get ${formatDecimal(signUpBonus, true, 0)} Linqto Bucks at sign up. ${encodeURIComponent(referralUrl)}`
      : `Join Linqto with my personal link. ${encodeURIComponent(referralUrl)}`

  const linkCopy = (referralUrl) => {
    Mixpanel.track('Copy Referral Link', { index: positionIndex })
    navigator.clipboard.writeText(referralUrl)
    setShowCopiedMessage(true)
  }

  useEffect(() => {
    if (showCopiedMessage) {
      const timer = setTimeout(() => {
        setShowCopiedMessage(false) 
      }, 1500)
      return () => clearTimeout(timer)
    }
  }, [showCopiedMessage])

  const facebookShare = (referralUrl) => {
    trackShareReferralLink('Facebook')
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      referralUrl
    )}`
    const win = window.open(url, '_blank')
    win.focus()
  }

  const XShare = (shareTxt) => {
    trackShareReferralLink('X')
    const url = `https://twitter.com/intent/tweet?text=${shareTxt}`
    const win = window.open(url, '_blank')
    win.focus()
  }

  const LinkedinShare = (referralUrl) => {
    trackShareReferralLink('LinkedIn')
    const url = `https://www.linkedin.com/shareArticle?mini=true&url=${encodeURIComponent(
      referralUrl
    )}`
    const win = window.open(url, '_blank')
    win.focus()
  }

  const emailShare = (shareTitle, shareTxt) => {
    trackShareReferralLink('Email')
    const url = `mailto:?subject=${shareTitle}&body=${shareTxt}`
    window.open(url, '_blank')
  }

  const trackShareReferralLink = (Type) => Mixpanel.track('Share Referral Link', { Type, index: positionIndex })

  return (
    <section className='flex items-center my-4 mt-6'>
      {/* Show the copied message below the button */}
      <div className='relative'>
        <Button mode='secondary' onClick={() => linkCopy(referralUrl)} ariaLabel='Copy the referral link' data-testid='copy-share-btn'>
          <img
            src={images.rewards_icon_copy}
            alt='Copy the link'
            className='mr-2'
          />
      Copy Your Link</Button>
        {showCopiedMessage && (
          <div className='absolute px-4 py-2 text-sm font-medium transform -translate-x-1/2 -translate-y-2 rounded-lg shadow-ambient-shadow bottom-14 left-1/2 bg-background-scrim text-text-on-dark-bg min-w-36'>
            <div className='flex items-center space-x-2'>
              <img
                src={images.check_circle_white}
                alt='Linked Copied'
              />
              <span>Link Copied!</span>
            </div>
            <div className='absolute w-3 h-3 transform rotate-45 -translate-x-1/2 left-1/2 bg-background-scrim -bottom-1'></div>
          </div>
        )}
      </div>
      <div className='hidden lg:flex'>
        <Button
          ariaLabel='Share the referral link via email'
          className='flex items-center justify-center w-12 h-12 mx-2 leading-4 border-0 rounded-full cursor-pointer outline-0 bg-legacy-citron hover:shadow-ambient-shadow'
          onClick={() => emailShare(shareTitle, shareTxt)}
        >
          <img src={images.rewards_icon_email} alt='Share link by email' />
        </Button>
        <Button
          ariaLabel='Share the referral link with Facebook'
          data-testid='facebook-share-btn'
          className='flex items-center justify-center w-12 h-12 mx-2 leading-4 border-0 rounded-full cursor-pointer outline-0 bg-legacy-citron hover:shadow-ambient-shadow'
          onClick={() => facebookShare(referralUrl)}
        >
          <img
            src={images.rewards_icon_facebook}
            alt='Share link with Facebook'
          />
        </Button>
        <Button
          data-testid='x-share-btn'
          ariaLabel='Share the referral link with X'
          className='flex items-center justify-center w-12 h-12 mx-2 leading-4 border-0 rounded-full cursor-pointer outline-0 bg-legacy-citron hover:shadow-ambient-shadow'
          onClick={() => XShare(shareTxt)}
        >
          <img
            src={images.rewards_icon_X}
            alt='Share link with X'
          />
        </Button>
        <Button
          data-testid='linkedin-share-btn'
          ariaLabel='Share the referral link with Linkedin'
          className='flex items-center justify-center w-12 h-12 mx-2 leading-4 border-0 rounded-full cursor-pointer outline-0 bg-legacy-citron hover:shadow-ambient-shadow'
          onClick={() => LinkedinShare(referralUrl)}
        >
          <img
            src={images.rewards_icon_linkedin}
            alt='Share link with Linkedin'
          />
        </Button>
      </div>
    </section>
  )
}

export default RewardShare
