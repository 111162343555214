import React from 'react'
import {
  AreaChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Area,
  ReferenceLine
} from 'recharts'
import { formatDecimal, getPreviousMonthTimestamp, getNextMonthTimestamp } from 'utils'
import { 
  CHART_COLORS, 
  CHART_DIMENSIONS, 
  getThreePoints,
  generateQuarterTicks,
  formatXAxis
} from './utils'

const CustomXAxisTick = ({ x, y, payload, isLastTick, isMobile, isSingleDataPoint, currentDate }) => {
  const adjustedX = isLastTick && isMobile ? x - 25 : x
  if (isSingleDataPoint) {
    if (payload.value === currentDate) {
      return (
        <g transform={`translate(${adjustedX},${y})`}>
          <text
            x={0}
            y={0}
            dy={16}
            textAnchor='middle'
            className='small_1 fill-text-text-secondary'>
            {formatXAxis(payload.value)}
          </text>
        </g>
      )
    }
    return null
  }

  return (
    <g transform={`translate(${adjustedX},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor='middle'
        className='small_1 fill-text-text-secondary'>
        {formatXAxis(payload.value)}
      </text>
    </g>
  )
}

const CustomYAxisTick = ({ x, y, payload }) => (
  <g transform={`translate(${x},${y})`}>
    <text
      x={0}
      y={0}
      dy={3}
      textAnchor='end'
      className='small_1 fill-text-text-secondary'
    >
      {`$${Math.floor(payload.value)}`}
    </text>
  </g>
)

const SingleDataTooltip = ({ dataPoint }) => {
  if (!dataPoint) return null
  return (
    <div className='absolute bg-background-default rounded-lg min-w-[100px] text-center shadow-ambient-shadow p-2'>
      <div className='small_2 text-text-default'>{formatDecimal(dataPoint.price)}</div>
      <div className='text-xs text-text-default'>New Company</div>
    </div>
  )
}

const CustomTooltip = ({ active, payload, label, singleDataPoint }) => {
  if (singleDataPoint) return null

  if (!active || !payload?.length) return null

  const date = new Date(label)
  const formattedDate = date.toLocaleDateString('en-US', {
    month: 'short',
    year: 'numeric'
  })

  return (
    <div className='p-2 rounded-lg bg-background-default shadow-ambient-shadow'>
      <div className='small_2 text-text-default'>{formatDecimal(payload[0].value)}</div>
      <div className='text-xs text-text-default'>{formattedDate}</div>
    </div>
  )
}

const PriceChangeDisplay = ({ value, percentage }) => {
  if (!value || percentage === '-') {
    return <span className='medium_1 md:px-8' >0.00 (0.00%) Past month</span>
  }

  const isPositive = value > 0
  const formattedValue = Math.abs(value).toFixed(2)
  
  return (
    <span className={`medium_1 md:px-8 ${isPositive ? 'text-text-success' : 'text-text-error'}`}>
      {isPositive ? '+' : '-'}${formattedValue} ({percentage}%) Past month
    </span>
  )
}

export const PriceHistoryChartRenderer = (data, isMobile, updateLabel = '') => {
  const priceHistory = data?.priceHistoryData?.priceHistory || []
  const isSingleDataPoint = priceHistory.length === 1
  
  const currentPriceData = priceHistory[priceHistory.length - 1] || { price: 0 }
  const previousMonthData = priceHistory[priceHistory.length - 2] || { price: currentPriceData.price }
  
  const currentPrice = currentPriceData.price
  const previousPrice = previousMonthData.price

  const priceDifference = currentPrice - previousPrice
  const percentageChange = previousPrice !== 0 
    ? ((priceDifference / previousPrice) * 100).toFixed(1)
    : 0

  let chartData = [...priceHistory]
  
  if (isSingleDataPoint && currentPriceData) {
    const startDate = getPreviousMonthTimestamp(currentPriceData.date)
    chartData = [
      {
        ...currentPriceData,
        date: startDate,
      },
      currentPriceData
    ]
  }

  const prices = chartData.map(item => item.price || 0)
  const minPrice = prices.length ? Math.min(...prices) : 0
  const maxPrice = prices.length ? Math.max(...prices) : 100
  
  const yAxisTicks = getThreePoints(minPrice, maxPrice, isSingleDataPoint)
  const yDomain = [yAxisTicks[0], yAxisTicks[2]]

  const xDomain = isSingleDataPoint ? 
    [chartData[0].date, getNextMonthTimestamp(currentPriceData.date)] :
    ['dataMin', 'dataMax']

  return (
    <>
      <div className='flex items-end justify-between mb-8'>
        <div>
          <h2 className='large_1 text-text-default md:px-8'>
            {formatDecimal(currentPrice)}
          </h2>
          <PriceChangeDisplay 
            value={priceDifference} 
            percentage={percentageChange !== 0 ? `${percentageChange}` : '-'} 
          />
        </div>
        <p className='hidden mt-2 body_1 text-text-secondary md:block md:px-8'>
          {updateLabel}
        </p>
      </div>
      
      <div>
        <div className='relative h-[340px] pb-4'>
          <ResponsiveContainer width='100%'>
            <AreaChart
              data={chartData}
              margin={{ top: 10, right: isMobile ? 0 : 40, left: isMobile ? -25 : 0, bottom: 5 }}
            >
              <defs>
                <linearGradient id='areaGradient' x1='0' y1='0' x2='0' y2='1'>
                  <stop offset='5%' stopColor={CHART_COLORS.line} stopOpacity={0.1} />
                  <stop offset='95%' stopColor={CHART_COLORS.line} stopOpacity={0.11} />
                </linearGradient>
              </defs>

              <CartesianGrid
                horizontal={true}
                vertical={false}
                strokeDasharray='none'
                style={{
                  stroke: CHART_COLORS.stroke,
                  strokeWidth: '1px'
                }}
              />

              {isSingleDataPoint && (
                <ReferenceLine
                  x={currentPriceData.date}
                  stroke={CHART_COLORS.line}
                  strokeWidth={2}
                  strokeDasharray='4'
                />
              )}

              <Tooltip
                content={(props) => (
                  <CustomTooltip 
                    {...props} 
                    singleDataPoint={isSingleDataPoint}
                  />
                )}
                cursor={{
                  stroke: '#0066FF',
                  strokeWidth: 1,
                  strokeDasharray: '4'
                }}
                separator=''
              />
              <Area
                type='monotone'
                dataKey='price'
                fill={CHART_COLORS.fill}
                fillOpacity={0.05}
                stroke={CHART_COLORS.line}
                strokeWidth={CHART_DIMENSIONS.strokeWidth}
                baseLine={yDomain[0]}
                dot={isSingleDataPoint ? (props) => {
                  // Only show dot for the current date point
                  if (props.payload.date === currentPriceData.date) {
                    return (
                      <circle
                        cx={props.cx}
                        cy={props.cy}
                        r={5}
                        fill='white'
                        stroke='#0066FF'
                        strokeWidth={2}
                      />
                    )
                  }
                  return null
                } : false}
                activeDot={{
                  r: 5,
                  fill: 'white',
                  stroke: '#0066FF',
                  strokeWidth: 2
                }}
              />

              <XAxis
                dataKey='date'
                ticks={isSingleDataPoint ? 
                  [chartData[0].date, currentPriceData.date] :
                  generateQuarterTicks(chartData, isMobile)
                }
                type='number'
                domain={xDomain}
                interval={0}
                tick={(props) => (
                  <CustomXAxisTick 
                    {...props} 
                    isMobile={isMobile}
                    isLastTick={!isSingleDataPoint && props.index === generateQuarterTicks(chartData, isMobile).length - 1}
                    isSingleDataPoint={isSingleDataPoint}
                    currentDate={currentPriceData.date}
                  />
                )}
                axisLine={{ stroke: CHART_COLORS.stroke, strokeWidth: 1 }}
                tickLine={false}
                padding={{ left: 20, right: 20 }}
              />
              
              <YAxis
                domain={yDomain}
                ticks={yAxisTicks}
                tick={<CustomYAxisTick />}
                axisLine={false}
                tickLine={false}
                dx={-5}
              />

              <Tooltip
                content={(props) => (
                  <CustomTooltip 
                    {...props} 
                    singleDataPoint={isSingleDataPoint}
                  />
                )}
                cursor={{
                  stroke: '#0066FF',
                  strokeWidth: 1,
                  strokeDasharray: '4'
                }}
                separator=''
              />
            </AreaChart>
          </ResponsiveContainer>
          
          {isSingleDataPoint && (
            <div 
              className='absolute'
              style={{
                left: '50%',
                top: '20%',
                transform: 'translate(-50%, -50%)'
              }}
            >
              <SingleDataTooltip dataPoint={currentPriceData} />
            </div>
          )}
        </div>
      </div>
      <p className='text-center md:hidden body_1 text-text-secondary'>
        {updateLabel || 'Updated on the 1st of every month'}
      </p>
    </>
  )
}