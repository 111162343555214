import { useFormContext } from 'react-hook-form'
import TooltipLabel from 'components/Global/TooltipLabel'
import ErrorMessage from 'components/Global/Inputs/ErrorMessage'

const EntityTypeSelect = ({
  name,
  label,
  content = '',
  disabled = false,
  ariaLabel = '',
  className = '',
  options = []
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()

  return (
    <div className={`input-group ${errors[name] && 'error'} ${className}`}>
      <TooltipLabel content={content} label={label} />
      <select className={`border bg-background-default border-stroke-on-white-dark text-text-default hover:border-stroke-cobalt-blue hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue ${errors[name] ? 'border border-stroke-alert-red hover:border-stroke-alert-red focus:border-stroke-alert-red' : ''}`} {...register(name)} disabled={disabled} aria-label={ariaLabel}>
        <option value=''>Please select</option>
        {options && options.length > 0 && options.map((e, i) => <option value={e} key={`${i}-${e}`}>{e}</option>)}
      </select>
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default EntityTypeSelect
