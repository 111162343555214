import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getDrawerContent, useLDFlags } from 'utils';
import { contentfulContentIds } from 'components/data';
import useContentful from 'hooks/useContentful';

const useReSignUpUser = () => {
  const { showRevalidateUserDrawer } = useLDFlags(['showRevalidateUserDrawer'])
  const [showCampaign, setShowCampaign] = useState(false)
  const [contentfulDrawerData, setContentfulDrawerData] = useState(null)
  const { needsReAccreditation, needsReKyc } = useSelector(state => state.userSlice)
  const { content: contentfulData } = useContentful(contentfulContentIds.reVerifyUserDrawerId)

  useEffect(() => {
    if (!contentfulData?.fields || sessionStorage.getItem('resignup-drawer') === 'dismissed' || !showRevalidateUserDrawer) return

    setContentfulDrawerData(getDrawerContent(contentfulData.fields))
    setShowCampaign(true)
  }, [contentfulData, needsReAccreditation, needsReKyc, showRevalidateUserDrawer])

  const handleClose = () => {
    setShowCampaign(false)
    sessionStorage.setItem('resignup-drawer', 'dismissed')
  }

  return [showCampaign, handleClose, contentfulDrawerData]
}

export default useReSignUpUser
