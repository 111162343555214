// NOTE: keeping this component for past ATS V1 sell offer that has already placed.
import Breadcrumb from 'components/Global/Breadcrumb'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading, Tooltip } from 'components'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, Link, useHistory } from 'react-router-dom'
import { getSellOfferDetail } from 'slices/sellOrderSlice'
import { seoTitleTemplate, Mixpanel, formatDecimal, formatShares } from 'utils'
import SellOrderItem from '../SellOrder/SellOrderDetail/partials/SellOrderItem'
import moment from 'moment'
import { transactionFeeCopy } from 'components/data'
const style = {
  cancelled: 'text-text-error bg-fill-light-red',
  canceled: 'text-text-error bg-fill-light-red',
  closed: 'bg-background-dark-gray text-text-default',
  completed: 'bg-logo-mint text-text-default',
  active: 'bg-logo-turq text-text-default'
}
const SellOfferDetail = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { companyUrlName, companyName, pageLoading, completedOrders, companyIconUrl, sharePrice, shareCount, subTotal, transactionFee, transactionFeeRate, totalProceeds, projectedGain, offerStatus, createdAt, deactivatedAt, transactionFlatFee, accountOwnerName } = useSelector(state => state.sellOrder)
  const { id } = useParams()
  const items = [{ name: 'My Portfolio', route: '/portfolio' }, { name: companyName, route: `/portfolio/${companyUrlName}` }]
  const statusLabel = offerStatus === 'Canceled' ? 'Cancelled' : offerStatus
  const [showHover, setShowHover] = useState(false)
  const [showFlatFeeHover, setFlatFeeShowHover] = useState(false)

  useEffect(() => {
    dispatch(getSellOfferDetail(id)).then(({ meta, payload }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Sell Offer Details Page (ATS)', { 'Company Name': payload.companyName })
      }
    })
  }, [id])

  const pushToSellOrder = (id) => {
    history.push(`/sell-order/${id}`)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Offer Summary')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Offer Confirmed')} />
      <div className='page-container sell-order-container'>
        <div className='inner-container'>
          <Breadcrumb items={items} />
          <div className='sell-order-header'>
            <h1>Sell Offer Details</h1>
            <div className='sell-order-company-name'>
              <img className='border border-stroke-on-white-light' src={companyIconUrl} alt={companyName} />
              <h3>{companyName}</h3>
            </div>
          </div>
          <div className='sell-order-page-container'>
            <div className='border border-stroke-on-white-light sell-order-summary-container bg-background-default'>
              {offerStatus !== 'Pending' && <div className={`text-text-default sell-order-summary-container__badge ${offerStatus?.toLowerCase()} ${style[offerStatus?.toLowerCase()]}`}>
                <span>{statusLabel}</span>
              </div>}
              <span className='text-text-default'>Offers remain active until cancelled or sold. All sales are final.</span>
              {id && <div className='sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__copy'>Offer ID</span>
                <span className='sell-order-summary-container__row__copy'>{id}</span>
              </div>}
              <div className='sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__copy'>Offer Created</span>
                <span className='sell-order-summary-container__row__copy'>{moment(createdAt).format('MM/DD/YYYY')}</span>
              </div>
              { offerStatus === 'Canceled' && deactivatedAt && <div className='sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__copy'>Cancelled on</span>
                <span className='sell-order-summary-container__row__copy'>{moment(deactivatedAt).format('MM/DD/YYYY')}</span>
              </div>}
              <div className='sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__copy'>Account</span>
                <span className='sell-order-summary-container__row__copy'>{accountOwnerName}</span>
              </div>
              <div className='sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__copy'>Asking price per unit (USD)</span>
                <span className='sell-order-summary-container__row__copy'>{formatDecimal(sharePrice)}</span>
              </div>
              <div className='sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__copy'>Units</span>
                <span className='sell-order-summary-container__row__copy'>{formatShares(shareCount)}</span>
              </div>
              <div className='sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__copy'>Subtotal</span>
                <span className='sell-order-summary-container__row__copy'>{formatDecimal(subTotal)}</span>
              </div>
              <div className='sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__copy'>
                  Transaction Fee
                  <Tooltip content={transactionFeeCopy(transactionFeeRate)} showHover={showHover} setShowHover={setShowHover} />
                </span>
                <span className='sell-order-summary-container__row__copy'>-{formatDecimal(transactionFee)}</span>
              </div>
              <div className='sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__copy'>
                Regulatory Filing Fee
                  <Tooltip content='This flat fee helps cover local and federal regulatory filing expenses required for sales of private equity. If your offer sells across multiple orders, this fee is applied prior to distributing proceeds.' showHover={showFlatFeeHover} setShowHover={setFlatFeeShowHover} />
                </span>
                <span className='sell-order-summary-container__row__copy'>-{formatDecimal(transactionFlatFee)}</span>
              </div>
              <div className='pt-2 mt-2 border-t sell-order-summary-container__row border-t-stroke-on-white-dark'>
                <div>
                  <p className='m-0 text-text-default'>Total Proceeds</p>
                  {offerStatus !== 'Canceled' && <p className='m-0 text-text-secondary'>To be deposited in your cash account</p>}
                </div>
                <span className='m-0 text-text-default'>{formatDecimal(totalProceeds)}</span>
              </div>
              <div className='sell-order-summary-container__row'>
                <span className='sell-order-summary-container__row__copy'>Projected Gain</span>
                <span className='sell-order-summary-container__row__copy'>{formatDecimal(projectedGain)}</span>
              </div>
            </div>
            {completedOrders && completedOrders.length &&
            <>
              <h1 className='medium_2 sell-order-summary-container__completed-sales-header'>Completed Sales</h1>
              {completedOrders.map(order => (
                <SellOrderItem
                  key={order.sellOrderId}
                  order={order}
                  handleClick={() => pushToSellOrder(order?.sellOrderId)}
                />))
              }</>}
            <div className='medium_1 space-above-sm'>
            Have a question about your offer?{' '}
              <Link onClick={() => Mixpanel.track('Click Contact Us', { Location: 'Sell Offer Details' })}
                to={`/contact?topic=Order Support&message=Re: Sell Offer ID ${id}`}
                className='link text-text-hyperlink contact-us'>
                Contact Us
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SellOfferDetail
