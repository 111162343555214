import { useState } from 'react'
import Tooltip from 'components/Global/Tooltip'
import { useSelector } from 'react-redux'
import { formatDecimal, formatShares } from 'utils'
import { useFormContext } from 'react-hook-form'
import SellOrderForm from './SellOrderForm'
import { availableSharesToSellTooltip, transactionFeeCopy} from 'components/data'

const SellOrderContainer = ({ type = 'create', setSelectedAccount, selectedAccount, selectedAvailableShares, setSelectedAvailableShares, renderErrorModal }) => {
  const [showHover, setShowHover] = useState(false)
  const [showFlatFeeHover, setFlatFeeShowHover] = useState(false)
  const [showAvailableSharesHover, setAvailableSharesHover] = useState(false)
  let { sharePrice, shareCount, subTotal, transactionFee, transactionFeeRate, totalProceeds, projectedGain, offerStatus, transactionFlatFee, accountOwnerName, purchaseCreditPercentage } = useSelector(state => state.sellOrder)
  const { featureFlags } = useSelector(state => state.userSlice)

  const transactionFlatFeeValue = transactionFlatFee
  sharePrice = sharePrice === 0 ? '-' : formatDecimal(sharePrice)
  shareCount = type === 'create' ? selectedAvailableShares === 0 ? '-' : formatShares(selectedAvailableShares) : shareCount
  subTotal = subTotal === 0 ? '-' : formatDecimal(subTotal)
  transactionFee = transactionFee === 0 ? '' : formatDecimal(transactionFee)
  transactionFlatFee = transactionFlatFee === 0 ? '-' : formatDecimal(transactionFlatFee)
  totalProceeds = totalProceeds === 0 ? '-' : formatDecimal(totalProceeds)
  projectedGain = projectedGain === 0 ? '-' : formatDecimal(projectedGain)

  let bottomContainer = null

  const { formState: { errors } } = useFormContext()
  const minimumOrderError = errors?.sharesForAccount?.type === 'orderMinimum' || false

  if (type === 'create') {
    bottomContainer = (
      <div className='sell-order-summary-container__row'>
        <div>
          <p className='m-0 text-text-default'>Estimated Total Proceeds</p>
        </div>
        <span className='m-0 text-text-default'>{totalProceeds}</span>
      </div>
    )
  } else {
    bottomContainer = <>
      <div className='sell-order-summary-container__row'>
        <div>
          <p className='m-0 text-text-default'>Estimated Total Proceeds</p>
          {offerStatus !== 'Canceled' && <p className='m-0 text-text-secondary'>To be deposited in your cash account</p>}
        </div>
        <span className='m-0 text-text-default'>{totalProceeds}</span>
      </div>
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>Projected Gain</span>
        <span className='sell-order-summary-container__row__copy'>{projectedGain}</span>
      </div>
    </>
  }

  return (
    <div className='border border-stroke-on-white-light sell-order-summary-container bg-background-default'>
      {type === 'create' &&
        <SellOrderForm
          setSelectedAccount={setSelectedAccount}
          selectedAccount={selectedAccount}
          selectedAvailableShares={selectedAvailableShares}
          setSelectedAvailableShares={setSelectedAvailableShares}
          renderErrorModal={renderErrorModal}
        /> }
      {type === 'summary' && <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>Account</span>
        <span className='sell-order-summary-container__row__copy'>{accountOwnerName}</span>
      </div>
      }
      {type === 'create' &&
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>Available Units
          <Tooltip
            content={availableSharesToSellTooltip}
            showHover={showAvailableSharesHover}
            setShowHover={setAvailableSharesHover}
          />
        </span>
        <span className='sell-order-summary-container__row__copy'>{shareCount}</span>
      </div>
      }
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>Estimated Unit Price</span>
        <span className='sell-order-summary-container__row__copy'>{sharePrice}</span>
      </div>
      {type === 'summary' &&
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>Units to Sell</span>
        <span className='sell-order-summary-container__row__copy'>{shareCount}</span>
      </div>
      }
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>Subtotal</span>
        <span className={`m-0 text-text-default ${minimumOrderError ? 'error' : ''}`}>{subTotal}</span>
      </div>
      {minimumOrderError && <div className='sell-order-summary-container__row'>
        <span className='error'>Minimum sell order is $5,000</span>
      </div>
      }
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>
          Transaction Fee
          <Tooltip
            content={transactionFeeCopy(transactionFeeRate)}
            showHover={showHover}
            setShowHover={setShowHover}
          />
        </span>
        <span className='sell-order-summary-container__row__copy'>-{transactionFee}</span>
      </div>
      {featureFlags?.PurchaseCreditEnabled && purchaseCreditPercentage && <span className='medium_2 text-text-secondary' data-testid='purchase-credit-copy'>
      Purchase Credit: You&apos;ll receive a discount of <span className='medium_2 text-text-default'>{purchaseCreditPercentage * 100}%</span> of transaction fee on your next purchase.
      </span>}
      {transactionFlatFeeValue > 0 &&
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>
          Regulatory Filing Fee
          <Tooltip
            content='This flat fee helps cover local and federal regulatory filing expenses required for sales of private equity. If your offer sells across multiple orders, this fee is applied prior to distributing proceeds.'
            showHover={showFlatFeeHover}
            setShowHover={setFlatFeeShowHover}
          />
        </span>
        <span className='sell-order-summary-container__row__copy'>-{transactionFlatFee}</span>
      </div>
      }
      {bottomContainer}
    </div>
  )
}

export default SellOrderContainer
