import { FullStory as FS, init, isInitialized } from '@fullstory/browser'

const fullstoryOrgId = {
  'app.dev.linqto.com': 'o-1THW9H-na1',
  'app.dev.linqto.io': 'o-1THW9H-na1',
  'app.alpha.linqto.com': 'o-1THW9H-na1',
  'app.beta.linqto.com': 'o-1THW9H-na1',
  'app.growth.linqto.com': 'o-1THW9H-na1',
  'app.growth.linqto.io': 'o-1THW9H-na1',
  'app.team-ai.linqto.io': 'o-1THW9H-na1',
  'app.trade.linqto.com': 'o-1THW9H-na1',
  'app.trade.linqto.io': 'o-1THW9H-na1',
  'app.linqto.com': 'o-1THW9H-na1'
}

const Fullstory = (() => {
  return {
    isInitialized: () => isInitialized(),
    /**
     *
     * @param {FullstoryConfig} config
     */
    initialize: (config) => {
      if(config.isValid()){
        init({ orgId: config.getOrgId(), debug: false })
        return
      }
      init({ orgId: fullstoryOrgId[window.location.hostname], debug: false })
    },
    track: (eventName, properties = {}) => {
      if (isInitialized()) { // Check if FullStory is initialized
        FS('trackEvent', {
          name: eventName,
          properties
        })
      }
    },
    identify: (id) => {
      if (isInitialized()) { // Check if FullStory is initialized
        FS('setIdentity', { uid: id })
      }
    },
    setUserProperty: (properties) => {
      if (isInitialized()) { // Check if FullStory is initialized
        FS('setProperties', {
          type: 'user',
          properties
        })
      }
    },
    stopTracking: () => {
      if (isInitialized()) { // Check if FullStory is initialized
        FS('shutdown')
      }
    },
    resumeTracking: () => {
      if (isInitialized()) { // Check if FullStory is initialized
        FS('restart')
      }
    },
    getSessionUrl: () => {
      if (isInitialized()) { // Check if FullStory is initialized
        return FS('getSession', { format: 'url' })
      }
    }
  }
})()

export { Fullstory }
