const ProgressBarRevamp = ({ step = 1, type = 'full' }) => {
  return (
    <div className='acc-progress-bar' id='progress-bar-revamp'>
      <div className={`bg-background-dark-gray acc-progress-bar-step ${step >= 1 ? 'after:bg-fill-cobalt-primary current' : ''} ${step > 1 ? 'full' : type}`} />
      <div className={`bg-background-dark-gray acc-progress-bar-step ${step >= 2 ? 'after:bg-fill-cobalt-primary current' : ''} ${step > 2 ? 'full' : type}`} />
      <div className={`bg-background-dark-gray acc-progress-bar-step ${step >= 3 ? 'after:bg-fill-cobalt-primary current' : ''} ${step > 3 ? 'full' : type}`} />
      <div className={`bg-background-dark-gray acc-progress-bar-step ${step === 4 ? 'after:bg-fill-cobalt-primary current' : ''} ${type}`} />
    </div>
  )
}

export default ProgressBarRevamp
