const parsingCoreUser = (coreUserPrms) => {
  const result = {}
  result.isSignedIn = coreUserPrms.isLoggedIn ? coreUserPrms.isLoggedIn : false
  result.amountUntilApollo = coreUserPrms.amountUntilApollo || 0
  result.firstName = coreUserPrms.firstName || ''
  result.lastName = coreUserPrms.lastName || ''
  result.isImpersonating = coreUserPrms.isImpersonating || false
  result.canImpersonate = coreUserPrms.canImpersonate || false
  result.isPreferredCustomer = coreUserPrms.isPreferredCustomer || false
  result.userId = coreUserPrms.userId || 0
  result.cashPaymentEnabled = !!coreUserPrms?.cashPaymentEnabled
  result.googleReCaptchaKeyId = coreUserPrms.googleReCaptchaKeyId || '',
  result.userStage = coreUserPrms.userStage || '',
  result.needToCompleteInvestorProfile = !!coreUserPrms?.needToCompleteInvestorProfile
  return result
}

export default parsingCoreUser
