import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { formatDecimal, MOBILE_WIDTH_SIZE, Mixpanel, Fullstory } from 'utils'
import { Button } from 'components'
import { searchCompaniesbyValue } from 'slices/investSlice'
import { images } from 'assets'
import _ from 'lodash'
import useWindowSize from 'hooks/useWindowSize'

const SearchInput = ({ searchCompanies, searchValue, clearSearch, setSearchValue}) => (
  <div className='relative flex items-center mb-8 md:ml-auto md:mb-0'>  
    <img src={images['search']} alt='Search Companies' className='absolute left-3 max-w-5 max-h-5'/>
    <label htmlFor='search-companies' className='sr-only'>Search Companies</label>
    <input
      id='search-companies'
      placeholder='Search Companies'
      onChange={(e) => {
        setSearchValue(e.target.value)
        searchCompanies(e.target.value)}
      }
      data-testid='search-companies-input'
      autoComplete='off'
      className='rounded-[100px] outline-none border border-stroke-on-white-dark body_1 h-10 md:h-8 pl-10 min-w-full placeholder-text-default md:min-w-60 lg:min-w-64'
      value={searchValue}
    />
    {searchValue !=='' && <Button className='absolute right-3' onClick={clearSearch} ariaLabel='Clear Search'><img src={images['close-search']} alt='Clear Search'/></Button>}
  </div>
)

const SearchItem = ({ item, onItemClick, index }) => (
  <Button data-testid={`search-item-${index}`} className='relative flex items-center w-full p-4 pb-2' onClick = {() => onItemClick(item)} ariaLabel={`${item?.name || item?.title}, price: ${formatDecimal(item?.sharePrice) || formatDecimal(item?.price)}`}> 
    <img src={item?.iconUrl} alt={`${item?.name} logo`} className='w-[32px] h-[32px] rounded-[50%] mr-2' />
    <div className='flex flex-col items-start'>
      <div className='body_2'>{item?.name}</div>
      <div className='small_1'>{item?.vertical}</div>
    </div>
    <div className='ml-auto body_2'>{formatDecimal(item?.sharePrice)}</div>
  </Button>
)

const SearchResults = ({ items, onItemClick, isMobile }) => (
  <div className={`absolute min-h-[68px] min-w-full md:min-w-60 lg:min-w-64 bg-background-default z-50 right-0 ${isMobile ? 'top-24' : 'top-10' } shadow-ambient-shadow rounded-2xl items-center flex flex-col max-h-[275px] overflow-y-auto pb-2`}>  
    {items?.length > 0 && items.map((item, i) =>
      <SearchItem key={`list_${i}`} item={item} onItemClick={() => onItemClick(item)} index={i}/> 
    )}
    {items?.length === 0 &&  
      <div className='flex items-start w-full pt-[22px] pl-4 medium_2 text-text-secondary'>  
        No Results Found
      </div>}
  </div>
)

const SearchCompaniesInput = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const { width } = useWindowSize()

  const { searchedCompanies } = useSelector(state => state.investSlice)

  const [searchValue, setSearchValue] = useState('')
  const [showSearchResult, setShowSearchResult] = useState(false)

  const isMobile = width <= MOBILE_WIDTH_SIZE
  
  useEffect(() => {
    if (searchValue === '') {
      setShowSearchResult(false)
    }
  }, [searchValue])
  
  const clearSearch = () => {
    setSearchValue('')  
    setShowSearchResult(false)
  }

  const searchCompanies = (query) => {
    if(query !== '') {
      setShowSearchResult(true)
      dispatch(searchCompaniesbyValue({ query }))
    } else {
      setShowSearchResult(false)
    }
  }

  const debounceFun = _.debounce(function (val) {
    searchCompanies(val)
  }, 250, {})
  
  const showProductDetails = (item) => {   
    Mixpanel.track('Click Company on Product List Page', { 'Company Name': item?.companyName, 'Share Price': item?.sharePrice })
    Fullstory.track('Card Click', { name: 'Company', company: item?.companyName, share_price: item?.sharePrice })
    history.push(`/products/${item?.urlName}`)
  }

  return (
    <>
      <SearchInput searchCompanies={debounceFun} searchValue={searchValue} clearSearch={clearSearch} setSearchValue={setSearchValue}/>
      {showSearchResult && <SearchResults items={searchedCompanies} onItemClick={showProductDetails} isMobile={isMobile} /> }
    </>
  )
}

export default SearchCompaniesInput

