import { useSelector } from 'react-redux'
import moment from 'moment'
import { formatDecimal } from 'utils'

const PurchaseCreditsCurrent = () => {
  let { currentCreditEntries } = useSelector((state) => state.purchaseCredits)
  currentCreditEntries = currentCreditEntries.slice().sort((a, b) => b.createdAt - a.createdAt)

  return (
    <>
      <div className='border-t grid-old border-t-stroke-on-white-dark md:border-t-0 transactions-table desktop-content'>
        <div className='border-b row border-b-stroke-on-white-dark transactions-table-header'>
          <div className='column five medium_1 text-text-default'>ISSUED</div>
          <div className='column six medium_1 text-text-default'>EXPIRING</div>
          <div className='column five medium_1 text-text-default right'>ISSUED AMT.</div>
          <div className='column five medium_1 text-text-default right'>REMAINING</div>
        </div>
        {currentCreditEntries?.map((f, i) =>
          <div key={`currentPurchaseCredits_${i}`} className='border-b row border-b-stroke-on-white-light transactions-table-body'>
            <div className='column five medium_1 text-text-default'>{moment(f?.createdAt).format('MM/DD/YYYY')}</div>
            <div className='column six medium_2 text-text-default'>{moment(f?.expiringAt).format('MM/DD/YYYY')}</div>
            <div className='column five medium_1 text-text-default right'>{formatDecimal(f?.amount)}</div>
            <div className='column five medium_2 text-text-default right'>{formatDecimal(f?.balance)}</div>
          </div>
        )}
      </div>
      <div className='border-t grid-old transactions-table border-t-stroke-on-white-dark md:border-t-0 mobile-content'>
        {currentCreditEntries && currentCreditEntries.map((f, i) =>
          <div key={`currentPurchaseCredits_${i}`} className='border-b row border-b-stroke-on-white-light transactions-table-body'>
            <div className='column eight-mobile medium_1 text-text-default'>ISSUED {moment(f.createdAt).format('MM/DD/YYYY')}<br/>EXPIRING <span className='medium_2'>{moment(f.expiringAt).format('MM/DD/YYYY')}</span></div>
            <div className='column eight-mobile medium_1 text-text-default right'>ISSUED AMT. {formatDecimal(f.amount)}<br/>REMAINING <span className='medium_2'>{formatDecimal(f?.balance)}</span></div>
          </div>
        )}
      </div>
    </>
  )
}

export default PurchaseCreditsCurrent
