import React from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from '../../../Global/Inputs/ErrorMessage'

const DocumentTypeDropdown = ({ items, name, label, ariaLabel, disabled, onChange = () => {}, className }) => {
  const { register, formState: { errors } } = useFormContext()

  const options = items.map(({ label, value }, i) => (
    <option value={value} key={`${value} + ${i}`} data-testid='select-option'>{label}</option>
  ))

  return (
    <div className={`input-group  ${errors?.name ? 'error' : ''} ${className}`}>
      <label className='text-text-default'>{label}</label>
      <select {...register(name, { onChange })} disabled={disabled} aria-label={ariaLabel} className={`border bg-background-default border-stroke-on-white-dark text-text-default hover:border-stroke-cobalt-blue hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue ${errors[name] ? 'border border-stroke-alert-red hover:border-stroke-alert-red focus:border-stroke-alert-red' : ''}`}>
        <option value=''>Document Type</option>
        {options}
      </select>
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default DocumentTypeDropdown
