import { useEffect } from 'react'
import { seoTitleTemplate, Mixpanel } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import {
  getLinqtoWallet
} from 'slices/walletSlice'
import { useDispatch, useSelector } from 'react-redux'
import { PageLoading } from 'components'
import LinqtoWalletTotalFunds from './partials/LinqtoWalletTotalFunds'
import AccountItem from './partials/AccountItem'
import EntityItem from './partials/EntityItem'
import MessagingCarousel from 'components/Global/MessagingCarousel'
import { images } from 'assets'
import { cashSecuredCopy } from 'components/data'

const LinqtoWallet = () => {
  const dispatch = useDispatch()
  const { walletContentCards, walletSubContentCards } = useSelector(state => state.commonSlice)
  const { accounts, linqtoBucks, purchaseCredits, pageLoading } = useSelector(
    (state) => state.walletSlice
  )
  const hasEntities = accounts?.length > 1
  const { featureFlags } = useSelector(state => state.userSlice)
  const { PurchaseCreditEnabled } = featureFlags || {}

  const ReferFriendTag = () => {
    return (
      <span className='bg-background-light-gray current-investment-item-tag'>
        <span className='body_2'><img src={images['wallet-refer-friend']} alt='Refer a Friend' className='current-investment-item-tag-img'/> Refer a Friend</span>
        <span className='body_1'>Get $1,000 towards your next investment</span>
      </span>
    )
  }

  useEffect(() => {
    dispatch(getLinqtoWallet()).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Wallet Page')
      }
    })
  }, [])

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Linqto Wallet')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Linqto Wallet')} />
      <div className='page-container wallet-container'>
        <div className='inner-container'>
          {walletContentCards?.length ? (
            <div className='messaging-carousel-container'>
              <MessagingCarousel data={walletContentCards} page='Wallet' />
            </div>) : null}
          <h1>Linqto Wallet</h1>
          <div className='flex-col'>
            {!hasEntities && <LinqtoWalletTotalFunds />}
            <div className='investments-container'>
              <div className='current-investment-item-container'>
                {hasEntities ? (<>
                  <AccountItem
                    icon='wallet-linqtobuck-account'
                    accountName='Linqto Bucks'
                    balance={linqtoBucks}
                    goTo='/rewards'
                    tag={<ReferFriendTag />}
                  />
                  {PurchaseCreditEnabled && <AccountItem
                    icon='wallet-purchase-credit-account'
                    accountName='Purchase Credits'
                    balance={purchaseCredits}
                    goTo='/purchase-credits'
                  />}
                </>
                ) : (<div className='medium_1'>Click below to view linked accounts and manage funds.</div>)
                }
                {walletSubContentCards?.length ? <div className='my-10 messaging-carousel-container'><MessagingCarousel data={walletSubContentCards} page='Wallet' /></div> : null}
                {accounts?.map((account, i) => {
                  let goToUrl = '/cash-account'
                  if (account.entityId) { goToUrl += '?entityId=' + account.entityId }
                  return (
                    <div key={i}>
                      {hasEntities &&
                        <>
                          <EntityItem name={account.accountName} />
                          <div className='medium_1'>Click below to view linked accounts and manage funds.</div>
                        </>}
                      <AccountItem
                        icon='wallet-cash-account'
                        accountName='Cash Balance'
                        balance={account.amountAvailable}
                        goTo={goToUrl}
                      />
                      {!hasEntities && (
                        <>
                          <AccountItem
                            icon='wallet-linqtobuck-account'
                            accountName='Linqto Bucks'
                            balance={linqtoBucks}
                            goTo='/rewards'
                            tag={<ReferFriendTag />}
                          />
                          {PurchaseCreditEnabled && <AccountItem
                            icon='wallet-purchase-credit-account'
                            accountName='Purchase Credits'
                            balance={purchaseCredits}
                            goTo='/purchase-credits'
                          />}
                        </>
                      )}
                    </div>
                  )
                })}
              </div>
            </div>
          </div>
          <div className='mt-20 text-center md:px-8 small_1 text-text-secondary'>{cashSecuredCopy}</div>
        </div>
      </div>
    </>
  )
}
export default LinqtoWallet
