import Tooltip from 'components/Global/Tooltip'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import { formatDate, formatDecimal, formatShares, toTitleCase } from 'utils'
import { transactionFeeCopy } from 'components/data'
const style = {
  cancelled: 'text-text-error bg-fill-light-red',
  canceled: 'text-text-error bg-fill-light-red',
  closed: 'bg-background-dark-gray text-text-default',
  completed: 'bg-logo-mint text-text-default',
  active: 'bg-logo-turq text-text-default'
}
const SellOrderSummaryContainer = () => {
  const [showHover, setShowHover] = useState(false)
  const [showFlatFeeHover, setFlatFeeShowHover] = useState(false)
  const { sellOrder } = useSelector(state => state.sellOrder)

  let { sharePrice, amount, linqtoFee, totalProceeds, status, doneAt, transactionFeeRate, shares, linqtoFlatFee, sellOrderId } = sellOrder || {}

  const linqtoFeeValue = linqtoFee
  const linqtoFlatFeeValue = linqtoFlatFee
  sharePrice = formatDecimal(sharePrice)
  shares = formatShares(shares)
  amount = formatDecimal(amount)
  linqtoFee = formatDecimal(linqtoFee)
  totalProceeds = formatDecimal(totalProceeds)
  linqtoFlatFee = formatDecimal(linqtoFlatFee)
  doneAt = formatDate(doneAt)

  return (
    <div className='border border-stroke-on-white-light sell-order-summary-container bg-background-default'>
      {status && <div className={`text-text-default sell-order-summary-container__badge ${status?.toLowerCase()} ${style[status?.toLowerCase()]}`}>
        <span>{toTitleCase(status)}</span>
      </div>}
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>Sell Order ID</span>
        <span className='sell-order-summary-container__row__copy'>{sellOrderId}</span>
      </div>
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>Date of Sale</span>
        <span className='sell-order-summary-container__row__copy'>{doneAt}</span>
      </div>
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>Price per unit (USD)</span>
        <span className='sell-order-summary-container__row__copy'>{sharePrice}</span>
      </div>
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>Units Sold</span>
        <span className='sell-order-summary-container__row__copy'>{shares}</span>
      </div>
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>Subtotal</span>
        <span className='sell-order-summary-container__row__copy'>{amount}</span>
      </div>
      {linqtoFeeValue > 0 && <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>
          Transaction Fees {transactionFeeRate}%
          <Tooltip content={transactionFeeCopy(transactionFeeRate)} showHover={showHover} setShowHover={setShowHover} />
        </span>
        <span className='sell-order-summary-container__row__copy'>-{linqtoFee}</span>
      </div>
      }
      {linqtoFlatFeeValue > 0 &&
      <div className='sell-order-summary-container__row'>
        <span className='sell-order-summary-container__row__copy'>
        Regulatory Filing Fee
          <Tooltip content='This flat fee helps cover local and federal regulatory filing expenses required for sales of private equity. If your offer sells across multiple orders, this fee is applied prior to distributing proceeds.' showHover={showFlatFeeHover} setShowHover={setFlatFeeShowHover} />
        </span>
        <span className='sell-order-summary-container__row__copy'>-{linqtoFlatFee}</span>
      </div>
      }
      <div className='pt-2 mt-2 border-t sell-order-summary-container__row border-t-stroke-on-white-dark'>
        <div>
          <p className='m-0 text-text-default'>Total Proceeds</p>
          <p className='m-0 text-text-secondary'>Deposited to your cash account</p>
        </div>
        <span className='m-0 text-text-default'>{totalProceeds}</span>
      </div>
    </div>
  )
}

export default SellOrderSummaryContainer
