import { useLDClient } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { getUserCountry } from 'utils';

const useLaunchDarkly = (userId, userStage) => {
  const ldClient = useLDClient()

  useEffect(() => {
    if (ldClient && userId) {
      ldClient.identify({
        kind: 'user',
        key: userId,
        hasReferrer: window.location.search.includes('?r=') || window.location.search.includes('?ria='),
        country: getUserCountry(),
        screenSize: window.innerWidth,
        userStage
      });
    }
  }, [ldClient, userId, userStage]);

};

export default useLaunchDarkly;
