import React from 'react'
import { useParams } from 'react-router-dom'
import { seoTitleTemplate } from 'utils'
import FAQBox from './FAQBox'
import Breadcrumb from 'components/Global/Breadcrumb'
import SeoMeta from 'components/Global/SeoMeta'
import { PageLoading } from 'components'
import useHelpCenter from '../useHelpCenter'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const FAQList = () => {
  const history = useHistory()
  const { id } = useParams()
  const { currentFaq, isLoading } = useHelpCenter(id)
  const { isSignedIn, isUserReturned } = useSelector(state => state.userSlice)

  useEffect(() => {
    if (isUserReturned && !isSignedIn) {
      history.push('/signin', { from: `/help-center/announcements/${id}` })
    }
  }, [isUserReturned, isSignedIn])

  if (isLoading || !currentFaq || !isSignedIn) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate(currentFaq?.fields?.label || 'Announcements')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate(currentFaq.fields.label || 'Announcements')} />
      <div className='page-container'>
        <div className='inner-container'>
          {!window.ReactNativeWebView && (
            <Breadcrumb
              items={[
                { name: 'Help Center', route: '/help-center' },
                { name: currentFaq.fields.label, route: `/help-center/announcements/${id}` }
              ]}
            />
          )}
          <h1 className='mt-4 mb-5'>{currentFaq.fields.label}</h1>
          {currentFaq.fields.faqItems.map((faq, index) => (
            <FAQBox key={index} faq={faq} />
          ))}
        </div>
      </div>
    </>
  )
}

export default FAQList
