export const CONFIG_CONTENTFUL = 'configContentful'

/**
 *
 * @param {Config} config
 * @returns {ContentfulConfig}
 * @constructor
 */
function ContentfulConfig(config) {
  Object.assign(this, config.get(CONFIG_CONTENTFUL))
  return this
}

export default ContentfulConfig

ContentfulConfig.prototype.getSpace = function() {
  return this.space
}

ContentfulConfig.prototype.getAccessToken = function() {
  return this.accessToken
}

ContentfulConfig.prototype.getEnvironment = function() {
  return this.environment
}

/**
 * The production variation won't have a host and relies on the default in the client creation in the contentful sdk.
 * @returns {*|undefined}
 */
ContentfulConfig.prototype.getHost = function() {
  return this.host || undefined
}

/**
 *  We only require the other fields, host is not required for the prod variant.
 * @returns {*}
 */
ContentfulConfig.prototype.isValid = function() {
  return this.getSpace() && this.getAccessToken() && this.getEnvironment()
}