import { formatDecimal, MOBILE_WIDTH_SIZE, useLDFlags } from 'utils'
import { useSelector } from 'react-redux'
import { Row } from 'components'
import { images } from 'assets'
import useWindowSize from 'hooks/useWindowSize'

const PlaceOrderHeader = ({ sharePrice, handleFavorite, copy }) => {
  const { width } = useWindowSize()
  const { details, linqtoImpliedValuation } = useSelector(state => state.investSlice)
  const { isSoldOut, promoFlag, isInterested } = details
  const { inputPurchaseAmount, showWatchList } = useLDFlags(['inputPurchaseAmount','showWatchList'])

  const showBlur =
  promoFlag === 'FULLY_SUBSCRIBED' ||
  (promoFlag === 'COMING_SOON' && (linqtoImpliedValuation === 'TBD' || !sharePrice))

  return (
    <>
      {width > MOBILE_WIDTH_SIZE && showWatchList && <div className={`border shadow-none box border-stroke-on-white-light flex items-center justify-between p-5 cursor-pointer box invest-detail-box hover:bg-gray-100 !mb-4 ${inputPurchaseAmount && showWatchList && 'w-[344px]'}`} onClick={handleFavorite}>
        <span className='body_1'>{copy}</span>
        <img aria-label='notifyBtn' src={images[isInterested ? 'watchlist-save-full' : 'watchlist-save-empty']} alt={`${isInterested ? 'watchlist-save-full' : 'watchlist-save-empty'}`} />
      </div>}
      {!inputPurchaseAmount && <div className='border shadow-none box border-stroke-on-white-light invest-order-box invest-order-header'>
        {isSoldOut &&
        <Row className='invest-order-row amounts'>
          <span className='medium_1'>Status</span>
          <span className='medium_1'>
            {promoFlag === 'COMING_SOON' ? 'Coming Soon' : 'Unavailable'}
          </span>
        </Row>
        }
        <Row className='invest-order-row amounts'>
          <span className='medium_1 sub-header'>Unit Price</span>
          <span className={`medium_2 ${showBlur && 'blured'}`}>{sharePrice ? formatDecimal(sharePrice) : '$0.00'}</span>
        </Row>
      </div>}
    </>
  )
}

export default PlaceOrderHeader
