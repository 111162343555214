import { isReactLink } from 'utils/linkHelper'
import { NavLink } from 'react-router-dom'
import { useLocation } from 'react-router-dom'

const NavigationLink = ({ item, onClick, onLogout, className }) => {
  const { pathname } = useLocation()
  const { appLink, href } = isReactLink(item?.fields?.linkUrl)
  const isExternal = item?.fields?.isExternal || false
  const id = item?.sys?.id
  const title = item?.fields?.title
  const isSignOut = title === 'Sign Out'
  const isActive = (href === pathname  && (item?.fields?.parent === 'None' || item?.fields?.parentMenuItem === 'None')) || false // make sure style is applied to top level nav items
  const isDropdownItemActive = (href === pathname  && item?.fields?.parentMenuItem === 'Account') || false // apply active style to dropdown items
  const isSecondayItem = item?.fields?.secondaryItem || false

  const itemStyle = item?.fields?.style || ''
  if (isSignOut) {
    return (
      <div
        key={`nav-${id}`}
        className={`${className} dropdown text-text-hyperlink`}
        onClick={onLogout}
        role='button'
        tabIndex={0}
        data-testid='logout-button'
        aria-label={`Navigate to ${title}`}
      >
        {title}
      </div>
    )
  }
  
  if (isExternal || !appLink) {
    return (
      <a
        key={`nav-${id}`}
        href={href}
        onClick={onClick}
        target={isExternal ? '_blank' : undefined}
        rel={isExternal ? 'noreferrer noopener' : undefined}
        className={`${className} ${itemStyle === 'Button' ? 'text-text-default lg:text-text-on-dark-bg bg-stroke-cobalt-blue nav-button' : ''} ${isActive ? 'text-text-hyperlink active border-b-[3px] border-stroke-cobalt-blue' : 'border-none'}`}
      >
        {title}
      </a>
    )
  } else if (appLink) {
    return (
      <NavLink
        key={`nav-${id}`}
        to={href}
        onClick={onClick}
        className={`${className} ${itemStyle === 'Button' ? 'text-text-default lg:text-text-on-dark-bg lg:bg-logo-cobalt nav-button hover:text-text-on-dark-bg cursor-pointer' : ''} ${isActive ? 'text-text-hyperlink active border-b-[3px] border-stroke-cobalt-blue hover:text-text-hyperlink' : 'border-none'} ${isDropdownItemActive ? 'text-text-hyperlink hover:text-text-hyperlink' : ''}`}
      >
        {title}
        {isSecondayItem && <div className='text-text-default nav-secondary-item bg-legacy-citron before:border-r before:border-r-legacy-citron'>{item?.fields?.secondaryItem}</div>}
      </NavLink>
    )
  }
}

export default NavigationLink