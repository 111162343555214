import { Button } from 'components'
import { useHistory } from 'react-router-dom'
import { useLDFlags } from 'utils/LaunchDarkly'

const EditProfileDetailsButton = () => {
  const history = useHistory()
  const { restrictedAccreditationOptions } = useLDFlags(['accreditationIncomeFlow'])

  const handleProfileDetailsClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToProfile')
    } else {
      history.push('/profile?redirect=/investor-status')
    }
  }

  if (window.ReactNativeWebView) {
    return null
  }

  return restrictedAccreditationOptions ? (
    <Button id='edit-profile-button' className='cursor-pointer medium_2 text-text-hyperlink' onClick={handleProfileDetailsClick}>Edit Profile Details</Button>
  ) : (
    <Button id='edit-profile-button' mode='tertiary' onClick={handleProfileDetailsClick}>Back</Button>
  )
}

export default EditProfileDetailsButton
