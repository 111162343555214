import { useState } from 'react'
import { Button, Tooltip, Keypad, PurchaseMessagingBanner } from 'components'
import { images } from 'assets'
import InvestDetailOrderPill from './InvestDetailOrderPill'
import { formatDecimal, formatWholeNumber } from 'utils'
import useCompanyShares from './useCompanyShares'

const InputPurchaseAmountKeypad = ({ setShowInputKeypad, placeOrder }) => {
  const [showHover, setShowHover] = useState(false)
  const [showEstimatedSharesHover, setShowEstimatedSharesHover] = useState(false)
  const [showBuyHover, setShowBuyHover] = useState(false)
  const [value, setValue] = useState('')
  const [cents, setCents] = useState('')
  const { 
    name, iconUrl, companyShareData, sliderMinimum, companySharePrice, 
    showMinimumBanner, showMaxBanner, showDiscountBanner,
    maxDigits, companyErrorMessage, disableOrderButton  
  } = useCompanyShares(value)
  const { shares, cost, pricePerShare } = companyShareData || {}
  const initialQuickBuyAmounts = [sliderMinimum, 10000, 25000]

  return (
    <>
      <div
        className='fixed inset-0 z-[1000] overflow-scroll bg-white'
        role='dialog'
        aria-modal='true'
        tabIndex={-1}
        aria-label='Purchase amount keypad'
        onKeyDown={e => e.key === 'Escape' && setShowInputKeypad(false)}
      >
        <div className='overflow-auto p-4 h-full'>
          <button
            onClick={() => setShowInputKeypad(false)}
            aria-label='Back to invest details'
            type='button'
          >
            <img src={images['back-circle']} alt='back to invest details' />
          </button>
          <div className='flex flex-col items-center'>
            <img
              src={iconUrl}
              alt={name}
              className='w-[60px] h-[60px] rounded-full mb-2'
            />
            <p className='mb-8 text-center large_1'>
              Buy {name}{' '}
              <Tooltip
              top='0'
                header='Buy Units'
                content='You are buying units of a series of Linqto Liquidshares, LLC, a private unregistered fund, that invests directly or indirectly into this company.'
                showHover={showBuyHover}
                setShowHover={setShowBuyHover}
              />
            </p>
            <p className='flex items-start large_number' aria-label='purchaseAmount'>
              <span className='large_1'>$</span>
              {value ? formatDecimal(+value, false, 0) : '0'}
              <span className='large_1'>{cents ? '.' + cents : '.00'}</span>
            </p>
            <PurchaseMessagingBanner
              color='green'
              isVisible={showDiscountBanner && !showMaxBanner && !showMinimumBanner}
            >
              <div className='flex items-center'>
                <img src={images.tag} alt='tag' className='mr-2' />
                <p className='body_1'>
                  Spend <span className='body_2'>$10k+</span> for lowest pricing.
                </p>
              </div>
              <Tooltip
                top='0'
                header='Preferred Pricing Discount'
                content={
                  <p className='body_1'>
                    Unlock bigger savings when you buy more. Our tiered pricing rewards you with
                    lower costs per unit as you invest more.
                    <br />
                    <br />- Spend <span className='body_2'>$10k+</span> to reach your first
                    preferred pricing discount <br />- Spend <span className='body_2'>$100k+</span>{' '}
                    to reach our top tier preferred pricing discount
                  </p>
                }
                showHover={showHover}
                setShowHover={setShowHover}
              />
            </PurchaseMessagingBanner>
            <PurchaseMessagingBanner color='red' isVisible={showMinimumBanner}>
              <div className='flex items-center'>
                <img src={images.exclaim} alt='exclaim' className='mr-2' />
                <p className='body_1'>
                  Minimum order is <span className='body_2'>{formatDecimal(sliderMinimum)}</span>
                </p>
              </div>
            </PurchaseMessagingBanner>
            <PurchaseMessagingBanner color='red' isVisible={showMaxBanner}>
              <div className='flex'>
                <img src={images.exclaim} alt='exclaim' className='mr-1' />
                <p className='body_1'>Max. Order is {formatDecimal(cost)}</p>
              </div>
              <Button 
                className='body_2' 
                onClick={() => setValue(cost)}
              >
                Enter Max.
              </Button>
            </PurchaseMessagingBanner>
            <PurchaseMessagingBanner color='red' isVisible={!!companyErrorMessage}>
              <div className='flex'>
                <img src={images.exclaim} alt='exclaim' className='mr-2' />
                <p className='body_1'>{companyErrorMessage}</p>
              </div>
            </PurchaseMessagingBanner>
            <div className='flex justify-between items-end mt-6 w-full'>
              <p className='self-start body_1'>Price Per Unit</p>
              {/* Compare local share price to BE share price to show discount share pricing*/}
              {(formatDecimal(pricePerShare) < formatDecimal(companySharePrice) && (pricePerShare !== undefined)) ? (
                <div className='flex'>
                  <div className='line-through body_1 mr-1.5 gray3'>
                    {formatDecimal(companySharePrice)}
                  </div>
                  <div className='body_2'>{`${formatDecimal(pricePerShare)}`}</div>
                </div>
              ) : (
                <div className='body_2'>{`${formatDecimal(companySharePrice)}`}</div>
              )}
            </div>
            <div className='flex justify-between items-center mt-4 mb-7 w-full'>
              <p className='self-start body_1'>
                Estimated Units
                <Tooltip
                  header='Estimated Units'
                  content='Estimated units adjusted to reflect whole units and applied discounts.'
                  showHover={showEstimatedSharesHover}
                  setShowHover={setShowEstimatedSharesHover}
                />
              </p>
              <p className='body_2'>{shares && value !== '' ? formatWholeNumber(shares) : '0'}</p>
            </div>
            <div className='flex justify-between w-full mb-[19px]'>
              <InvestDetailOrderPill
                onClick={() => setValue(initialQuickBuyAmounts[0])}
                active={value === initialQuickBuyAmounts[0]}
                amount={`$${sliderMinimum / 1000 + 'K'}`}
              />
              <InvestDetailOrderPill
                onClick={() => setValue(initialQuickBuyAmounts[1])}
                active={value === initialQuickBuyAmounts[1]}
                amount='$10K'
              />
              <InvestDetailOrderPill
                onClick={() => setValue(initialQuickBuyAmounts[2])}
                active={value === initialQuickBuyAmounts[2]}
                amount='$25K'
              />
            </div>
            <Keypad
              value={value}
              setValue={setValue}
              cents={cents}
              setCents={setCents}
              maxDigits={maxDigits}
            />
            <Button
              onClick={() => placeOrder({ cost, shares })}
              disabled={disableOrderButton}
            >
              Review Order
            </Button>
          </div>
        </div>
      </div>
    </>
  )
}

export default InputPurchaseAmountKeypad
