import { useState, useEffect } from 'react'
import { getClient, processRichText } from 'utils/Contentful'
import contentfulFallBackData from '../initalStates/contentfulFallBackData.json'
import useConfig from '../config/useConfig'

/**
 * Hook to fetch and manage Contentful entries
 * @param {string} entryId - The ID of the Contentful entry to fetch
 * @param {Object} options - Additional options for processing the content
 * @param visibility
 * @param {Object} [options.placeholders] - Placeholders for rich text processing
 * @param {boolean} [options.removeHtml] - Whether to remove HTML from rich text
 * @returns {Object} The fetched and processed content state
 */
const useContentful = (entryId, options = {}, visibility = {}) => {
  const [content, setContent] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [error, setError] = useState(null)
  const { configuration } = useConfig()
  const { isPrivate = false, isSignedIn = false } = visibility

  useEffect(() => {
    const fetchContent = async () => {
      if (!entryId || (isPrivate && !isSignedIn)) {
        setIsLoading(false)
        return
      }

      try {
        setIsLoading(true)
        
        if (!configuration) {
          throw new Error('Missing configuration')
        }

        const client = getClient(configuration)
        const { ...restOptions } = options

        const response = await client.getEntry(entryId, {
          include: 2,
          ...restOptions
        })
        
        if (!response) {
          throw new Error('No response from Contentful')
        }

        const processedEntry = {
          ...response,
          fields: Object.entries(response.fields).reduce((acc, [key, value]) => {
            if (value?.nodeType && value?.content) {
              acc[key] = processRichText(
                value,
                options.placeholders,
                options.removeHtml
              )
            } else {
              acc[key] = value
            }
            return acc
          }, {})
        }

        setContent(processedEntry)
        setError(null)
      } catch (err) {
        const fallbackEntry = contentfulFallBackData.items.find(
          item => item.sys.id === entryId
        )
        
        if (fallbackEntry) {
          const processedFallbackEntry = {
            ...fallbackEntry,
            fields: Object.entries(fallbackEntry.fields).reduce((acc, [key, value]) => {
              if (value?.nodeType && value?.content) {
                acc[key] = processRichText(
                  value,
                  options.placeholders,
                  options.removeHtml
                )
              } else {
                acc[key] = value
              }
              return acc
            }, {})
          }
          setContent(processedFallbackEntry)
          setError({ message: 'Using fallback data', originalError: err })
        } else {
          setError(err)
          setContent(null)
        }
      } finally {
        setIsLoading(false)
      }
    }

    fetchContent()
  }, [entryId, options.placeholders, options.removeHtml, configuration, isPrivate, isSignedIn])

  return {
    content,
    isLoading,
    error,
    isFallback: error?.message === 'Using fallback data'
  }
}

export default useContentful