import { getDomain } from 'utils'

const NavHeader = ({ setToggleStatus, toggleStatus, headerEntry }) => {
  const headerLogo = headerEntry?.logo?.fields?.file?.url 
  
  return (
    <div className='mobile-nav-container'>
      {headerLogo && <a
        href={getDomain('/', true)}
        className='logo home'
        onClick={() => setToggleStatus(false)}
        role='link'
        aia-label='Home'
        data-testid='home-link'
      >
        {!toggleStatus && <img data-testid='header-logo' alt='Linqto - Private Investing' src={headerLogo} />}
      </a>}
      <div
        id='nav-icon'
        data-testid='nav-icon'
        className={`toggle-icon ${toggleStatus ? 'open' : ''}`}
        onClick={() => setToggleStatus(!toggleStatus)}
        role='button'
        aria-label='Toggle Navigation'
      >
        <span></span>
        <span></span>
      </div>
    </div>
  )
}

export default NavHeader