import { useEffect, useState } from 'react'
import 'rc-slider/assets/index.css'
import { ConditionalRender, Row, Tooltip } from 'components'
import { images } from 'assets'
import { formatDecimal, formatWholeNumber, getSliderValue, Mixpanel, Fullstory, MOBILE_WIDTH_SIZE } from 'utils'
import LinqtoSlider from 'components/Global/LinqtoSlider'
import { useSelector } from 'react-redux'
import { useLDFlags } from 'utils/LaunchDarkly'
import useWindowSize from 'hooks/useWindowSize'
import InputPurchaseDesktop from '../InputPurchaseAmount/InputPurchaseDesktop'

const OrderDetails = ({ setAmount, setShares, shares, setSharePrice, totalSavings, setTotalSavings, setTotalTillNextTear, isPreferred, setIsPreferred, setTiers, name, discountPercent, setDiscountPercent, discountedAmount, setDiscountedAmount, handleButtonClick, setShowingErrorBanner }) => {
  const { details } = useSelector(state => state.investSlice)
  const { discountTiers, sliderIncrement, sliderMinimum, sliderMaxIndex, offers } = details || {}
  const [sliderIndex, setSliderIndex] = useState(0)
  const [showHover, setShowHover] = useState(false)
  const [amountUsd, setAmountUsd] = useState(sliderMinimum)
  const { inputPurchaseAmount } = useLDFlags(['inputPurchaseAmount'])
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE

  // Note - Initialize slider values on first render
  useEffect(() => {
    if (sliderIndex === 0) {
      updateSliderIndex(0)
    }
  }, [])


  // This is called when someone drags the slider AND when someone clicks the Plus/Minus buttons
  const updateSliderIndex = (index, type = '') => {
    // If it is out of bounds, don't allow it
    if (index < 0 || index > sliderMaxIndex) {
      return
    }

    const sliderVal = getSliderValue({ offers, sliderMinimum, sliderIncrement }, index)
    if (type !== '') {
      Mixpanel.track('Click Slider', { Type: type, 'Company Name': name })
      Fullstory.track('Slider', { name: 'Purchase Order', company_name: name, type })
    }

    // Set the values
    setAmountUsd(sliderVal?.amount)
    setAmount(sliderVal?.amount)
    setSliderIndex(index)
    setShares(sliderVal?.shares)
    setSharePrice(sliderVal?.sharePrice)
    setTotalTillNextTear(sliderVal?.totalTillNextTier)
    setTotalSavings(sliderVal?.totalSavings)
    setIsPreferred(sliderVal?.isPreferred)
    setTiers(sliderVal?.tiers)

    const discount = discountPercent > 0 ? Math.floor((sliderVal?.amount * discountPercent) * 100) / 100 : 0
    setDiscountedAmount(discount)
    handleDiscountConversion(sliderVal)
  }

  const handleDiscountConversion = sliderVal => {
    const findCurrentDiscountTier = discountTiers?.filter((tier) => tier?.minimumPurchase < sliderVal?.amount)
    if (findCurrentDiscountTier && findCurrentDiscountTier.length === 1) {
      // If discount percent exists multiple by sliderAmount
      // otherwise set as discountAmount
      if (findCurrentDiscountTier?.[0]?.percent) {
        setDiscountedAmount(Math.floor((sliderVal?.amount * findCurrentDiscountTier?.[0]?.percent) * 100) / 100)
        setDiscountPercent(findCurrentDiscountTier?.[0]?.percent)
      } else if (findCurrentDiscountTier?.[0]?.amount) {
        setDiscountedAmount(Math.floor(findCurrentDiscountTier[0]?.amount * 100) / 100)
        setDiscountPercent(0)
      } else {
        setDiscountedAmount(0)
        setDiscountPercent(0)
      }
    } else if (findCurrentDiscountTier && findCurrentDiscountTier.length > 1) {
      const newDiscountTier = findCurrentDiscountTier[findCurrentDiscountTier.length - 1]
      // If discount percent exists multiple by sliderAmount
      // otherwise set as discountAmount
      if (newDiscountTier?.percent) {
        setDiscountedAmount(Math.floor((sliderVal?.amount * newDiscountTier?.percent) * 100) / 100)
        setDiscountPercent(newDiscountTier?.percent)
      } else if (newDiscountTier?.amount) {
        setDiscountedAmount(Math.floor(newDiscountTier?.amount * 100) / 100)
        setDiscountPercent(0)
      } else {
        setDiscountedAmount(0)
        setDiscountPercent(0)
      }
    } else {
      setDiscountedAmount(0)
      setDiscountPercent(0)
    }
  }

  let savingsCopy = ''

  if (isPreferred) {
    savingsCopy = <div className='invest-order-row__tier-price__copy-container'>
      <img src={images['team-apollo-black']} height={16} width={16} alt='Team Apollo Savings' />
      <span>Team Apollo Savings</span>
    </div>
  } else {
    savingsCopy = <span>Tiered Pricing Discount</span>
  }

  const plusBtnDisabled = sliderIndex >= sliderMaxIndex
  const minusBtnDisabled = sliderIndex <= 0

  return (
    <>
      <div className={`box border border-stroke-on-white-light shadow-none invest-order-box ${inputPurchaseAmount && 'w-[344px]'}`} style={{ marginTop: '16px' }}>
        <ConditionalRender isVisible={inputPurchaseAmount && !isMobile}>
          <div className='large_1 mb-[15px]'>Buy {details?.name}  
            <Tooltip
              top='top-0'
              header='Buy Units'
              content='You are buying units of a series of Linqto Liquidshares, LLC, a private unregistered fund, that invests directly or indirectly into this company.'
              showHover={showHover}
              setShowHover={setShowHover}
            /></div>
        </ConditionalRender>
        <Row className='invest-order-row amounts' style={{
          marginBottom: '14px'
        }}>
        {!inputPurchaseAmount && (
          <>
            <span>Units to Purchase</span>
            <span className='medium_2'>{formatWholeNumber(shares)}</span>
          </>
        )}
        </Row>
        <ConditionalRender isVisible={inputPurchaseAmount && !isMobile}>
          <InputPurchaseDesktop beginOrder={handleButtonClick} setShowingErrorBanner={setShowingErrorBanner} />
        </ConditionalRender>
        <ConditionalRender isVisible={!inputPurchaseAmount}>
          <Row className='invest-order-row slider-row'>
            <LinqtoSlider
              min={0}
              max={sliderMaxIndex}
              step={1}
              defaultValue={0}
              onChange={(val) => updateSliderIndex(val)}
              onAfterChange={() => Mixpanel.track('Click Slider', { Type: 'Slider', 'Company Name': name })}
              value={sliderIndex}
              onMinusBtnClick={() => updateSliderIndex(sliderIndex - 1, 'Minus')}
              onPlusBtnClick={() => updateSliderIndex(sliderIndex + 1, 'Plus')}
              disabled={false}
              plusBtnDisabled={plusBtnDisabled}
              minusBtnDisabled={minusBtnDisabled}
            />
          </Row>
          <Row className='invest-order-row__tier-price'>
            <div className='invest-order-row__tier-price__left'>
              {savingsCopy}
            </div>
            <span className={`invest-order-row__tier-price__right ${totalSavings > 0 ? 'text-text-error' : ''}`}>{totalSavings > 0 && '-'}{formatDecimal(totalSavings)}</span>
          </Row>
          {(discountPercent > 0 || discountedAmount > 0) && <Row className='invest-order-row__tier-price'>
            <div className='invest-order-row__tier-price__left'>Promo Discount</div>
            <span className='invest-order-row__tier-price__right text-text-error'>
              {discountPercent > 0 && `-${formatDecimal(discountPercent * 100, false, 0)}% (${formatDecimal(discountedAmount)})`}
              {!discountPercent && discountedAmount > 0 && `-${formatDecimal(discountedAmount)}`}
            </span>
          </Row>}
          <Row className='invest-order-row amounts'>
            <span>Amount in USD</span>
            <span className='medium_2'>{formatDecimal(amountUsd)}</span>
          </Row>
        </ConditionalRender>
      </div>
    </>
  )
}

export default OrderDetails