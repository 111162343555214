import { useSelector } from 'react-redux'
import ProductCard from './ProductCard'
import { ConditionalRender } from 'components'

const ProductList = ({ showProductDetails, hasFilter }) => {
  const { companies, retailCompanies, nonRetailCompanies, isRetailPurchaser, accreditedStatus } = useSelector(state => state.investSlice)
  const { featureFlags } = useSelector(state => state.userSlice)
  const { RetailPurchaseAllowed } = featureFlags || {}

  const renderCardsSection = (companyList, type = '') => {
    return (
      <div className='invest-card-group'>
        <ConditionalRender isVisible={companyList.length > 0}>
          {companyList.map((p, index) => (
            <ProductCard p={p} key={index} showProductDetails={showProductDetails} />
          ))}
        </ConditionalRender>
        <ConditionalRender isVisible={companyList.length === 0}>
          {hasFilter && <div className='medium_1 text-text-secondary'>There are no results in this category that match your search criteria.</div>}
          {(type === 'retail' && !hasFilter) && <div className='medium_1 text-text-secondary'>All the companies available for non-accredited investors are now sold out. Please check again later!</div>}
        </ConditionalRender>
      </div>
    )
  }
  return (
    <>
      <ConditionalRender isVisible={RetailPurchaseAllowed && isRetailPurchaser && accreditedStatus !== 'ACCREDITED'}>
        <div className='large_1 text-text-default invest-subheader'>Invest Now</div>
        {renderCardsSection(retailCompanies, 'retail')}
        <div className='large_1 text-text-default invest-subheader'>Get Accredited to Invest in These Companies <p>These investment options are only available to accredited investors. Please <a className='medium_1 link text-text-hyperlink' href='/investor-status'>update your accreditation status</a> to place an order.</p></div>
        {renderCardsSection(nonRetailCompanies)}
      </ConditionalRender>
      <ConditionalRender isVisible={!RetailPurchaseAllowed || !isRetailPurchaser || accreditedStatus === 'ACCREDITED'}>
        {renderCardsSection(companies)}
      </ConditionalRender>
    </>
  )
}

export default ProductList
