const Toggle = ({ checked, handleChange, label, disabled = false, ariaLabel = '' }) => {
  return (
    <div className='toggle-switch-container'>
      <label className='medium_1 toggle-switch-container-label'>{label}</label>
      <div>
        <div className='toggle-switch'>
          <input
            type='checkbox'
            className='toggle-switch-checkbox'
            name={`toggleSwitch_${ariaLabel}`}
            id={`toggleSwitch_${ariaLabel}`}
            checked={checked}
            onChange={handleChange}
            disabled={disabled}
            aria-label={ariaLabel}
          />
          <label className={`toggle-switch-label ${disabled ? 'bg-background-dark-gray before:bg-background-dark-gray cursor-not-allowed' : ''}`} htmlFor={`toggleSwitch_${ariaLabel}`}>
            <span className='toggle-switch-inner after:text-text-on-dark-bg before:text-text-on-dark-bg after:bg-background-dark-gray before:bg-fill-cobalt-primary' />
            <span className='bg-background-default toggle-switch-switch shadow-drop-shadow' />
          </label>
        </div>
      </div>
    </div>
  )
}

export default Toggle
