import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
import useContentful from 'hooks/useContentful'
import { contentfulContentIds } from 'components/data'

/**
 * Modal that prompts the user to complete their investor profile to withdraw funds from their account
 * @param {Object} hideModal - Function to hide the modal
 * @returns {React.ReactNode} - Modal component
 */
const CompleteInvestorProfileModal = ({ hideModal }) => {
  const { content, isLoading } = useContentful(contentfulContentIds.completeInvestorProfileModalId)
  const history = useHistory()

  const handleClick = () => history.push('/profile')

  const { fields } = content || {}
  const { cta1Label, text, title } = fields || {}

  if (isLoading || !content) {
    return null
  }

  return (
    <Modal
      modalHeader={title}
      hideModal={hideModal}
      crossToClose
      clickToClose
    >
      <div className='medium_1 content'>
        {text}
      </div>
      <div className='inline-flex justify-center'>
        <Button onClick={handleClick}>
          {cta1Label}
        </Button>
      </div>
    </Modal>
  )
}

export default CompleteInvestorProfileModal 