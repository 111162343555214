import useWindowSize from 'hooks/useWindowSize'
import { formatDate, formatDecimal, MOBILE_WIDTH_SIZE } from 'utils'

const TransactionItem = ({
  transaction: { createdAt, description, amount, direction, status }
}) => {
  const { width } = useWindowSize()

  let rowComponent = null

  if (width > MOBILE_WIDTH_SIZE) {
    rowComponent = (
      <div className='border-b border-b-stroke-on-white-light nonclickable-grid-container__row cash_history'>
        <span>{status === 'PENDING' ? status : formatDate(createdAt)}</span>
        <span>{description}</span>
        <span className='text-right'>
          { direction === 'CREDIT' ? '+' : direction === 'DEBIT' ? '-' : ''}{formatDecimal(amount)}
        </span>
      </div>
    )
  } else {
    rowComponent = (
      <div className='border-b border-b-stroke-on-white-light nonclickable-grid-container__row cash_history'>
        <div className='history-grid-container__row__full-flex' >
          <span>{status === 'PENDING' ? status : formatDate(createdAt)}</span>
          <span>{ direction === 'CREDIT' ? '+' : direction === 'DEBIT' ? '-' : ''}{formatDecimal(amount)}</span>
        </div>
        <div className='history-grid-container__row__full-flex'>
          <span>{description}</span>
        </div>
      </div>
    )
  }

  return rowComponent
}

export default TransactionItem
