import { contentfulContentIds } from 'components/data';
import { useEffect, useState } from 'react';
import { getLabelContent, processRichText } from 'utils';
import useContentful from 'hooks/useContentful';

const CONTENT_FIELDS = {
  WATCH_LIST_TITLE: 'watchListTitle',
  WATCH_LIST_COPY: 'watchListCopy',
  WATCH_LIST_NOTIFY_LABEL: 'watchListNotifyLabel',
  WATCH_LIST_ADD: 'watchListAdd',
  WATCH_LIST_REMOVE: 'watchListRemove',
  WATCH_LIST_TITLE_TOOLTIP: 'watchListTitleTooltip',
}

const useWatchListContentful = () => {
  const [watchListContentfulData, setWatchListContentfulData] = useState(null)
  const { content: contentfulData } = useContentful(contentfulContentIds.watchListId)

  useEffect(() => {
    if (!contentfulData) return

    const items = contentfulData?.fields?.items || []
    // rich text field
    const watchListCopy = items.find(item => item.fields.id === CONTENT_FIELDS.WATCH_LIST_COPY)
    const watchListTitleTooltip = items.find(item => item.fields.id === CONTENT_FIELDS.WATCH_LIST_TITLE_TOOLTIP)

    setWatchListContentfulData({
      watchListTitle: getLabelContent(CONTENT_FIELDS.WATCH_LIST_TITLE, items), 
      watchListCopy: processRichText(watchListCopy?.fields?.richText, '', false),
      watchListNotifyLabel: getLabelContent(CONTENT_FIELDS.WATCH_LIST_NOTIFY_LABEL, items),
      watchListAdd: getLabelContent(CONTENT_FIELDS.WATCH_LIST_ADD, items),
      watchListRemove: getLabelContent(CONTENT_FIELDS.WATCH_LIST_REMOVE, items),
      watchListTooltip: {
        title: watchListTitleTooltip?.fields?.tooltipTitle,
        body: processRichText(watchListTitleTooltip?.fields?.tooltipBody, '', false),
      },
    })
  }, [contentfulData])

  return watchListContentfulData
}

export default useWatchListContentful
