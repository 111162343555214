import { Mixpanel } from 'utils'
import { Button } from 'components'

const InvestmentTabContainer = ({ onChange, tab }) => {
  const handleChange = (type) => {
    onChange(type)
    Mixpanel.track('Click Portfolio Tab', { 'Portfolio Tab': type === 'history' ? 'History' : 'Current Investments' })
  }

  return (
    <div className='flex items-start justify-start mt-3 mb-8 border-b border-stroke-on-white-dark lg:my-4'>
      <Button className={`pt-4 cursor-pointer lg:mr-10 mr-7 pb-2 large_1 ${tab === 'current' && 'border-b-[3px] border-stroke-cobalt-blue text-text-hyperlink'}`} onClick={() => handleChange('current')}>Current Investments</Button>
      <Button className={`pt-4 cursor-pointer lg:mr-10 mr-7 pb-2 large_1 ${tab === 'history' && 'border-b-[3px] border-stroke-cobalt-blue text-text-hyperlink'}`} onClick={() => handleChange('history')}>History</Button>
    </div>
  )
}

export default InvestmentTabContainer
