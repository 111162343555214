import { images } from 'assets'
import { formatDecimal } from 'utils'
import { useSelector } from 'react-redux'

const ReferFriendCopy = () => {
  const { signupBonus, initialPurchaseBucksToReferrer } = useSelector((state) => state.userSlice)

  return (
    <div className='refer-friend-proccess'>
      <div className='refer-friend-proccess-section'>
        <img src={images.rewards_signup} alt='Sign up'/>
        <div>
          <div className='large_1 text-text-hyperlink'>
            Sign Up
          </div>
          <div className='medium_1'>
          When you sign up, {formatDecimal(signupBonus, true, 0)} Linqto Bucks will be added to your account to use towards your first investment.
          </div>
        </div>
      </div>
      <div className='refer-friend-proccess-section'>
        <img src={images.rewards_toggle} alt='Share link by email' />
        <div>
          <div className='large_1 text-text-hyperlink'>
          Apply Linqto Bucks
          </div>
          <div className='medium_1'>
          Your sign-up rewards will show up in your wallet as Linqto Bucks. Simply toggle on as part of your payment.
          </div>
        </div>
      </div>
      <div className='refer-friend-proccess-section'>
        <img src={images.rewards_share} alt='Invite Your Friends'/>
        <div>
          <div className='large_1 text-text-hyperlink'>
          Invite Your Friends
          </div>
          <div className='medium_1'>
            Invite your friends and earn {formatDecimal(initialPurchaseBucksToReferrer, true, 0)} for each person that invests.
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReferFriendCopy
