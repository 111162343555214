const FormError = ({ errorMessageHeader, errorMessageCopy, customClass = '' }) => {
  return (
    <div className={`error-container ${customClass}`}>
      <div className='mb-4 border text-text-error bg-background-default error message border-stroke-alert-red'>
        <div className='medium_2 header'>{errorMessageHeader}</div>
        <p>{errorMessageCopy}</p>
      </div>
    </div>
  )
}
export default FormError
