const TransactionColumns = () => {
  return (
    <div className='border-b border-b-stroke-on-white-dark nonclickable-grid-container__columns cash_history'>
      <span>DATE</span>
      <span>DESCRIPTION</span>
      <span className='text-right'>AMOUNT</span>
    </div>
  )
}

export default TransactionColumns
