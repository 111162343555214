import api from 'apis/private'
import { useQuery } from '@tanstack/react-query'
import { useMemo } from 'react'
import useDebounce from 'hooks/useDebounce'
import { useSelector } from 'react-redux'

/**
 * @function useCompanyShares hook
 * Handles data fetching and logic for the InputPurchaseAmountPage component
 * and company shares api
 * @param {Number} amount - purchase amount from user input
 * @returns - company shares data and other boolean values
 */
const useCompanyShares = (amount) => {
  const { details } = useSelector(state => state.investSlice)
  const { offers, user, sliderMinimum, sharePrice: companySharePrice, companyId, name, iconUrl, urlName, promoFlag, isSoldOut } = details || {}
  const { isProfileComplete, isAccredited } = user || {}
  const { shares: availableShares } = offers?.[0] || {}

  const debouncedAmount = useDebounce(amount, 500)
  const getCompanyShares = async (companyUrlName, amount) => {
    const response = await api.get(`/resource/companies/${companyUrlName}/shares?purchaseLimit=${amount}`)
    return response.data
  }
  
  const { data: companyShareData } = useQuery({
    queryKey: ['companyShares', urlName, debouncedAmount],
    queryFn: async () => await getCompanyShares(urlName, +debouncedAmount),
    enabled: +amount >= sliderMinimum && !!debouncedAmount,
  })

  // If there is an error, get the error message to be displayed
  let companyErrorMessage
  if (companyShareData?.error) {
    companyErrorMessage = companyShareData?.error?.message 
  }

  // Place order button text
  const isComingSoon = promoFlag === 'COMING_SOON'
  const placeOrderButtonText = (() => {
    if (!isProfileComplete) return 'Complete Profile'
    if (!isAccredited) return 'Verify Accreditation'
    if (isComingSoon) return 'Coming Soon'
    if (isSoldOut) return 'Unavailable'
    return 'Begin Order'
  })()

  // Check if the amount is positive
  const isAmountPositive = useMemo(() => +debouncedAmount > 0, [debouncedAmount])

  /**
  + * Calculates the maximum number of digits allowed for input
  + * For non-max shares: allows 3 additional digits for decimal places (demical point and 2 digits)
  + * For max shares: limits to the whole number digits of the purchase limit
  + */
  const maxDigits = useMemo(() => {
    const isMaxShares = companyShareData?.shares === availableShares
    const allowedDigits = `${Math.floor(companyShareData?.purchaseLimit)}`.length
    return !isMaxShares ? allowedDigits + 3 : allowedDigits
  }, [companyShareData, availableShares])

  // Minimum banner, maximum banner and green discount banner
  const hasDiscountPricing = offers?.[0]?.tiers?.some(tier => tier.sharePrice < companySharePrice && tier.minPurchase >= 10000)
  const showMinimumBanner = debouncedAmount < sliderMinimum && isAmountPositive
  const showMaxBanner = (companyShareData?.shares >= availableShares) && (companyShareData?.cost !== debouncedAmount)
  const showDiscountBanner = !showMinimumBanner && !showMaxBanner && !companyErrorMessage && hasDiscountPricing
  const disableOrderButton = (() => {
    if (!isProfileComplete) return false
    if (!isAccredited) return false
    return showMinimumBanner || showMaxBanner || !isAmountPositive || !!companyErrorMessage
  })()

  return {
    name, 
    iconUrl,
    companyShareData, 
    sliderMinimum, 
    companySharePrice,
    availableShares, 
    companyId, 
    isAmountPositive,
    maxDigits,
    companyErrorMessage,
    isAccredited,
    isProfileComplete,
    showMinimumBanner,
    showMaxBanner,
    showDiscountBanner,
    disableOrderButton,
    placeOrderButtonText 
  }
}
export default useCompanyShares