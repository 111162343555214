import { useState } from 'react'
import ConditionalRender from 'components/Global/ConditionalRender'
import { images } from 'assets'
import FundingMethodsModal from './FundingMethodsModal'
import { formatDecimal } from 'utils'
import { useFormContext } from 'react-hook-form'
import { Button } from 'components'

const FBOAddFundsFundingSource = ({
  selectedAccount,
  setSelectedAccount,
  insufficientError,
  setInsufficientError,
  setShowWireInstruction,
  connectPlaidAccount,
  entityId,
  type,
  label,
  testId = ''
}) => {
  const [showFundingMethodsModal, setShowFundingMethodsModal] = useState(false)
  const openFundingMethodsModal = () => {
    setShowFundingMethodsModal(true)
  }

  const { formState: { errors } } = useFormContext()

  const hideModal = () => {
    setShowFundingMethodsModal(false)
  }

  const RenderIntitalSource = () => {
    return (
      <>
        <Button className={`medium_2 border border-stroke-on-white-dark funding-source ${errors?.account ? 'border border-stroke-alert-red' : ''}`} onClick={openFundingMethodsModal} data-testid={testId} ariaLabel='Select Account'>
          Select account
          <img
            className='current-investment-item_right tab-arrow'
            src={images['right-arrow']}
            alt='Select account'
          />
        </Button>
        {errors?.account && <div className='input-group'>
          <div className='text-left text-text-error'>{errors?.account.message}</div>
        </div>
        }
      </>
    )
  }
  const RenderSelectedAccount = () => {
    return (
      <Button
        className='flex items-center justify-between w-full px-4 py-2 text-left border funding-source-account text-text-default border-stroke-on-white-light rounded-xl'
        onClick={openFundingMethodsModal}
        data-testid={testId}
        arialabel={selectedAccount.label}
      >
        <img
          alt={selectedAccount.label}
          src={selectedAccount.iconUrl}
          className='w-10 h-10 account-icon'
        />
        {selectedAccount.label}
        <div>
          {selectedAccount?.usdBalance && (
            <span className='account-balance'>
              {formatDecimal(selectedAccount.usdBalance)}
            </span>
          )}
          <img
            className='current-investment-item_right tab-arrow'
            src={images['right-arrow']}
            alt={selectedAccount.label}
          />
        </div>
      </Button>
    )
  }

  return (
    <div className='input-group'>
      <label>{label}</label>
      <ConditionalRender isVisible={!selectedAccount}>
        <RenderIntitalSource />
      </ConditionalRender>
      <ConditionalRender isVisible={!!selectedAccount}>
        <RenderSelectedAccount />
      </ConditionalRender>
      <ConditionalRender isVisible={!!showFundingMethodsModal}>
        <FundingMethodsModal
          hideModal={hideModal}
          setSelectedAccount={setSelectedAccount}
          insufficientError={insufficientError}
          setInsufficientError={setInsufficientError}
          setShowWireInstruction={setShowWireInstruction}
          connectPlaidAccount={connectPlaidAccount}
          entityId={entityId}
          type={type}
        />
      </ConditionalRender>
    </div>
  )
}

export default FBOAddFundsFundingSource
