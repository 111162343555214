import { App, GoogleRecaptcha } from './components'
import './assets/scss/style.scss'
import { Provider } from 'react-redux'
import {
  Router
} from 'react-router-dom'
import history from './history'
import { store } from './store'
import { GoogleOAuthProvider } from '@react-oauth/google'
import { createRoot } from 'react-dom/client'
import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk'
import AppWrapper from './AppWrapper.jsx'

const env = {
  'www.linqto.com': 'production',
  'app.linqto.com': 'production',
  'stage.linqto.com': 'stage',
  'beta.linqto.com': 'beta',
  'app.beta.linqto.com': 'beta',
  'alpha.linqto.com': 'alpha',
  'app.alpha.linqto.com': 'alpha',
  'app.growth.linqto.com': 'growth',
  'app.growth.linqto.io': 'growth',
  'app.team-ai.linqto.io': 'team-ai',
  'app.trade.linqto.com': 'trade',
  'app.trade.linqto.io': 'trade',
  'dev.linqto.com': 'dev',
  'dev.linqto.io': 'dev',
  'app.dev.linqto.com': 'dev',
  'app.dev.linqto.io': 'dev'
}

const getLDClientSideId = () => {
  switch (env[window.location.hostname]) {
    case 'production':
      return '6605a0e7f71c6a0f824acad0'
    case 'beta':
      return '6605a0e7f71c6a0f824acacf'
    case 'growth':
      return '67576a24efda3e0bb832c14c'
    case 'trade':
      return '67576a112fc4df0b631afd54'
    default:
      return import.meta.env.VITE_LAUNCHDARKLY_CLIENT_ID
  }
}
const googleClientId = env[window.location.hostname] === 'dev' ? '1964738591-0upb2a0nh0bc1vqnpbhbqrl1kd5losor.apps.googleusercontent.com' : '268198152363-llf1st1jhvnq1gg4r14u54jmf3ajh3b5.apps.googleusercontent.com'

const container = document.getElementById('root')
const app = createRoot(container)

let renderApp
// Remove this once initial config context provider is complete
renderApp = (LDProvider = null) => {
  const AppContent = (
    <Provider store={store}>
      <Router history={history}>
        <GoogleOAuthProvider clientId={googleClientId}>
          <GoogleRecaptcha>
            <App />
          </GoogleRecaptcha>
        </GoogleOAuthProvider>
      </Router>
    </Provider>
  )

  const AppWithProviders = LDProvider ? (
    <LDProvider>
      {AppContent}
    </LDProvider>
  ) : AppContent

  app.render(AppWithProviders)
}
renderApp = (LDProvider = null) => {

  const AppWithProviders = (
    <LDProvider>
      <AppWrapper googleClientId={googleClientId} store={store} history={history} />
    </LDProvider>
  )
  app.render(AppWithProviders)
}
// note: asyncWithLDProvider ensures flags are available at the start of app lifecycle, which prevents UI flickering due to flag
asyncWithLDProvider({
  clientSideID: getLDClientSideId(),
  timeout: 3
})
  .then(LDProvider => {
    renderApp(LDProvider)
  })
  .catch(error => {
    console.error('LaunchDarkly initialization failed:', error)
    renderApp()
  })
