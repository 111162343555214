import PropTypes from 'prop-types'

const PurchaseMessagingBanner = ({ isVisible, color, bannerPX = 'px-4', children }) => {
  
  /**
 * PurchaseMessagingBanner component
 * a wrapper banner that is used for displaying messages related to purchasing on invest detail page
 * props include color, isVisible, and children
 * children will be formatted in a row (ex. icon, text and tooltip icon)
 *  and the banner will have a background color and border color based on the color prop
 */

  let backgroundColor = ''
  let borderColor = ''

  switch (color) {
  case 'red':
    backgroundColor = 'bg-fill-light-red'
    borderColor = 'border-stroke-alert-red'
    break
  case 'blue':
    backgroundColor = 'bg-fill-light-cobalt-tiertary'
    borderColor = 'border-logo-cobalt'
    break
  case 'green':
    backgroundColor = 'bg-fill-light-mint-green'
    borderColor = 'border-logo-mint'
    break
  }

  if (!isVisible) {
    return null
  }

  return (
    <div className={`${bannerPX} mt-4 h-12 border rounded-lg ${backgroundColor} ${borderColor} flex items-center justify-between max-w-[358px] md:max-w-[344px] w-full`}>
      {children}
    </div>
  )
}

export default PurchaseMessagingBanner

PurchaseMessagingBanner.propTypes = {
  color: PropTypes.string.isRequired, // banner color
  isVisible: PropTypes.bool.isRequired, // conditionally renders the banner
  bannerPX: PropTypes.string, // Tailwind CSS class name for horizontal padding (default: 'px-4')
  children: PropTypes.node, // content inside the banner
}