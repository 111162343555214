import PropTypes from 'prop-types';

const Button = ({
  mode = 'primary',
  size = 'lg',
  loading = false,
  disabled = false,
  customClass = '',
  children,
  ariaLabel,
  type ='button',
  ...props
}) => {
  // Base button classes
  const baseClass = 'flex border-0 medium_2 cursor-pointer items-center justify-center whitespace-nowrap mt-4 mb-2 disabled:cursor-default  grow shrink md:basis-0 w-full md:w-auto';

  // Mode-specific classes
  const modeClasses = {
    primary: 'bg-fill-cobalt-primary text-text-on-dark-bg hover:bg-logo-cobalt disabled:bg-background-dark-gray focus:bg-logo-cobalt',
    secondary: 'bg-background-default text-text-hyperlink border-2 border-stroke-cobalt-blue hover:text-text-hyperlink hover:border-stroke-cobalt-blue disabled:border-stroke-on-white-dark disabled:text-text-secondary focus:border-logo-cobalt',
    tertiary: 'bg-background-default text-text-default hover:bg-background-mid-gray disabled:bg-background-default disabled:text-text-secondary focus:bg-background-mid-gray'
  };

  // Loading spinner classes
  const loadingClasses = {
    primary: 'border-[3px] border-white border-t-transparent rounded-full h-7 w-7 mx-auto animate-spinCustom',
    tertiary: 'border-[3px] border-white border-t-transparent rounded-full h-7 w-7 mx-auto animate-spinCustom',
    secondary: 'border-[3px] border-text-text-hyperlink border-t-transparent rounded-full h-7 w-7 mx-auto animate-spinCustom'
  };

  // Size-specific classes
  const sizeClasses = {
    sm: 'text-base py-0 px-5 rounded-[20px] h-10 min-w-32 min-h-10',
    lg: 'text-lg py-0 px-14 rounded-[30px] h-[50px] max-w-[408px] min-w-24 min-h-[50px]'
  }

  // Combine class lists
  let classesList = [
    baseClass,
    modeClasses[mode] || modeClasses.primary,
    sizeClasses[size] || sizeClasses.lg,
    customClass
  ]
    .filter(Boolean)
    .join(' ')

  // Render button with conditional loading state
  return (
    <button
      aria-label={ariaLabel || (typeof children === 'string' && children) || ''}
      className={classesList}
      disabled={disabled}
      type={type}
      aria-disabled={disabled}
      {...props}
    >
      {loading ? <div className={loadingClasses[mode]}></div> : children}
    </button>
  );
};

Button.propTypes = {
  mode: PropTypes.oneOf(['primary', 'secondary', 'tertiary']),
  size: PropTypes.oneOf(['sm', 'lg']),
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  customClass: PropTypes.string,
  children: PropTypes.node.isRequired,
  ariaLabel: PropTypes.string,
  type: PropTypes.string
};

export default Button;