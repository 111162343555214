import { images } from 'assets'
import PropTypes from 'prop-types'
import { useState } from 'react'

const Keypad = ({ value, setValue, cents, setCents, maxDigits }) => {
  const [inputCents, setInputCents] = useState(false)
  const numbers = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '.', '0', 'del']

const handleDelete = () => {
    if (cents) {
      setCents(prev => prev.slice(0, -1))
      return
    }

    if (inputCents) {
      setInputCents(false)
    }

    setValue(prev => {
      // Handle if prev is undefined
      const valueToModify = (prev ?? value).toString()
      const newValue = valueToModify.slice(0, -1)
      return newValue
    })
  }

  const updateNumber = num => {
    if (inputCents) {
      setCents(prev =>  prev + num)
      return
    }

    setValue(prev => {
      const newValue = prev + num
      return newValue
    })
  }

  const handleKeyPress = num => {
    // Validate input
    if (num === 'del' && !value) return
    if (!value && num === '.') return
    if (inputCents && num === '.') return
    if (inputCents && String(cents)?.length === 2 && num !== 'del') return
    if (value && value?.length >= maxDigits && num !== 'del') return

    // Handle delete
    if (num === 'del') {
      handleDelete()
      return
    }
    // Handle decimal
    if (num === '.') {
      setInputCents(true)
      return
    }

    // Handle numbers
    updateNumber(num)
  }

  return (
    <div className='grid w-full grid-cols-3'>
      {numbers.map(num => (
        <button
          aria-label={num === 'del' ? 'Delete' : `Enter ${num}`}
          role='button'
          tabIndex={0}
          key={num}
          onClick={() => {
            handleKeyPress(num)
          }}
          className={num !== 'del' ? 'w-full p-4 large_1_regular' : 'p-2'}
        >
          {num !== 'del' ? num : <img src={images['delete-button']} alt='Delete button' className='h-12' />}
        </button>
      ))}
    </div>
  )
}

export default Keypad

Keypad.propTypes = {
  value: PropTypes.string.isRequired, // input value
  setValue: PropTypes.func.isRequired, // setState function for value
  cents: PropTypes.string.isRequired, // input cents
  setCents: PropTypes.func.isRequired, // setState function for cents
  maxDigits: PropTypes.number.isRequired, // number of digits allowed
}
