import TooltipLabel from '../TooltipLabel'
import Button from 'components/Global/Button'
import { useState } from 'react'

const CopyLinkInput = ({
  name,
  value,
  label,
  placeholder,
  content = '',
  disabled = true,
  ariaLabel = '',
  type = 'text',
  onClick = () => {}
}) => {
  const [copied, setCopied] = useState(0)
  const linkCopy = () => {
    onClick()
    navigator.clipboard.writeText(value)
    setCopied(1)
  }

  return (
    <div className='input-group copy-input-group'>
      <TooltipLabel content={content} label={label} />
      <input
        disabled={disabled}
        type={type}
        placeholder={placeholder}
        aria-label={ariaLabel}
        name={name}
        value={value}
        className={`[&:not([type='checkbox'])]:disabled:bg-background-default [&:not([type='radio'])]:disabled:bg-background-default [&:not([type='checkbox'])]:disabled:text-text-default [&:not([type='radio'])]:disabled:text-text-default`}
      />
      <Button
        size='sm'
        type='button'
        customClass='absolute right-0 top-1 h-[49px] rounded-[8px] px-0 min-w-[80px]'
        onClick={linkCopy}
      >
        Copy
      </Button>
      {copied === 1 && (
        <>
          <div
            className='share-text'
            data-copied={copied}
            onAnimationEnd={() => setCopied(0)}
          >
            {value}
          </div>
          <div className='absolute text-sm -bottom-5 medium_1 text-text-default'>Link copied</div>
        </>
      )}
    </div>
  )
}

export default CopyLinkInput
