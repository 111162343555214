import React from 'react'
import PropTypes from 'prop-types'
import { images } from 'assets'
import { getHelpCenterFormattedDate } from 'utils'
/**
 * FAQ list item component that displays a FAQ item with label and date
 * @component
 * @param {Object} props - Component props
 * @param {Object} props.item - The FAQ item data
 * @param {Object} props.item.sys - System metadata for the FAQ item
 * @param {string} props.item.sys.updatedAt - Last update timestamp
 * @param {Object} props.item.fields - FAQ item fields
 * @param {string} props.item.fields.label - FAQ item label/title
 * @param {string} props.item.fields.url - FAQ item URL or ID
 * @param {Function} props.onClick - Callback function when FAQ is clicked
 * @returns {React.ReactElement} A clickable FAQ item
 */
const FaqListItem = ({ item, onClick }) => {

  const formattedDate = getHelpCenterFormattedDate(item)
  
  return (
    <div
      role="button"
      aria-label={item.fields.label}
      onClick={() => onClick(item.fields.url)}
      className='px-8 py-4 rounded-lg border cursor-pointer border-stroke-on-white-light hover:bg-gray-50'
    >
      <div className='flex justify-between items-center'>
        <div>
          <p className='medium_2' data-testid="faq-item-label">
            {item.fields.label}
          </p>
          <p className='mt-2 medium_1 text-text-secondary'>
            {formattedDate}
          </p>
        </div>
        <img src={images['arrow_forward']} alt={item.fields.label} />
      </div>
    </div>
  )
}

FaqListItem.propTypes = {
  item: PropTypes.shape({
    sys: PropTypes.shape({
      updatedAt: PropTypes.string,
      contentType: PropTypes.shape({
        sys: PropTypes.shape({
          id: PropTypes.string.isRequired
        }).isRequired
      }).isRequired
    }).isRequired,
    fields: PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  onClick: PropTypes.func.isRequired
}

export default FaqListItem 