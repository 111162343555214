/**
 * Checks if the exception has a message
 * @param {string|Error} exception - The exception to check
 * @returns {boolean} - True if the exception is an Error object
 */
const isError = (exception) => {
    return exception && typeof exception === 'object' && 'message' in exception
  }
  
  /**
   * Get the message and name properties from the original exception
   * @param {Object} event - The event containing the original error
   * @returns {Object} - An object containing `name` and `message` properties if available
   */
  export const getOriginalExceptionProperties = (event) => {
    if (event && event.originalError && isError(event.originalError)) {
      const { name, message } = event.originalError
      return { name, message }
    }
  
    return {}
  }