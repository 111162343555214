import React, { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import SeoMeta from 'components/Global/SeoMeta'
import { seoTitleTemplate } from 'utils'
import Breadcrumb from 'components/Global/Breadcrumb'
import { getHelpCenterFormattedDate } from 'utils'
import useHelpCenter from '../useHelpCenter'
import { PageLoading } from 'components'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { processRichText } from 'utils/Contentful'

const Article = () => {
  const { id } = useParams()
  const history = useHistory()
  const { isLoading, articleLists } = useHelpCenter()
  const [article, setArticle] = useState(null)
  const { isSignedIn, isUserReturned } = useSelector((state) => state.userSlice)
  const location = useLocation()
  
  useEffect(() => {
    if (isUserReturned && !isSignedIn) {
      history.push('/signin', { from: `/help-center/article/${id}` })
    }
  }, [isUserReturned, isSignedIn])
  
  useEffect(() => {
    if (location.state?.article) {
      setArticle(location.state.article)
      return
    }
    
    if (articleLists && articleLists?.length > 0 && id && !article) {
      for (const list of articleLists) {
        if (list.fields?.articlesList) {
          const foundArticle = list.fields.articlesList.find(
            item => item.fields?.url === id
          )
          
          if (foundArticle) {
            setArticle(foundArticle)
            break
          }
        }
      }
    }
  }, [id, location.state, articleLists, article])
  
  const formattedArticle = article ? processRichText(article?.fields?.body, {}, false) : null
  const formattedDate = getHelpCenterFormattedDate(article)

  if (isLoading || !isSignedIn) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Article')} />
        <PageLoading />
      </>
    )
  }

  if (!article) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Article Not Found')} />
        <div className='page-container'>
          <div className='inner-container'>
            <div className='max-w-3xl mx-auto text-center'>
              <h1 className='mb-4 text-3xl font-medium'>Article Not Found</h1>
              <p className='mb-6'>We couldn't find the article you're looking for.</p>
              <button 
                className='btn btn-primary'
                onClick={() => history.push('/help-center')}
              >
                Return to Help Center
              </button>
            </div>
          </div>
        </div>
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate(article?.fields?.title || 'Article')} />
      <div className='page-container'>
        <div className='inner-container'>
          {!window.ReactNativeWebView && (
            <Breadcrumb
              items={[
                { name: 'Help Center', route: '/help-center' },
                { name: article?.fields?.title, route: `/help-center/article/${id}` }
              ]}
            />
          )}
          <div className='max-w-3xl mx-auto'>
            <h1 className='!mb-0 text-3xl font-medium'>{article?.fields?.title}</h1>
            {formattedDate && <p className='mt-2 medium_1 text-text-secondary'>{formattedDate}</p>}
            <div className='mt-10 article'>{formattedArticle}</div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Article
