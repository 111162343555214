import { Button } from 'components'

const PlaidBankAccountBanner = ({ text, link, onClick, headline = null }) => {
  return (
    <div className='bg-legacy-citron accreditation-verification-banner'>
      <div className='accreditation-verification-banner__copy-container'>
        <span className='medium_1 margin-60'>{headline && <span className='medium_2'>{headline}</span>}{text}</span>
        {link &&
      <Button className='medium_2 text-text-hyperlink nowrap' onClick={onClick}>{link}</Button>
        }
      </div>
    </div>
  )
}

export default PlaidBankAccountBanner
