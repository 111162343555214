import * as FullStory from '@fullstory/browser'
import { getOriginalExceptionProperties } from './RaygunFullStoryUtils'

/**
 * React Hook for integrating FullStory with Raygun
 */
const useRaygunFullStory = (client, options = {}) => {
    if (!client) {
      console.warn('Raygun client is not provided.')
      return
    }

    const fsEventName = options.fsEventName || 'Raygun Error'

    const getRaygunUrl = () => `Could not retrieve url`

    client('withCustomData', () => {
      if (FullStory.FullStory && FullStory.FullStory.event) {
        FullStory.FullStory.event(fsEventName, {
          raygunUrl: getRaygunUrl(),
          ...getOriginalExceptionProperties()
        })
      }

      return {
        fullstoryUrl: decodeURIComponent(FullStory.FullStory.getCurrentSessionURL(true))|| 'current session URL API not ready'
      }
    })
}

export default useRaygunFullStory