import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import SeoMeta from 'components/Global/SeoMeta'
import { seoTitleTemplate } from 'utils'
import { PageLoading, Button } from 'components'
import { deleteAccountReasons } from '../../data'
import { getUserCanSelfDelete, submitDeleteAccount } from 'slices/securitySlice'
import ConfirmDeleteModal from './partial/ConfirmDeleteModal'
import ConfirmAutomaticDeleteModal from './partial/ConfirmAutomaticDeleteModal'
import SuccessModal from './partial/SuccessModal'
import DeleteAccountReasons from './partial/DeleteAccountReasons'
import { FormProvider, useForm } from 'react-hook-form'
import TextInput from 'components/Global/Inputs/TextInput'
import { schema } from 'schemas/deleteAccountSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { useHistory } from 'react-router-dom'

const url = 'https://hs-20548088.s.hubspotemail.net/hs/manage-preferences/unsubscribe'

const DeleteAccount = () => {
  const dispatch = useDispatch()
  const history = useHistory()

  const { selfDeleteAvailable } = useSelector(
    (state) => state.securitySlice
  )
  const [pageLoading, setPageLoading] = useState(true)
  const [showConfimModal, setShowConfimModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const methods = useForm({ resolver: yupResolver(schema) })
  const { watch, handleSubmit, unregister, setValue } = methods

  const reason = watch('reason')
  const otherReason = watch('otherReason')

  useEffect(() => {
    (async () => {
      await dispatch(getUserCanSelfDelete())
      setPageLoading(false)
    })()
  }, [])

  useEffect(() => {
    if (reason !== 'Other (Please specify below)') {
      setValue('otherReason', '')
      unregister('otherReason', { keepError: false })
    }
  }, [reason])

  const onSubmit = () => setShowConfimModal(true)

  const submitRequestDelete = async () => {
    setLoading(true)
    const option = reason === 'Other (Please specify below)' ? otherReason : reason
    const res = await dispatch(submitDeleteAccount(option))
    const { meta, payload } = res
    if (meta.requestStatus === 'fulfilled') {
      setShowConfimModal(false)
      if (selfDeleteAvailable) {
        if (payload?.userWasAutomaticallyDeleted) {
          history.push('/settings/delete-account-success')
        }
      } else {
        setShowDeleteModal(true)
      }
    }
    setLoading(false)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Delete Account')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Delete Account')} />
      <FormProvider {...methods}>
        <form className='page-container' onSubmit={handleSubmit(onSubmit)}>
          <div className='inner-container'>
            <div className='p-0 border border-stroke-on-white-light box lg:py-12 lg:px-28'>
              <div className='large_1'>We&apos;re sorry to see you go.</div>
              <div className='medium_1'>
                Why are you requesting account deletion?
              </div>
              <DeleteAccountReasons reasons={deleteAccountReasons} url={url} />
              <TextInput className='mt-4 lg:max-w-[439px] max-w-full' name='otherReason' disabled={reason !== 'Other (Please specify below)' || loading} ariaLabel='Other Reason' />
              <div className='lg:w-[439px] w-small mt-4 flex'>
                <Button disabled={loading || reason === null} type='submit'>
                  Request Account Deletion
                </Button>
              </div>
            </div>
          </div>
          {showConfimModal && selfDeleteAvailable && <ConfirmAutomaticDeleteModal submitRequestDelete={submitRequestDelete} setShowConfimModal={setShowConfimModal} loading={loading} />}
          {showConfimModal && !selfDeleteAvailable && <ConfirmDeleteModal submitRequestDelete={submitRequestDelete} setShowConfimModal={setShowConfimModal} loading={loading} />}
          {showDeleteModal && <SuccessModal />}
        </form>
      </FormProvider>
    </>
  )
}

export default DeleteAccount
