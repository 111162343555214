import { useContext } from 'react'
import { ConfigContext } from './ConfigContext.jsx'

/**
 *
 * Assumes it is available since it resolve asynchronously in the provider.
 * @returns {{configuration: Config}}
 */
function useConfig() {
  const resolvedConfig = useContext(ConfigContext)

  return { configuration: resolvedConfig }
}

export default useConfig
