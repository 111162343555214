import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import NumberInput from 'components/Global/Inputs/NumberInput'
import Select from 'components/Global/Inputs/Select'
import { Controller, useFormContext } from 'react-hook-form'
import { getSellOrderSummary, clearTotalsState } from 'slices/sellOrderSlice'
import { Mixpanel } from 'utils'
import _ from 'lodash'

const SellOrderForm = ({ setSelectedAccount, selectedAccount, setSelectedAvailableShares, selectedAvailableShares, renderErrorModal }) => {
  const dispatch = useDispatch()
  const { shareOwningAccounts, companyUrlName, companyName, loading, minimumTotalSellPrice } = useSelector(state => state.sellOrder)
  const [accountsByName, setAccountsByName] = useState([])
  const [searchLoading, setSearchLoading] = useState(false)

  useEffect(() => {
    if (shareOwningAccounts.length > 1) {
      const arr = []
      shareOwningAccounts.forEach((account) => arr.push(account.accountOwnerName))
      setAccountsByName(arr)
    } else if (shareOwningAccounts.length === 1) {
      setSelectedAccount(shareOwningAccounts[0])
      setSelectedAvailableShares(shareOwningAccounts[0].sellableShareCount)
    }
  }, [shareOwningAccounts])

  const {
    control,
    clearErrors,
    setError,
    setValue
  } = useFormContext()

  const onSelectChange = (val) => {
    clearErrors('sharesForAccount')
    if (val !== '') {
      setSelectedAccount(shareOwningAccounts.filter((a) => a.accountOwnerName === val)[0])
      setSelectedAvailableShares(shareOwningAccounts.filter((a) => a.accountOwnerName === val)[0].sellableShareCount)
    } else {
      setSelectedAccount(null)
      setSelectedAvailableShares(0)
    }
  }

  const onInputChange = _.debounce(function (val) {
    const entityId = selectedAccount?.entityId || null
    if (!val) {
      dispatch(clearTotalsState())
      clearErrors('sharesForAccount')
    } else if (val > selectedAvailableShares) {
      dispatch(clearTotalsState())
    } else if (val > 0 & val <= selectedAvailableShares) {
      setSearchLoading(true)
      dispatch(getSellOrderSummary({ companyUrlName, shareCount: val, entityId }))
        .then(({ meta, payload }) => {
          if (meta.requestStatus === 'fulfilled') {
            Mixpanel.track('Enter Shares on Create Sell Order Page',
              { 'Company Name': companyName, Shares: val, 'Estimated Share Price': payload?.sharePrice, Subtotal: payload?.subTotal, 'Transaction Fee': payload?.transactionFee, 'Esitimated Total Proceeds': payload?.totalProceeds, 'Cost Basis': payload?.totalOriginalHeldSharePrice, 'Average Cost/Share,': payload?.avgOriginalHeldPricePerShare, 'Projected Gain': payload?.projectedGain })
            if (!payload?.sharePrice) {
              renderErrorModal()
            } else {
              if (payload?.subTotal < minimumTotalSellPrice && parseInt(val) !== selectedAvailableShares) {
                setError('sharesForAccount', {
                  type: 'orderMinimum',
                  message:
                      'Minimum not reached'
                })
              } else {
                clearErrors('sharesForAccount')
              }
            }
          }
          setSearchLoading(false)
        })
    }
  }, 1000, {})

  return (
    <>
      {shareOwningAccounts.length > 1 &&
        <div className='sell-order-summary-container__row sell-order-summary-container__row__input'>
          <span className='sell-order-summary-container__row__copy'>Selling as</span>
          <Controller
            control={control}
            name='accountName'
            setValue={setValue}
            render={({ field }) => (
              <Select
                field={field}
                name='accountName'
                ariaLabel='accountName'
                options={accountsByName}
                defaultOption={{ value: '', label: 'Select account' }}
                disabled={loading || searchLoading}
                handleChange={onSelectChange}
              />
            )}
          />
        </div>
      }
      <div className='sell-order-summary-container__row sell-order-summary-container__row__input'>
        <span className='sell-order-summary-container__row__copy'>Units to Sell</span>
        <span className='sell-order-summary-container__row__copy__input-container'>
          <NumberInput
            isDecimal
            disabled={shareOwningAccounts.length > 0 && !selectedAccount}
            name='sharesForAccount'
            ariaLabel='sharesForAccount'
            handleKeyPress={(e) => {
              if (e.key === '.' || e.key === '-') {
                e.preventDefault()
              }
            }}
            onChange={(e) => {
              onInputChange(e.target.value)
            }}
          />
        </span>
      </div>
    </>
  )
}

export default SellOrderForm
