const label = {
  PENDING: {
    value: 'Pending',
    className: 'text-text-default bg-legacy-citron'
  },
  COMPLETED: {
    value: 'Completed',
    className: 'bg-logo-mint text-text-default'
  },
  CANCELED: {
    value: 'Cancelled',
    className: 'bg-fill-light-red text-text-on-dark-bg'
  },
  CLOSED: {
    value: 'Closed',
    className: 'bg-background-dark-gray text-text-default'
  }
}
const StatusTag = ({ status }) => {
  return (
    <div className={`medium_2 label ${label[status]?.className}`}>
      {label[status].value}
    </div>
  )
}

export default StatusTag
