import { MOBILE_WIDTH_SIZE } from 'utils'
import useWindowSize from 'hooks/useWindowSize'
import { Thumbnail } from 'components/Global/ProductItem'
import useWatchListContentful from '../useWatchListContentful'
import { images } from 'assets'
import MobileHorizontalWatchlistSection from './MobileHorizontalWatchlistSection'
import useWatchlistSection from './useWatchlistSection'
import { useState } from 'react'
import { Tooltip } from 'components'

const WebWatchListInvestSection = ({ title, copy, prevPage, goToPage, isDisabledLeft, isDisabledRight, shouldHideArrows, tooltip }) => {
  const { title: tooltipTitle, body: tooltipBody } = tooltip || {}
  const [showHover, setShowHover] = useState(false)
  const { width } = useWindowSize()
  return (
    <div className='flex-col'>
      <div className='flex items-center justify-between'>
        {title !== '' && <div className='flex items-center mb-4 large_1 font-CodecColdBold'>
          {title}
          <div className='watchlist-tooltip-section'>
            <Tooltip header={tooltipTitle} content={tooltipBody} showHover={showHover} setShowHover={setShowHover} />
          </div>
        </div>}
        {width > MOBILE_WIDTH_SIZE && !shouldHideArrows && <div className='flex gap-3'>
          <div className={`p-3 border rounded-full cursor-pointer border-stroke-on-white-light bg-gray-100 ${isDisabledLeft && 'opacity-[30%] cursor-auto'}`} onClick={prevPage} data-testid='arrow-left' aria-disabled={isDisabledLeft}>
            <img src={images['arrow-left']} alt='arrow-left' />
          </div>
          <div className={`p-3 border rounded-full cursor-pointer border-stroke-on-white-light bg-gray-100 ${isDisabledRight && 'opacity-[30%] cursor-auto'}`} onClick={goToPage} data-testid='arrow-right' aria-disabled={isDisabledRight}>
            <img src={images['arrow-right']} alt='arrow-right' />
          </div>
        </div>}
      </div>
      <div>
        <div className='mb-4 sm:mb-0'>{copy}</div>
      </div>
    </div>
  )
}
const WatchlistSection = ({ title, list = [], listType = 'products', onItemClick, showWatchList = false }) => {
  const { width } = useWindowSize()
  const watchListContentfulData = useWatchListContentful()

  const [currentCompanies, currentPage, totalPages, nextPage, prevPage] = useWatchlistSection(list)
  const { watchListTitle, watchListCopy, watchListTooltip } = watchListContentfulData || {}

  let WatchlistCompanies = currentCompanies?.length > 0 ? <MobileHorizontalWatchlistSection companies={list} listType={listType} onItemClick={onItemClick} showWatchList={showWatchList} /> : null

  if (width > MOBILE_WIDTH_SIZE) {
    WatchlistCompanies = (
      <div className={`grid grid-cols-4 gap-7 mt-8 justify-between`}>
        {currentCompanies?.map((item, i) => (
          <Thumbnail key={`list_${i}`} listType={listType} item={item} onItemClick={onItemClick} index={i} section={title} showWatchList={showWatchList} />
        ))}
      </div>
    )
  }

  return (
    <div className='mb-12 -mt-4 sm:mb-16 sm:mt-4 invest-revamp-content'>
      <WebWatchListInvestSection title={watchListTitle} copy={watchListCopy} prevPage={prevPage} goToPage={nextPage} isDisabledLeft={currentPage === 1} isDisabledRight={currentPage === totalPages} shouldHideArrows={list?.length === 0} tooltip={watchListTooltip} />
      {currentCompanies && currentCompanies.length > 0 && WatchlistCompanies}
    </div>)
}

export default WatchlistSection
