const FinanciallySophisticatedProgressBar = ({ currentPage, total, totalItems = 20, isMobile }) => {
  const start = currentPage + (currentPage - 1) * total
  const end = start + total

  return (
    <div className='progressbar-wrapper'>
      <div className='text-text-secondary medium_1'>{isMobile ? `Question ${currentPage} of ${total}` : `Questions ${start} - ${end} of ${totalItems}`}</div>
      <div className='progressbar-line-bg bg-fill-cobalt-primary/40'>
        <div className='bg-fill-cobalt-primary progressbar-line' style={{ width: `${(currentPage / total) * 100}%` }}/>
      </div>
    </div>
  )
}

export default FinanciallySophisticatedProgressBar
