import { useState, useEffect } from 'react'
import { ConditionalRender, PageLoading, Button } from 'components'
import SeoMeta from 'components/Global/SeoMeta'
import UnicornDetailsFundingRounds from './partials/UnicornDetailsFundingRounds'
import UnicornDetailsCompetitorFundings from './partials/UnicornDetailsCompetitorFundings'
import UnicornDetailsKeyPartners from './partials/UnicornDetailsKeyPartners'
import UnicornDetailsOfficers from './partials/UnicornDetailsOfficers'
import UnicornMosaicModal from './partials/UnicornMosaicModal'
import { Mixpanel, formatNumberWithLetter, truncate } from 'utils'
import { useParams, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getUnicornsDetails, setInterestedInUnicorn } from 'slices/unicornsSlice'
import moment from 'moment'
import { images } from 'assets'

const UnicornDetails = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const params = useParams()
  const { companyName } = params
  const company = useSelector(state => state.unicornsSlice)
  const { name, estimatedRevenue, fundingRounds, competitorFundingsUnicrons, competitorFundingsOther, boardMemebers, partners, totalRaised, lastRaised, lastRaisedDate, address, mosaic, investors, officers, logoUrl, sharePrice, foundedYear, sector, valuation, description, competitorFundings, isInterested, urlName } = company
  const [activeIndex, setActiveIndex] = useState(0)
  const [showModal, setShowModal] = useState(false)
  const [pageLoading, setPageLoading] = useState(true)
  const maxCharLength = 260
  const [truncatedDescription, setTruncatedDescription] = useState(truncate(description, maxCharLength))
  const [readMoreLink, setReadMoreLink] = useState('Read More')
  useEffect(() => {
    const fetchData = async () => {
      const { meta, payload } = await dispatch(getUnicornsDetails({ companyName }))
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Market Insights Company Page', { 'Company Name': payload?.company?.name })
      }
      setPageLoading(false)
    }
    fetchData()
  }, [])

  const setTab = (index, tab) => {
    setActiveIndex(index)
    Mixpanel.track('Click Market Insights Company Tab', { 'Company Name': name, 'Tab Name': tab })
  }

  const setDescription = () => {
    if (readMoreLink === 'Read More') {
      setTruncatedDescription(description)
      setReadMoreLink('Read Less')
    } else {
      setTruncatedDescription(truncate(description, maxCharLength))
      setReadMoreLink('Read More')
    }
  }

  const setInterestedUnicorn = async (value) => {
    const { meta } = await dispatch(setInterestedInUnicorn({ urlName, isInterested: value }))
    if (meta.requestStatus === 'fulfilled') {
      if (value) {
        Mixpanel.track('Click I want to see this on Linqto', { 'Company Name': name })
      }
    }
  }
  if (pageLoading) {
    return (
      <>
        <SeoMeta title={'Linqto Market Insights | Linqto | private investing made simple'} />
        <PageLoading />
      </>)
  }

  return (
    <>
      <SeoMeta title={`${name} | Linqto Market Insights`} />
      <div className='bg-background-default unicorns-details-container'>
        <header className='border-b bg-background-mid-gray border-b-stroke-on-white-dark text-text-default page-header'>
          <img src={images['radial-lines-gray']} className='unicorn-backgeound' alt='unciron background'/>
          <div className='inner-container page-header-company-info'>
            <div className='page-header-company-logo'>
              <div className='border border-stroke-on-white-light page-header-company-logo-wrapper'>
                <div className='border border-stroke-on-white-light page-header-company-logo-bg bg-background-default'>
                  {logoUrl && <img src={`${window.location.origin}${logoUrl}`} alt={name} />}
                </div>
              </div>
              <div className='page-header-company-title-container'>
                <h3 className='page-header-company-title'>{name}</h3>
              </div>
            </div>
            <div className='page-header-company-details'>
              <div className='page-header-company-details-desc'>
                <div>{truncatedDescription}</div>
                {description && description.length > maxCharLength &&
                <span className='hover:text-text-secondary' onClick={() => setDescription()}>{readMoreLink}</span>
                }
                { sharePrice !== null &&
                  <div className='page-header-company-share'>
                    <span className='hover:text-text-secondary'>Unit Price on Linqto:</span> {formatNumberWithLetter(sharePrice, true, true, 2)}
                  </div>
                }
                <ConditionalRender isVisible={sharePrice !== null}>
                  <Button customClass='buy-unicorn' onClick={() => history.push(`/products/${company.urlName}`)} ariaLabel='Buy on Linqto'>
                    <img src={images['unicorn-icon']} alt='Buy on Linqto'/>
                    <div>Invest on Linqto</div>
                  </Button>
                </ConditionalRender>
                <ConditionalRender isVisible={sharePrice === null && !isInterested}>
                  <Button customClass='interest-unicorn' onClick={() => setInterestedUnicorn(true)}>
                    I want to see this on Linqto
                  </Button>
                </ConditionalRender>
                <ConditionalRender isVisible={sharePrice === null && isInterested }>
                  <Button customClass='interest-unicorn interest-received' onClick={() => setInterestedUnicorn(false)} ariaLabel='You requested we add this to Linqto'>
                    <div>You requested we add this to Linqto</div>
                    <img src={images['unicorn-interested-icon']} alt='Request Received'/>
                  </Button>
                </ConditionalRender>
              </div>
            </div>
          </div>
          <div className='inner-container'>
            <div className='border medium_2 bg-background-default border-stroke-on-white-light pager-header-summary'>
              <div className='text-text-default unicorn-details-column'>
                <label>Founded Year</label>
                <div>{foundedYear || '-'}</div>
              </div>
              <div className='text-text-default unicorn-details-column'>
                <label>Sector</label>
                <div>{sector || '-'}</div>
              </div>
              <div className='text-text-default unicorn-details-column'>
                <label>Revenue</label>
                <div>{estimatedRevenue ? formatNumberWithLetter(estimatedRevenue, true, true, 1, true) : '-'}</div>
              </div>
              <div className='text-text-default unicorn-details-column'>
                <label>Valuation</label>
                <div>{valuation ? formatNumberWithLetter(valuation, true, true, 1, true) : '-'}</div>
              </div>
            </div>
          </div>
        </header>
        <div className='page-container'>
          <div className='inner-container'>
            <div className='page-tabs section-seperator'>
              <div className={`tab ${activeIndex === 0 ? 'medium_2 active' : ''}`} onClick={() => setTab(0, 'Summary')}>
                <span>Summary</span>
              </div>
              { (estimatedRevenue ||
                (fundingRounds && fundingRounds.length > 0) ||
                (competitorFundingsUnicrons && competitorFundingsUnicrons.length > 0) ||
                (competitorFundingsOther && competitorFundingsOther.length > 0)
              ) &&
                <div className={`medium_1 tab ${activeIndex === 1 ? 'medium_2 active' : ''}`} onClick={() => setTab(1, 'Funding & Competitors')}>
                  <span className='lg-tab-span'>Funding & Competitors</span>
                  <span className='sm-tab-span'>Funding</span>
                </div>
              }
              {((boardMemebers && boardMemebers.length > 0) ||
              (partners && partners.length > 0)) &&
                <div className={`medium_1 tab ${activeIndex === 2 ? 'medium_2 active' : ''}`} onClick={() => setTab(2, 'Partners & Customers')}>
                  <span className='lg-tab-span'>Partners & Customers</span>
                  <span className='sm-tab-span'>Partners</span>
                </div>
              }
            </div>
            <ConditionalRender isVisible={activeIndex === 0 }>
              <div className='text-text-default unicorn-details-section section-seperator border-b-[0.5px] border-b-stroke-on-white-dark'>
                <div className='grid-old'>
                  <div className='row row-with-padding'>
                    <div className='column three eight-mobile text-text-default unicorn-details-column'>
                      <label>Total Raised</label>
                      <div>{totalRaised ? formatNumberWithLetter(totalRaised, true, true, 1, true) : '-'}</div>
                    </div>
                    <div className='column three eight-mobile text-text-default unicorn-details-column'>
                      <label>Last Raised</label>
                      <div>{lastRaised && `${formatNumberWithLetter(lastRaised, true, true, 1, true)} | ` } { lastRaisedDate && moment(lastRaisedDate).fromNow()}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='text-text-default unicorn-details-section section-seperator border-b-[0.5px] border-b-stroke-on-white-dark'>
                <div className='grid-old'>
                  <div className='row row-with-padding'>
                    <div className='column three sixteen-mobile text-text-default unicorn-details-column'>
                      <label>Headquarters Location</label>
                      {address?.street && <div>{address?.street || '-'}</div>}
                      {address?.city && <div>{address?.city}, {address?.state}</div>}
                      {address?.postalCode && <div>{address?.postalCode}</div>}
                      {address?.country && <div>{address?.country}</div>}
                    </div>
                    <div className='column three sixteen-mobile text-text-default unicorn-details-column mobile-section-seperator'>
                      <label>Mosaic Score</label>
                      {mosaic?.overall && <div>{mosaic?.overall}</div>}
                      {mosaic?.momentum && <div>Momentum - {mosaic?.momentum}</div>}
                      {mosaic?.market && <div>Market - {mosaic?.market}</div>}
                      {mosaic?.money && <div>Money - {mosaic?.money}</div>}
                      {mosaic?.management && <div>Management - {mosaic?.management}</div>}
                      {mosaic && <span className='hover:text-text-secondary' onClick={() => setShowModal(true)}>What is a Mosaic Score?</span>}
                    </div>
                    <div className='column three sixteen-mobile text-text-default unicorn-details-column mobile-section-seperator'>
                      <label>Key Investors</label>
                      <div>{investors || '-'}</div>
                    </div>
                  </div>
                </div>
              </div>
              {officers &&
                <div className='text-text-default unicorn-details-section'>
                  <div className='column text-text-default unicorn-details-column sixteen'>
                    <label className='light'>Key Officers</label>
                  </div>
                  <UnicornDetailsOfficers officers={officers} />
                </div>
              }
            </ConditionalRender>
            <ConditionalRender isVisible={activeIndex === 1 }>
              <div className='text-text-default unicorn-details-section section-seperator border-b-[0.5px] border-b-stroke-on-white-dark'>
                <div className='grid-old'>
                  <div className='row'>
                    <div className='column sixteen text-text-default unicorn-details-column'>
                      <label>Revenue:</label>
                      <div>{estimatedRevenue ? formatNumberWithLetter(estimatedRevenue, true, true) : '-'}</div>
                    </div>
                  </div>
                </div>
              </div>
              {fundingRounds && fundingRounds.length > 0 &&
                <div className='text-text-default unicorn-details-section'>
                  <div className='text-text-default unicorn-details-column'>
                    <label className='light'>Funding Rounds</label>
                    <UnicornDetailsFundingRounds fundingRounds={fundingRounds}/>
                  </div>
                </div>
              }
              {competitorFundingsUnicrons && competitorFundingsUnicrons.length > 0 &&
                <div className='text-text-default unicorn-details-section'>
                  <div className='text-text-default unicorn-details-column'>
                    <label className='light'>Unicorn Competitors & Peer Funding</label>
                  </div>
                  <UnicornDetailsCompetitorFundings competitorFundings={competitorFundingsUnicrons}/>
                </div>
              }
              {competitorFundingsOther && competitorFundingsOther.length > 0 &&
                <div className='text-text-default unicorn-details-section'>
                  <div className='text-text-default unicorn-details-column'>
                    <label className='light'>Non-Unicorn Competitors & Peers</label>
                  </div>
                  <div className='desktop-content'>
                    <div className='grid-old unicorn-table'>
                      <div className='row'>
                        <div className='column five text-text-default unicorn-details-column' style={{ paddingLeft: 0 }}><strong>Companies</strong></div>
                        <div className='column five text-text-default unicorn-details-column'> { competitorFundings && competitorFundings.map((f, i) =>
                          <div key={`competitorOthrtFundings_${i}`} className='column'>{f.companyName}</div>
                        )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='unicorn-card-container'>
                    <div className='border-b border-b-stroke-on-white-light unicorn-card' >
                      <div className='grid-old'>
                        {competitorFundings && competitorFundings.map((f, i) =>
                          <div className='row unicorn-card-row' key={`mcompetitorOthrtFundings_${i}`}>
                            <div className='column eight'>{i === 0 && <strong>Companies: </strong>}</div>
                            <div className='column eight'>{f.companyName}</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              }
            </ConditionalRender>
            <ConditionalRender isVisible={activeIndex === 2 }>
              {partners && partners.length > 0 &&
                <div className='text-text-default unicorn-details-section'>
                  <div className='text-text-default unicorn-details-column'>
                    <label className='light'>Key Partners & Customers</label>
                  </div>
                  <UnicornDetailsKeyPartners partners={partners}/>
                </div>
              }
              {boardMemebers && boardMemebers.length > 0 &&
              <div className='text-text-default unicorn-details-section'>
                <div className='text-text-default unicorn-details-column'>
                  <div>Officers & Board of Directors</div>
                </div>
                {boardMemebers && <UnicornDetailsOfficers officers={boardMemebers} />}
              </div>
              }
            </ConditionalRender>
          </div>
        </div>
      </div>
      <ConditionalRender isVisible={showModal}>
        <UnicornMosaicModal setShowModal={setShowModal}/>
      </ConditionalRender>
    </>
  )
}

export default UnicornDetails
