import { useSelector } from 'react-redux'
import { images } from 'assets'
import Button from 'components/Global/Button'
import { formatDecimal } from 'utils'
import ClientStatus from './ClientStatus'

const ClientList = ({ viewAs }) => {
  const { allowedImpersonations } = useSelector((state) => state.ria)

  return (
    <div className='ria-client-list'>
      <div className='flex-col items-end justify-between uppercase border-b lg:flex-row lg:items-center border-stroke-on-white-dark'>
        <div className='lg:w-[38%] w-full'>Client</div>
        <div className='lg:w-[38%] w-full'></div>
        <div className='-mt-6 lg:mt-0'>Investment</div>
        <div className='lg:w-[18%] lg:pl-0 flex justify-end pl-4 w-full'></div>
      </div>

      {allowedImpersonations.length === 0 && (
        <div className='border-b border-b-stroke-on-white-dark box-item'>
          You don&apos;t have any linked clients yet.
        </div>
      )}
      {allowedImpersonations.map((user, i) => (
        <div className='flex-col items-end justify-between border-b lg:flex-row lg:items-center border-stroke-on-white-light' key={i}>
          <div className='lg:w-[38%] w-full flex'>
            {user.firstName} {user.lastName}{' '}
            {user.isCurrentIdentity && (
              <div className='mt-1 ml-2'>
                <img
                  width={25}
                  alt={user.firstName}
                  src={images.checkmark}
                />
              </div>
            )}
          </div>
          <div className='lg:w-[38%] w-full flex'>
            <ClientStatus
              status={user.accreditationStatus}
              userId={user.userId}
              hasPendingOrder={user.hasPendingOrder}
            />
          </div>
          <div className='mr-4 lg:w-[18%] justify-end w-full flex'>
            {formatDecimal(user.totalInvestment)}
          </div>
          <div className='lg:w-[18%] lg:pl-0 flex justify-end pl-4 w-full'>
            <Button
              size='sm'
              onClick={() => viewAs(user.userId)}
              ariaLabel={`View as ${user.firstName}`}
            >
              View As
              <img
                alt='View as'
                src={images.arrow_white_right}
                className='ml-4'
              />
            </Button>
          </div>
        </div>
      ))}
    </div>
  )
}

export default ClientList
