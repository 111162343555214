import { useFormContext } from 'react-hook-form'

const RadioButton = ({
  label,
  name,
  value,
  className,
  disabled = false,
  ariaLabel = '',
  required = false
}) => {
  const { register } = useFormContext()
  return (
    <div className='radio-option'>
      <label className='radio-option_label-container medium_1 text-text-default'>
        <input
          type='radio'
          {...register(name, { required })}
          value={value}
          disabled={disabled}
          className={`bg-background-default disabled:bg-background-dark-gray checked:bg-fill-cobalt-primary disabled:border-stroke-on-white-light border-[1.5px] border-stroke-on-white-dark ${className}`}
          aria-label={ariaLabel}
        />
        <span className={className}>
          {label}
        </span>
      </label>
    </div>
  )
}

export default RadioButton
