import { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux'
import { PageLoading, Button, ConditionalRender } from 'components'
import { seoTitleTemplate, Mixpanel, Fullstory } from 'utils'
import SeoMeta from 'components/Global/SeoMeta'
import Checkbox from 'components/Global/Inputs/Checkbox'
import { FormProvider, useForm, Controller } from 'react-hook-form'
import { useQuery } from 'hooks/useQuery'
import { schema } from 'schemas/recurringInvestmentSchema'
import { yupResolver } from '@hookform/resolvers/yup'
import { getRecurringInvestment, cancelRecurringInvestment, updateRecurringInvestment } from 'slices/recurringInvestmentSlice'
import ProductDocuments from 'components/OrderDetail/partials/ProductDocuments'
import Breadcrumb from 'components/Global/Breadcrumb'
import Select from 'components/Global/Inputs/Select'
import DatePickerInput from 'components/Global/Inputs/DatePickerInput'
import moment from 'moment'
import ConfirmCancellationModal from './partials/ConfirmCancellationModal'
import { RecurringInvestmentFrequency } from '../data'

const RecurringInvestment = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const query = useQuery()

  const { recurringEventId } = useParams() || 0

  const { documents, initialValues } = useSelector(state => state.recurringInvestment)
  const forwardedCompanyId = query.get('companyId')

  const formStatus = recurringEventId ? 'edit' : 'create'

  const [pageLoading, setPageLoading] = useState(true)
  const [commitLoading, setCommitLoading] = useState(false)
  const [selectedAccount, setSelectedAccount] = useState({})
  const [companies, setCompanies] = useState([])
  const [accounts, setAccounts] = useState([])
  const [fundingSources, setFundingSources] = useState([])
  const [initiatedDate, setInitiatedDate] = useState('')
  const [nextDate, setNextDate] = useState(false)
  const [showConfimModal, setShowConfimModal] = useState(false)
  const [companyHasInvestment, setCompanyHasInvestment] = useState(false)

  const methods = useForm({
    mode: 'onTouched',
    resolver: yupResolver(schema),
    context: {
      companyHasInvestment: companyHasInvestment && formStatus === 'create'
    },
    defaultValues: {
      confirmCheckbox: formStatus === 'edit',
      startDate: moment().clone().add(1, 'days')._d,
      frequency: 'MONTHLY',
      userId: selectedAccount?.userId,
      recurringEventId: recurringEventId || 0,
      companyId: forwardedCompanyId || ''
    }
  })

  const { handleSubmit, setValue, control, watch, formState: { errors } } = methods

  const items = [
    { name: 'My Portfolio', route: '/portfolio' },
    { name: 'Recurring Investment', route: '/recurring-investment' }
  ]

  const parseFundingSource = (array) => {
    const fundingSourceArray = []
    array.forEach((account) =>
      fundingSourceArray.push({
        label: `${account.bankName} (${account.lastFour})`,
        value: account?.cashExternalAccountId
      }))
    return fundingSourceArray
  }

  const parseAccounts = (array) => {
    const accountsArray = []
    array.forEach((purchaser) =>
      accountsArray.push({
        label: purchaser.name,
        value: purchaser?.entityId || 'individual',
        name: purchaser.name,
        entityId: purchaser?.entityId || 'individual',
        availableCompanies: purchaser.availableCompanies,
        fundingSources: purchaser.fundingSources
      }))
    return accountsArray
  }

  const parseCompanies = (array) => {
    const companiesArray = []
    array.forEach((company) =>
      companiesArray.push({
        label: company.name,
        value: company?.companyId
      }))
    return companiesArray
  }

  const initializeDropDowns = (purchasers, entityId) => {
    const accountsArray = parseAccounts(purchasers)
    setAccounts(accountsArray)

    let account = null
    if (formStatus === 'create') {
      account = accountsArray[0]
    } else {
      account = accountsArray.filter((account) => (account.entityId.toString() === entityId.toString()))[0]
    }
    setSelectedAccount(account)
    setCompanies(parseCompanies(account?.availableCompanies))
    setFundingSources(parseFundingSource(account?.fundingSources))

    return account
  }

  const handlePurchaseAsChange = (value) => {
    setValue('companyId', '')
    setValue('cashExternalAccountId', '')
    setSelectedAccount(accounts.filter((account) => (account.entityId.toString() === value.toString()))[0])
    setFundingSources(parseFundingSource(accounts.filter((account) => (account.entityId.toString() === value.toString()))[0]?.fundingSources))
    setCompanies(parseCompanies(accounts.filter((account) => (account.entityId.toString() === value.toString()))[0]?.availableCompanies))
  }

  useEffect(() => {
    (async () => {
      const res = await dispatch(getRecurringInvestment(recurringEventId || 0))
      const { meta, payload } = res
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('View Recurring Investment Page')
        Fullstory.track('Page View', {
          page_name: 'View Recurring Investment'
        })
        let account = null
        if (formStatus === 'create') {
          account = initializeDropDowns(payload?.purchasers)
          setValue('entityId', parseAccounts(payload?.purchasers)?.[0]?.value)
          setValue('userId', payload?.purchasers[0]?.userId)
        } else {
          const entityId = payload?.recurringEvent?.entityId || 'individual'
          account = initializeDropDowns(payload?.purchasers, entityId)
          setValue('userId', payload?.recurringEvent?.userId)
          setValue('entityId', entityId)

          const companyName = account?.availableCompanies?.filter((company) => (company?.companyId.toString() === payload?.recurringEvent?.companyId.toString()))[0]?.name
          Mixpanel.track('Edit Recurring Investment Page', { 'Selected Company': companyName })
          Fullstory.track('Page View', { page_name: 'Edit Recurring Investment', selected_company: companyName })
        }
      }
      setPageLoading(false)
    })()
  }, [])

  useEffect(() => {
    setInitiatedDate(moment(watch('startDate')).format('MMMM DD,YYYY'))
    setNextDate(watch('frequency') === 'MONTHLY' ? 'month' : watch('frequency') === 'WEEKLY' ? 'week' : 'two weeks')
  }, [watch('startDate'), watch('frequency')])

  useEffect(() => {
    const companyName = selectedAccount?.availableCompanies?.filter((company) => (company?.companyId.toString() === watch('companyId').toString()))[0]?.name
    setValue('companyName', companyName)
  }, [watch('companyId')])

  useEffect(() => {
    const hasInvestment = selectedAccount?.availableCompanies?.filter((company) => (company?.companyId.toString() === watch('companyId').toString()))[0]?.hasRecurringInvestment
    setCompanyHasInvestment(!!hasInvestment)
  }, [watch('companyId')])

  useEffect(() => {
    if (formStatus === 'edit') {
      methods.reset(initialValues)
    }
  }, [initialValues])
  const onSubmit = async (formValues) => {
    setCommitLoading(true)
    if (isNaN(formValues.startDate)) formValues.startDate = new Date(formValues.startDate).getTime()
    const res = await dispatch(updateRecurringInvestment(formValues))
    const { meta, payload } = res
    if (meta.requestStatus === 'fulfilled') {
      const eventName = formStatus === 'create' ? 'Confirm Recurring Investment' : 'Confirm Edit Investment'
      Mixpanel.track(`${eventName} Clicked`, {
        'Account Type': formValues.entityId === 'individual' ? 'Individual' : 'Entity',
        Company: formValues.companyName,
        'Investment Amount': formValues.maxAmount,
        'Start Date': formValues.startDate,
        Frequency: RecurringInvestmentFrequency[formValues.frequency]
      })
      Fullstory.track('Button',
        {
          name: eventName,
          account_type: formValues.entityId === 'individual' ? 'Individual' : 'Entity',
          company: formValues.companyName,
          investment_amount: formValues.maxAmount,
          start_date: formValues.startDate,
          frequency: RecurringInvestmentFrequency[formValues.frequency]
        })
      if (formStatus === 'edit') {
        localStorage?.setItem('edit-recurring-investment', true)
      }
      history.push(`/recurring-investment-confirmed/${payload?.recurringEventId}`)
    }
    setCommitLoading(false)
  }

  const submitCancellation = async () => {
    setCommitLoading(true)
    const res = await dispatch(cancelRecurringInvestment(recurringEventId))
    const { meta } = res
    if (meta.requestStatus === 'fulfilled') {
      Mixpanel.track('Confirm Cancellation Clicked', {
        'Company Name': watch('companyName')
      })
      Fullstory.track('Button',
        {
          name: 'Confirm Cancellation',
          company_name: watch('companyName')
        })
      localStorage?.setItem('cancel-recurring-investment', true)
      history.push('/portfolio')
    }
    setCommitLoading(false)
  }

  const cancelInvestment = () => {
    Mixpanel.track('Cancel Investment Clicked', {
      'Company Name': watch('companyName')
    })
    Fullstory.track('Button',
      {
        name: 'Cancel Investment',
        company_name: watch('companyName')
      })
    setShowConfimModal(true)
  }

  if (pageLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Recurring Investment')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Recurring Investment')} />
      <FormProvider {...methods}>
        <div className='page-container page-with-breadcrumb'>
          <div className='inner-container'>
            <Breadcrumb items={items} />
            <h1>{formStatus === 'edit' && 'Edit '}Recurring Investment</h1>
            <div className='border border-stroke-on-white-light add-funds-container'>
              <form onSubmit={handleSubmit(onSubmit)}>
                <ConditionalRender isVisible={accounts.length > 1}>
                  <Controller
                    control={control}
                    name='entityId'
                    setValue={setValue}
                    render={({ field }) => (
                      <Select
                        field={field}
                        disabled={commitLoading || formStatus === 'edit'}
                        label='Purchase as'
                        name='entityId'
                        ariaLabel='entityId'
                        optionsWithLabel={accounts}
                        setValue={setValue}
                        handleChange={handlePurchaseAsChange}
                      />
                    )}
                  />
                </ConditionalRender>
                <Controller
                  control={control}
                  name='companyId'
                  setValue={setValue}
                  render={({ field }) => (
                    <Select
                      field={field}
                      disabled={commitLoading || formStatus === 'edit'}
                      label='Company'
                      name='companyId'
                      ariaLabel='companyId'
                      optionsWithLabel={companies}
                      setValue={setValue}
                      defaultOption={{ value: '', label: 'Select Company' }}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='maxAmount'
                  setValue={setValue}
                  render={({ field }) => (
                    <Select
                      field={field}
                      disabled={commitLoading}
                      label='Investment Amount'
                      name='maxAmount'
                      ariaLabel='maxAmount'
                      optionsWithLabel={[
                        { label: '$500.00', value: 500.00 },
                        { label: '$1,000.00', value: 1000.00 },
                        { label: '$2,500.00', value: 2500.00 },
                        { label: '$5,000.00', value: 5000.00 }]}
                      setValue={setValue}
                      defaultOption={{ value: '', label: 'Select $ Amount' }}
                      content='The max amount we’ll withdraw from your funding source to make your purchase.  Amount may be less depending on share price at time of transaction. '
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='cashExternalAccountId'
                  setValue={setValue}
                  render={({ field }) => (
                    <Select
                      field={field}
                      disabled={commitLoading}
                      label='Funding Source'
                      name='cashExternalAccountId'
                      ariaLabel='cashExternalAccountId'
                      optionsWithLabel={fundingSources}
                      setValue={setValue}
                      defaultOption={{ value: '', label: 'Select Account' }}
                      content='We’ll use this account to fund your purchase, and not use funds from your Linqto Cash Account balance.'
                    />
                  )}
                />
                <Controller
                  name='startDate'
                  render={({ field: { onChange, value } }) => (
                    <DatePickerInput
                      label='Start Date'
                      disabled={commitLoading}
                      value={value}
                      onChange={onChange}
                      name='startDate'
                      ariaLabel='startDate'
                      placeholder='Select Date'
                      minDate={moment().clone().add(1, 'days')._d}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name='frequency'
                  setValue={setValue}
                  render={({ field }) => (
                    <Select
                      field={field}
                      disabled={commitLoading}
                      label='Frequency'
                      name='frequency'
                      ariaLabel='frequency'
                      optionsWithLabel={[
                        { label: 'Weekly', value: 'WEEKLY' },
                        { label: 'Every Two Weeks', value: 'FORTNIGHTLY' },
                        { label: 'Monthly', value: 'MONTHLY' }]}
                      setValue={setValue}
                      defaultOption={{ value: '', label: 'Select Frequency' }}
                    />
                  )}
                />
                <ProductDocuments documents={documents} type='recurring-investment' selectedAccount={selectedAccount} />
                <Checkbox
                  label='I confirm I have read these documents and agree to be bound by their provisions.'
                  name='confirmCheckbox'
                  ariaLabel='confirmCheckbox'
                  disabled={commitLoading}
                  checkboxClass='confirm-checkbox'
                />
                <div className='text-center text-text-default margined medium_1'>
                  Your next recurring investment will be initiated <span className='medium_2'>{initiatedDate}</span>, and <span className='medium_2'>recur every {nextDate}</span>, and shares will become available once payment funds clear.
                </div>
                <ConditionalRender isVisible={formStatus === 'create'}>
                  <div className='flex buy-order-row'>
                    <Button
                      type='submit'
                      loading={commitLoading}
                      disabled={commitLoading || Object.keys(errors).length > 0}
                      customClass='full-width'
                    >
                    Confirm Recurring Investment
                    </Button>
                  </div>
                </ConditionalRender>
                <ConditionalRender isVisible={formStatus === 'edit'}>
                  <div className='buy-order-row md:flex md:gap-4'>
                    <Button
                      type='button'
                      mode='secondary'
                      disabled={commitLoading}
                      onClick={() => {
                        history.push(`/recurring-investment-confirmed/${recurringEventId}`)
                      }}
                    >
                    Back
                    </Button>
                    <Button
                      type='submit'
                      loading={commitLoading}
                      disabled={commitLoading || Object.keys(errors).length > 0}
                    >
                    Update
                    </Button>
                  </div>
                  <div className='flex justify-center mt-8'>
                    <Button className='link text-text-error' onClick={cancelInvestment}>Cancel Investment</Button>
                  </div>
                </ConditionalRender>
              </form>
            </div>
          </div>
        </div>
      </FormProvider>
      {showConfimModal && <ConfirmCancellationModal submitCancellation={submitCancellation} setShowConfimModal={setShowConfimModal}/>}
    </>
  )
}

export default RecurringInvestment
