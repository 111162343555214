import BrazeConfig, { CONFIG_BRAZE } from 'utils/monitoring/Braze/Braze.config.js'
import ContentfulConfig, { CONFIG_CONTENTFUL } from 'utils/content/Contentful.config.js'
import AiAuthConfig, { CONFIG_AI_AUTH } from 'components/AiAuth/AiAuth.config.js'
import FullstoryConfig, { CONFIG_FULLSTORY } from 'utils/monitoring/Fullstory/Fullstory.config.js'
import GoogleRecaptchaConfig, {
  CONFIG_GOOGLE_RECAPTCHA
} from 'utils/auth/GoogleRecaptcha.config.js'

/**
 *
 * @type {LDClient | null}
 */
let configSource = null

/**
 * This is required to hold onto the values for rare cases we can't access the singleton configuration instance, we access it via the static module import
 */
let configValues = {}

/**
 * The implementation and values are a singleton in the application
 *
 * Not coding to an interface to avoid adding un-needed complexity right now.
 *
 * @param {import('launchdarkly_js_client_sdk').LDClient} configSourceImpl
 * @returns {Config}
 * @constructor
 */
function Config(configSourceImpl = null) {
  if (configSourceImpl) {
    configSource = configSourceImpl
  }
  return this
}

const CONFIG_TOKENS = [
  CONFIG_BRAZE,
  CONFIG_CONTENTFUL,
  CONFIG_AI_AUTH,
  CONFIG_FULLSTORY,
  CONFIG_GOOGLE_RECAPTCHA
]

Config.prototype.init = async function () {
  const results = configSource.allFlags()
  const config = {}
  CONFIG_TOKENS.forEach(token => {
    config[token] = results[token] || null
  })

  Object.assign(configValues, config)
  Object.assign(this, configValues)
  return this
}

export default Config

/**
 * For convenience if the desired config is not yet accounted for in the prototype
 * @param token
 * @returns {*|null}
 */
Config.prototype.get = function (token) {
  const value = this[token] || null
  !value && console.warn(`Config value ${token} not found`)
  return value
}

/**
 * @typedef {Object.<string, {current: any, previous: any}>} LDUpdates
 */

/**
 * This will yield new memory of the config with the updated values merged
 * @typedef {Object} onchange
 * @param {LDUpdates} updates
 */
Config.prototype.merge = function (updates) {
  const cloned = new Config(configSource)
  Object.assign(cloned, this)
  CONFIG_TOKENS.forEach(token => {
    if (updates[token] === undefined) {
      return
    }
    cloned[token] = updates[token].current
  })
  Object.assign(configValues, cloned)
  return cloned
}

/**
 *
 * @returns {BrazeConfig}
 */
Config.prototype.getBrazeConfig = function () {
  return new BrazeConfig(this)
}

/**
 *
 * @returns {ContentfulConfig}
 */
Config.prototype.getContentfulConfig = function () {
  return new ContentfulConfig(this)
}

/**
 *
 * @returns {AiAuthConfig}
 */
Config.prototype.getAiConfig = function () {
  return new AiAuthConfig(this)
}

/**
 *
 * @returns {FullstoryConfig}
 */
Config.prototype.getFullstoryConfig = function () {
  return new FullstoryConfig(this)
}

/**
 *
 * @returns {GoogleRecaptchaConfig}
 */
Config.prototype.getGoogleRecaptchaConfig = function () {
  return new GoogleRecaptchaConfig(this)
}
