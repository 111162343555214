import useReSignUpUser from 'hooks/useReSignUpUser'
import Drawer from 'components/Global/Drawer/Drawer'
import { useLDFlags } from 'utils'
import { useSelector } from 'react-redux'
const ReSignUpUserDrawer = () => {
  const { needToCompleteInvestorProfile } = useSelector(state => state.userSlice)
  const { showRevalidateUserDrawer } = useLDFlags(['showRevalidateUserDrawer'])
  const [showDrawer, handleClose, data] = useReSignUpUser()
  // If the drawer is not shown or the user does not need to re-kyc, do not render the drawer
  if (!showRevalidateUserDrawer || !needToCompleteInvestorProfile) return null
  return <Drawer visible={showDrawer} data={data} handleClose={handleClose} />
}

export default ReSignUpUserDrawer
