import React from 'react'
import { useFormContext } from 'react-hook-form'
import ErrorMessage from './ErrorMessage'

const RegionCountryDropdown = ({ items, type, name, label, ariaLabel, disabled, onChange = () => {} }) => {
  const { register, formState: { errors } } = useFormContext()

  let placeholder = ''

  if (type === 'regions') {
    placeholder = 'Select Region'
  } else {
    placeholder = 'Select Country'
  }

  const options = items?.map((item, i) => (
    <option value={item} key={`${item} + ${i}`} data-testid='select-option'>{item}</option>
  ))

  return (
    <div className={`input-group ${(errors[name]) && 'error'}`}>
      <label className='text-text-default'>{label}</label>
      <select {...register(name, { onChange })} disabled={disabled} aria-label={ariaLabel} className={`border bg-background-default border-stroke-on-white-dark text-text-default hover:border-stroke-cobalt-blue hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue ${errors[name] ? 'border border-stroke-alert-red hover:border-stroke-alert-red focus:border-stroke-alert-red' : ''}`}>
        <option value=''>{placeholder}</option>
        {options}
      </select>
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default RegionCountryDropdown
