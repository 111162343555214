import ErrorMessage from 'components/Global/Inputs/ErrorMessage'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const BirthDropdown = ({ label, ariaLabel, name, items, disabled, onChange = () => {} }) => {
  const { register, formState: { errors } } = useFormContext()

  let options = []

  if (name === 'birthMonth') {
    options = items.map(({ id, m }) => (
      <option key={id} value={id} data-testid='select-option'>{m}</option>
    ))
  } else {
    options = items.map((item, i) => (
      <option key={`${item} + ${i}`} value={item} data-testid='select-option'>{item}</option>
    ))
  }

  return (
    <div className={`input-group ${(errors[name]) && 'error'}`}>
      <label className='text-text-default'>{label}</label>
      <select {...register(name, { onChange })} disabled={disabled} aria-label={ariaLabel} className={`border bg-background-default border-stroke-on-white-dark text-text-default hover:border-stroke-cobalt-blue hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue ${errors[name] ? 'border border-stroke-alert-red hover:border-stroke-alert-red focus:border-stroke-alert-red' : ''}`}>
        <option value=''>Select</option>
        {options}
      </select>
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default BirthDropdown
