import { images } from 'assets'
import Button from '../../Global/Button'
const SecurityItem = ({
  option,
  name,
  description,
  icon,
  handelClick,
  email,
  disabled = false
}) => {
  return (
    <div className='border-b border-b-stroke-on-white-light box-item'>
      <div className='box-item-icon'>
        <img alt={`Icon representing ${name}`} src={images[`${icon}`]} />
      </div>
      <div className='box-item-description'>
        {name}
        <span className='hidden md:block medium_1 text-text-secondary'>
          {description} {option.type === 'EMAIL' && email}
        </span>
      </div>
      {option.verified && (
        <div className='verified'>
          <img
            width={25}
            alt={`${name} is verified`}
            src={images.checkmark}
          />
        </div>
      )}
      <div className='ml-auto'>
        {option.verified ? (
          <Button
            disabled={disabled}
            mode='tertiary'
            size='sm'
            onClick={() => handelClick(option.userMfaId)}
            ariaLabel={`Disable ${option.type.toLowerCase()}`}
          >
            Disable
          </Button>
        ) : (
          <Button
            mode='tertiary'
            size='sm'
            onClick={() => handelClick(`${option.type.toLowerCase()}`)}
            ariaLabel={`Set Up ${option.type.toLowerCase()}`}
          >
            Set Up
          </Button>
        )}
      </div>
    </div>
  )
}

export default SecurityItem
