import { Img, useImage } from 'react-image'

export const CircleLoader = (props) => {
  return (
    <div className='loader-component' {...props}>
      <div className='circle-spinner'>
        <div className='bg-logo-cobalt/90 double-bounce1' style={{ ...props.loaderStyle }} />
        <div className='bg-logo-cobalt/20 double-bounce2' style={{ ...props.loaderStyle }} />
      </div>
    </div>
  )
}

const Image = (props) => {
  return (
    <Img
      {...props}
      src={props.src}
      loader={<CircleLoader />}
    />
  )
}

export const ImgWithLoader = (props) => {
  const { src } = useImage({
    srcList: props.src
  })

  return <img {...props} src={src} alt={props.alt} />
}

export default Image
