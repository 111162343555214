export const CONFIG_GOOGLE_RECAPTCHA = 'configGoogleRecaptcha'

/**
 *
 * @param {Config} config
 * @returns {GoogleRecaptchaConfig}
 * @constructor
 */
function GoogleRecaptchaConfig(config) {
  Object.assign(this, config.get(CONFIG_GOOGLE_RECAPTCHA))
  return this
}

export default GoogleRecaptchaConfig

GoogleRecaptchaConfig.prototype.getKeyId = function() {
  return this.keyId
}

GoogleRecaptchaConfig.prototype.isValid = function() {
  return !!this.getKeyId()
}