import useWindowSize from 'hooks/useWindowSize'
import { truncate, transformPromoFlag, MOBILE_WIDTH_SIZE, formatDecimal } from 'utils'

const ProductCard = ({ p, showProductDetails }) => {
  const { width } = useWindowSize()
  const promoStyle = {
    RECENTLY_ADDED: 'bg-fill-cobalt-primary',
    TOP_SELLER: 'bg-legacy-unicorn',
    FULLY_SUBSCRIBED: 'bg-background-dark',
    LIMITED_SHARES: 'bg-logo-mint text-text-default',
    COMING_SOON: 'bg-logo-turq text-text-default',
    NONE: ''
  }

  const isFullySubscribed = p?.promoFlag === 'FULLY_SUBSCRIBED'
  const displayComingSoon = (p?.promoFlag === 'COMING_SOON' && p?.sharePrice === undefined)
  const showSharePrice = p?.sharePrice && !isFullySubscribed

  return (
    <div className='relative' data-testid={p.name}>
      {p.promoFlag !== 'NONE' && <span className={`text-text-on-dark-bg flag ${promoStyle[p.promoFlag]}`} data-testid={isFullySubscribed ? 'sold-out' : ''}>{transformPromoFlag(p.promoFlag)}</span> }
      <div className='border border-stroke-on-white-light box bg-background-default product-card' key={p.urlName} onClick ={() => showProductDetails(p.urlName, p.name, p.sharePrice)} role='button' tabIndex={0} aria-label={p.name}>
        <div className='text-text-default product-title'>
          {width <= MOBILE_WIDTH_SIZE && <span className='heading_8_eyebrow text-text-secondary product-title__sector'>{p.vertical}</span>}
          {p.logoUrl && <img className='product-title__icon' alt={p.name} src={p.logoUrl} />}
        </div>
        {width >= MOBILE_WIDTH_SIZE && <div className='border-x border-x-stroke-on-white-light product-description'>
          <span className='heading_8_eyebrow text-text-secondary product-description__sector'>{p.vertical}</span>
          <span className='text-text-default'>{truncate(p.description, 110)}</span>
        </div>}
        <div className='product-share-container hover:bg-background-mid-gray'>
          {isFullySubscribed ? <span className='product-share-container__share-price-copy text-text-secondary medium'>{'Unavailable'}</span> : <span className={`body_1 product-share-container__share-price${displayComingSoon ? '-copy medium' : 'text-logo-cobalt'}`}>{p?.sharePrice ? formatDecimal(p?.sharePrice) : 'Coming Soon'}</span>}
          {showSharePrice ? <span className='product-share-container__share-price-copy text-text-secondary'>Unit Price</span> : ''}
        </div>
      </div>
    </div>
  )
}

export default ProductCard
