const CircleLoader = (props) => {
  return (
    <div className='loader-component' {...props}>
      <div className='circle-spinner'>
        <div className='bg-logo-cobalt/90 double-bounce1' style={{ ...props.loaderStyle }} />
        <div className='bg-logo-cobalt/20 double-bounce2' style={{ ...props.loaderStyle }} />
      </div>
    </div>
  )
}

export default CircleLoader
