import USDocumentUploadItem from './USDocumentUploadItem'
import USDocumentTypesModal from './USDocumentTypesModal'
import USSuccessModal from './USSuccessModal'
import { ConditionalRender } from 'components'
import { Mixpanel } from 'utils'
import { images } from 'assets'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selfAccreditationOptions } from '../../data'

import {
  deleteDocument,
  getInvestorStatus,
  requestReview,
  saveSelfAccreditation
} from 'slices/investorStatusSlice'
import ButtonGroup from '../ButtonGroup'

const USDocumentUpload = ({ dropDownList, intialDocsToUpload }) => {
  const dispatch = useDispatch()
  const { documents, loading, accreditedStatus, accreditationRegion, accreditedCountry, selfAccreditation } = useSelector(
    (state) => state.investorStatusSlice
  )
  const [documentsToUpload, setDocumentsToUpload] = useState([])
  const [uploadedDocuments, setUploadedDocuments] = useState([])
  const [showDocumentTypeModal, setShowDocumentTypeModal] = useState(false)
  const [showsuccessModal, setShowsuccessModal] = useState(false)
  const [documentLoading, setDocumentLoading] = useState(false) // this state to avoid jump on the ux while where getting list of documents after upload
  useEffect(() => {
    if (documents?.length === 0) {
      setDocumentsToUpload(intialDocsToUpload)
    }
  }, [documents])

  const addNewUploadComponent = () => {
    const newDocument = documentsToUpload
    newDocument?.push({
      id: documentsToUpload.length + 1,
      name: 'Select Document Type'
    })
    setDocumentsToUpload([...newDocument])
  }

  const submitDocuments = () => {
    dispatch(requestReview()).then(({ meta }) => {
      if (meta.requestStatus === 'fulfilled') {
        Mixpanel.track('Onboarding Click Submit Verification', { 'Accreditation Method': 'Manual', 'Accredited Country': accreditedCountry, 'Accreditation Status': selfAccreditationOptions[selfAccreditation] })
        if (accreditationRegion === 'NONE') {
          dispatch(
            saveSelfAccreditation({
              selfAccreditation: 'FOREIGN_INVESTOR'
            }))
        }
        setUploadedDocuments([])
        setShowsuccessModal(true)
        dispatch(getInvestorStatus('noRefresh'))
      }
    })
  }

  const handleDelete = (id) => {
    dispatch(deleteDocument(id)).then(() => {
      const filtered = uploadedDocuments.filter((f) => f !== id)
      setUploadedDocuments([...filtered])
      dispatch(getInvestorStatus('noRefresh'))
    })
  }

  const hideSuccessModal = () => {
    setShowsuccessModal(false)
  }

  const hideDocumentTypeModal = () => {
    setShowDocumentTypeModal(false)
  }

  const onSuccessUpload = (uploadedDocId, id) => {
    Mixpanel.track('Onboarding View Success Document Upload')
    setUploadedDocuments([...uploadedDocuments, uploadedDocId])
    const filtered = documentsToUpload.filter((f) => f.id !== id)
    setDocumentsToUpload([...filtered])
    dispatch(getInvestorStatus('noRefresh')).then(() => {
      setDocumentLoading(false)
    })
  }

  const openDocumentTypeModal = () => {
    Mixpanel.track('Click What Can I Use on Accreditation Page')
    setShowDocumentTypeModal(true)
  }

  return (
    <div className='us-documents-container verification-section'>
      <div className='us-documents-header'>
        <div className='large_1'>Upload Documents</div>
        {accreditationRegion !== 'NONE' && <a className='link text-text-hyperlink' onClick={openDocumentTypeModal}>
          What can I use?
        </a>}
      </div>
      {documents?.length > 0 &&
        documents.map((doc, id) => (
          <USDocumentUploadItem
            key={`document-uploaded-${id}`}
            allowDelete={
              accreditedStatus === 'NOT_VERIFIED' ||
              accreditedStatus === 'FAILED'
            }
            document={doc}
            onDelete={() => handleDelete(doc.id)}
          />
        ))}
      {documentLoading && <div className='uploading'></div>}
      {documentsToUpload?.length > 0 &&
        documentsToUpload.map((el) => (
          <USDocumentUploadItem
            key={`document-upload-${el.id}`}
            dropDownLabel={el.name}
            dropDownList={dropDownList}
            showDocType={true}
            onSuccess={(doc) => {
              setDocumentLoading(true)
              onSuccessUpload(doc, el.id)
            }}
          />
        ))}
      <a
        className='link text-text-hyperlink add-document-link'
        onClick={addNewUploadComponent}
      >
        <img alt='Add another file' src={images.addMore} />
        Add another file
      </a>
      <ButtonGroup loading={loading} disabled={loading || uploadedDocuments.length === 0} onClick={submitDocuments} />
      <ConditionalRender isVisible={showDocumentTypeModal}>
        <USDocumentTypesModal hideModal={hideDocumentTypeModal} />
      </ConditionalRender>
      <ConditionalRender isVisible={showsuccessModal}>
        <USSuccessModal hideModal={hideSuccessModal} />
      </ConditionalRender>
    </div>
  )
}

export default USDocumentUpload
