import useWindowSize from 'hooks/useWindowSize'
import MobileBottomPopup from './MobileBottomPopup'

const BottomPopupDrawer = ({ isOpen, onClose, children }) => {
  if (!isOpen) return null // Don't render the modal if it's not open
  const { width } = useWindowSize()

  if (width <= 480) {
    return (
      <MobileBottomPopup isOpen={isOpen} onClose={onClose}>
        {children}
      </MobileBottomPopup>
    )
  }

  return (
    <>
      {/* Dark overlay */}
      <div  className='fixed inset-0 z-[999] bg-black bg-opacity-50' onClick={onClose} role='presentation' aria-hidden='true' />
      {/* BottomPopupDrawer container */}
      <div  className='fixed inset-0 z-[999] flex items-center justify-center'>
        <div className='p-6 bg-white rounded-lg shadow-ambient-shadow w-[480px]'>
          {children}
        </div>
      </div>
    </>
  )
}

export default BottomPopupDrawer
