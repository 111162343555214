import { images } from 'assets'
import { useState, useEffect } from 'react'

const MobileBottomPopup = ({ isOpen, onClose, children }) => {
  const [isClosing, setIsClosing] = useState(false)
  const [startY, setStartY] = useState(0)  // Starting touch position
  const [isDragging, setIsDragging] = useState(false)  // Is the user actively dragging
  const [translateY, setTranslateY] = useState(0)  // To track the drag movement

  useEffect(() => {
    if (isOpen) {
      setIsClosing(false)
      setTranslateY(0)  // Reset translateY when opening
      document.body.style.overflow = 'hidden' // Prevent scrolling
    } else {
      document.body.style.overflow = 'auto' // Re-enable scrolling
    }
    return () => {
      document.body.style.overflow = 'auto' // Re-enable scrolling when unmounting
    }
  }, [isOpen])

  // Handle the touch start event
  const handleTouchStart = (e) => {
    const touchStart = e.touches[0].clientY  // Get the start touch position
    setStartY(touchStart)
    setIsDragging(true)
  }

  // Handle the touch move event
  const handleTouchMove = (e) => {
    if (!isDragging) return

    const touchMove = e.touches[0].clientY
    const deltaY = touchMove - startY

    // If the user is dragging down, move the slide-up down (closer to 100% of screen height)
    if (deltaY > 5) {
      setTranslateY(Math.min(deltaY, window.innerHeight)) // Limit how far it can be dragged
    }
  }

  // Handle the touch end event
  const handleTouchEnd = () => {
    if (!isDragging) return

    // If the user dragged enough to close the component (past 50% of the screen height), trigger close
    if (translateY > window.innerHeight / 2) {
      setIsClosing(true)
      setTimeout(() => {
        onClose() // Close after animation
      }, 300) // Match the animation duration to remove the menu after the animation
    } else {
      setTranslateY(0) // Reset the position if not dragged enough
    }

    setIsDragging(false)
    setStartY(0)
  }

  // Handle overlay click to close the MobileBottomPopup
  const handleOverlayClick = () => {
    setIsClosing(true)
    setTimeout(() => {
      onClose() // Close the slide-up after animation
    }, 300) // Match the animation duration to remove the menu after the animation
  }

  return (
    <>
      {/* Overlay background */}
      {isOpen && <div className="fixed inset-0 z-[999] bg-black bg-opacity-50" onClick={handleOverlayClick} />}

      {/* Slide-up component */}
      <div
        className={`fixed bottom-0 left-0 rounded-t-2xl w-full p-4 bg-white z-[999] slide-up transition-all duration-300 ease-in-out transform ${
          isClosing ? 'translate-y-full' : isOpen ? `translate-y-${translateY}px` : 'translate-y-full'
        }`}
        style={{
          transition: 'transform 0.3s ease-in-out',
          transform: `translateY(${isClosing ? window.innerHeight : isOpen ? translateY : window.innerHeight}px)`, // Update position based on drag
        }}
      >
        <div onTouchStart={handleTouchStart} onTouchMove={handleTouchMove} onTouchEnd={handleTouchEnd} className='h-5'>
          <img src={images['grabber-indicator']} width={40} height={3} className="m-auto" alt="Grabber" />
        </div>
        {children}
      </div>
    </>
  )
}

export default MobileBottomPopup
