import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useQuery } from 'hooks/useQuery'
import { authenticateAIUser } from 'slices/commonSlice'
import useConfig from '../../config/useConfig.js'

const redirectDomainMap = {
  'app.linqto.com': 'https://www.linqto.ai',
  'app.beta.linqto.com': 'https://beta.linqto.ai'
}
const AiAuth = () => {
  const dispatch = useDispatch()
  const query = useQuery()
  const { configuration } = useConfig()
  const state = query.get('state')
  useEffect(() => {
    const aiSignIn = async () => {
      const { payload, meta } = await dispatch(authenticateAIUser())
      if (meta.requestStatus === 'fulfilled') {
        let leslieDomain = redirectDomainMap[window.location.hostname] || 'https://dev.linqto.ai'
        const aiConfig = configuration.getAiConfig()
        if(aiConfig.isValid()){
          leslieDomain = aiConfig.getRedirectUrl()
        }
        window.location.assign(`${leslieDomain}?state=` + state + '&tempCode=' + payload)
      }
    }
    if (state) {
      aiSignIn()
    }
  }, [state])
  
  return (
    <div className='min-h-[80vh] py-16 px-40'>
      <p>redirecting...</p>
    </div>
  )
}

export default AiAuth
