import { useState, useEffect } from 'react'

/**
 * This is a debounce hook to be used 
 * for a search api that is called frequently 
 * and needs to be debounced.
 * Created for use with react query
 * @param {*} value - value to be debounced
 * @param {*} delay - delay in milliseconds
 * @returns 
 */
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value)

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value)
    }, delay)

    return () => {
      clearTimeout(handler)
    }
  }, [value, delay])

  return debouncedValue
}
export default useDebounce