import { useEffect, useState } from 'react'
import { getDrawerContent, useLDFlags } from 'utils'
import useContentful from './useContentful'
import { contentfulContentIds } from 'components/data'

const useCampaignVariation = (flag) => {
  const { campaignShowNewCompanyDatabricks } = useLDFlags([flag])
  const [showCampaign, setShowCampaign] = useState(false)
  const [contentfulDrawerData, setContentfulDrawerData] = useState(null)
  const { content: contentfulData } = useContentful(contentfulContentIds.drawerId)
  const { content: userStageData } = useContentful(contentfulContentIds.userStageId)
  const userStage = userStageData?.fields?.stage || ''

  useEffect(() => {
    // TODO: update the conditon to either show drawer or carousel AND check the local storage key
    setShowCampaign(campaignShowNewCompanyDatabricks === 'drawer' && !localStorage.getItem(flag))
    
    if (!contentfulData) return

    const { metadata, fields } = contentfulData || {}
    if (!metadata || !fields) return

    const { tags } = metadata
    const isUserStageMatch = tags.some(tag => tag.sys.id.includes(userStage))

    if (isUserStageMatch && fields) {
      setContentfulDrawerData(getDrawerContent(fields))
    }
  }, [campaignShowNewCompanyDatabricks, contentfulData, userStage])

  const handleClose = () => {
    setShowCampaign(false)
    localStorage.setItem(flag, 'dismissed')
  }

  return [showCampaign, handleClose, contentfulDrawerData]
}

export default useCampaignVariation
