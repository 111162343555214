import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ConditionalRender, PageLoading, Button } from 'components'
import { images } from 'assets'
import { useQuery } from 'hooks/useQuery'
import FinancialAdvisor from './partials/FinancialAdvisor'
import CRDRevamp from './partials/CRDRevamp'
import ProgressBarRevamp from './partials/ProgressBarRevamp'
import { Mixpanel, useLDFlags, seoTitleTemplate } from 'utils'
import UnderReviewStatusView from './partials/UnderReviewStatusView'
import EUResidentsSection from './partials/EUResidentsSection'
import UKResidentsSection from './partials/UKResidentsSection'
import NoRegCountriesSection from './partials/NoRegCountriesSection'
import AccreditationVerified from './partials/AccreditationVerified'
import ManualUploadDocument from './partials/ManualUploadDocument'
import SeoMeta from 'components/Global/SeoMeta'
import PlaidBankAccountBanner from './partials/PlaidBankAccountBanner'
import USSuccessModal from './partials/USSuccessModal'
import FinanciallySophisticatedTestRevamp from './FinanciallySophisticated/FinanciallySophisticatedTestRevamp'
import { accreditationOptions, status } from '../data'
import AccreditationTypesModal from './partials/AccreditationTypesModal'
import PlaidDocumentIncomeLink from './partials/PlaidDocumentIncomeLink'

import {
  getInvestorStatus,
  getPlaidIncomeAccreditaiton,
  saveSelfAccreditation,
  submitAssetsAccounts
} from 'slices/investorStatusSlice'
import { useHistory } from 'react-router-dom'
import PlaidAssetsRevamp from './partials/PlaidAssetsRevamp'
import EditProfileDetailsButton from './partials/EditProfileDetailsButton'
import NewProgressBar from 'components/Profile/partials/NewProgressBar'
import PlaidIncomeLink from './partials/PlaidIncomeLink'

const InvestorStatus = () => {
  const query = useQuery()
  const dispatch = useDispatch()
  const history = useHistory()
  const {
    accreditationIncomeFlow,
    plaidAccreditation,
    suitabilityQuestions,
    restrictedAccreditationOptions
  } = useLDFlags([
    'accreditationIncomeFlow',
    'plaidAccreditation',
    'suitabilityQuestions',
    'restrictedAccreditationOptions'
  ])
  const { featureFlags } = useSelector(state => state.userSlice)
  const {
    accreditationRegion,
    accreditedCountrySource,
    accreditedCountry,
    accreditedStatus,
    documentAccreditationIsProcessing,
    isProcessing,
    wasAccredited,
    assetsAccounts,
    hasTakenAccreditationQuiz,
    isRetailPurchaser
  } = useSelector(state => state.investorStatusSlice)

  const [accreditationType, setAccreditationType] = useState(query.get('acc-type') || null)
  const [accreditationSubType, setAccreditationSubType] = useState(
    query.get('acc-sub-type') || null
  )
  const [submitPlaidBankAccounts, setSubmitPlaidBankAccounts] = useState(false)
  const [accreditationTypeTitle, setAccreditationTypeTitle] = useState(
    'Select an accreditation method'
  )
  const [navType, setNavType] = useState(query.get('acc-sub-type') || 'nav')
  const [progressBarStep, setProgressBarStep] = useState(3)
  const [progressBarType, setProgressBarType] = useState('half')
  const [pageLoading, setPageLoading] = useState(true)
  const [showPlaidDocumentSuccessModal, setShowPlaidDocumentSuccessModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showAccreditationTypesModal, setShowAccreditationTypesModal] = useState(false)
  const [showPlaidIncomeFailure, setShowPlaidIncomeFailure] = useState(false)

  const { AccreditationThroughTestAllowed } = featureFlags || {}

  const passQuiz = hasTakenAccreditationQuiz && isRetailPurchaser

  const allowedCountries = {
    'United States': true,
    'United Kingdom':
      restrictedAccreditationOptions === true || restrictedAccreditationOptions === false
        ? restrictedAccreditationOptions
        : false,
    Canada:
      restrictedAccreditationOptions === true || restrictedAccreditationOptions === false
        ? restrictedAccreditationOptions
        : false
  }

  const plaidFlowAllowed = !!allowedCountries[accreditedCountry]

  const accreditationNavRegions = {
    USA: {
      ACTION: 'NAVIGATE',
      OPTIONS: ['ANNUAL_INCOME', 'NET_WORTH', 'JOINT_INCOME', 'SECURITIES_LICENSE']
    },
    NONE: {
      ACTION: 'NAVIGATE',
      OPTIONS:
        accreditedCountry === 'United States'
          ? ['UPLOAD_DOCUMENT', 'EMAIL_FINANCIAL_ADVISOR']
          : ['UPLOAD_DOCUMENT']
    },
    RESTRICTED: {
      ACTION: 'NAVIGATE',
      OPTIONS: ['ANNUAL_INCOME', 'NET_WORTH', 'JOINT_INCOME', 'SECURITIES_LICENSE']
    },
    ANNUAL_INCOME: {
      ACTION: 'NAVIGATE',
      OPTIONS:
        plaidFlowAllowed && plaidAccreditation
          ? [
              'PLAID_INCOME',
              plaidFlowAllowed && accreditationIncomeFlow
                ? 'PLAID_UPLOAD_DOCUMENT'
                : 'UPLOAD_DOCUMENT'
            ].concat(accreditedCountry === 'United States' ? ['EMAIL_FINANCIAL_ADVISOR'] : [])
          : [
              plaidFlowAllowed && accreditationIncomeFlow
                ? 'PLAID_UPLOAD_DOCUMENT'
                : 'UPLOAD_DOCUMENT'
            ].concat(accreditedCountry === 'United States' ? ['EMAIL_FINANCIAL_ADVISOR'] : [])
    },
    NET_WORTH: {
      ACTION: 'NAVIGATE',
      OPTIONS:
        plaidFlowAllowed && plaidAccreditation
          ? ['PLAID_ASSETS', 'NET_UPLOAD_DOCUMENT'].concat(
              accreditedCountry === 'United States' ? ['EMAIL_FINANCIAL_ADVISOR'] : []
            )
          : ['NET_UPLOAD_DOCUMENT'].concat(
              accreditedCountry === 'United States' ? ['EMAIL_FINANCIAL_ADVISOR'] : []
            )
    },
    JOINT_INCOME: {
      ACTION: 'NAVIGATE',
      OPTIONS:
        accreditedCountry === 'United States'
          ? ['UPLOAD_DOCUMENT', 'EMAIL_FINANCIAL_ADVISOR']
          : ['UPLOAD_DOCUMENT']
    }
  }

  const getInvestorStatusPage = async () => {
    const res = await dispatch(getInvestorStatus())
    const { meta, payload } = res
    if (meta.requestStatus === 'fulfilled') {
      if (!payload?.hasKyc && payload?.isSignedIn) {
        history.push('/profile')
      } else {
        Mixpanel.track('Onboarding View Investor Status', {
          'Accredited Country': payload?.accreditedCountry
        })
      }
      if (
        (payload?.accreditedStatus === 'UNDER_REVIEW' ||
          payload?.documentAccreditationIsProcessing ||
          payload?.isProcessing) &&
        payload?.selfAccreditation
      ) {
        setAccreditationType(payload?.selfAccreditation)
        if (payload?.selfAccreditation === 'SECURITIES_LICENSE') {
          setAccreditationSubType(null)
        } else if (payload?.assetsAccounts?.length > 0) {
          setAccreditationSubType('PLAID_ASSETS')
        } else if (payload?.hasRequestedFinancialAdvisor) {
          setAccreditationSubType('EMAIL_FINANCIAL_ADVISOR')
        } else {
          setAccreditationSubType('UPLOAD_DOCUMENT')
        }
      }
      if (window.ReactNativeWebView) {
        window.ReactNativeWebView.postMessage('pageLoaded')
      }
      setPageLoading(false)
    }
  }

  useEffect(() => {
    getInvestorStatusPage()
  }, [])

  useEffect(() => {
    const messageListener = window.addEventListener('message', async nativeEvent => {
      try {
        const data = JSON.parse(nativeEvent?.data)
        if (data?.type === 'plaid success') {
          dispatch(getInvestorStatus('noRefresh'))
        } else if (data?.type === 'plaid income success') {
          const { payload } = await dispatch(getPlaidIncomeAccreditaiton())
          if (!payload) {
            Mixpanel.track('Onboarding Failed Accreditation', {
              'Accredited Status': '$200k Individual Income for 2 Years',
              'Accreditaiton Method': 'Plaid'
            })
          }
          dispatch(getInvestorStatus('noRefresh'))
        }
      } catch (err) {
        console.log('Error parsing message from native', err)
      }
    })
    return messageListener
  }, [])

  const getProgressBarStep = () => {
    if (accreditedStatus === 'UNDER_REVIEW' || documentAccreditationIsProcessing || isProcessing)
      return 4
    if (accreditationSubType) return 4
    if (accreditationType) return 3
    if (accreditedStatus === 'ACCREDITED') return 4
    return 3
  }
  useEffect(() => {
    updateProgressBar()
  }, [
    accreditationType,
    accreditationSubType,
    accreditationRegion,
    accreditedStatus,
    documentAccreditationIsProcessing,
    isProcessing
  ])

  const updateProgressBar = (selfAttestationSubmitSuccess = false) => {
    if (selfAttestationSubmitSuccess) {
      setProgressBarStep(4)
      setProgressBarType('full')
      return
    }
    setNavType(
      accreditationSubType ||
        (accreditationRegion === 'USA' ||
        accreditationRegion === 'NONE' ||
        restrictedAccreditationOptions
          ? 'nav'
          : '')
    )
    setAccreditationTypeTitle(
      accreditationType
        ? `Verify your ${accreditationOptions[accreditationType]?.TITLE}`
        : 'Select an accreditation method'
    )
    setProgressBarStep(getProgressBarStep())
    setProgressBarType(
      accreditedStatus === 'UNDER_REVIEW' || documentAccreditationIsProcessing || isProcessing
        ? 'half'
        : accreditationSubType
          ? 'half'
          : accreditationType
            ? 'full'
            : accreditedStatus === 'ACCREDITED'
              ? 'full'
              : 'half'
    )
  }

  useEffect(() => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage(
        `accreditationType:${progressBarStep}:${accreditationType}`
      )
    }
  }, [progressBarStep, accreditationType])

  window.onpopstate = () => {
    if (accreditationSubType) {
      setAccreditationSubType(null)
    } else if (accreditationType) {
      setAccreditationType(null)
    }
  }

  const tagStyle = {
    'quiz-pass': 'text-text-success bg-background-default',
    fast: 'bg-fill-cobalt-primary text-text-on-dark-bg',
    fastest: 'bg-fill-cobalt-primary text-text-on-dark-bg',
    'approval-s': 'bg-fill-cobalt-primary text-text-on-dark-bg',
    plaid: 'bg-fill-cobalt-primary text-text-default'
  }
  const AccreditationNavItem = ({ title, copy, icon, onClick, tags, testId, clickable = true }) => (
    <div
      className={`acc-nav-item border border-stroke-on-white-light ${!clickable ? 'not-clickable' : ''}`}
      onClick={() => (clickable ? onClick() : null)}
      data-testid={testId}
      role='button'
      aria-label={title}
      tabIndex={0}
    >
      <img src={images[icon]} alt={title} />
      <div className='acc-nav-copy'>
        <div className='medium_2 text-text-default'>{title}</div>
        <div className='medium_1 text-text-secondary'>{copy}</div>
        {tags?.length > 0 && (
          <div className='acc-nav-tags'>
            {tags?.map((tag, i) => {
              return (
                <div
                  key={i}
                  className={`bg-background-mid-gray text-text-default acc-nav-tag ${tag?.type} ${tagStyle[tag?.type]}`}
                >
                  {tag?.type === 'fast' || tag?.type === 'quiz-pass' || tag?.type === 'fastest' ? (
                    <img src={images.fast} alt={tag?.type} />
                  ) : (
                    ''
                  )}
                  {tag?.copy}
                </div>
              )
            })}
          </div>
        )}
      </div>
      {clickable && (
        <img src={images.arrow_forward_ios} alt={`Go to ${title}`} className='acc-nav-arrow' />
      )}
    </div>
  )

  const onAccreditationNavClicked = async (url, action) => {
    if (action === 'NAVIGATE') {
      setAccreditationType(url)
      const res = await dispatch(saveSelfAccreditation({ selfAccreditation: url }))
      const { meta } = res
      if (meta.requestStatus === 'fulfilled') {
        window.history.pushState(null, null, `/investor-status?acc-type=${url}`)
        setNavType('nav')
        setProgressBarStep(2)
        setProgressBarType('full')
        setAccreditationTypeTitle(`Verify your ${accreditationOptions[url]?.TITLE}`)
      }
    }
    if (action === 'SUB_NAVIGATE') {
      setNavType(url)
      setProgressBarStep(3)
      setProgressBarType('full')
      setAccreditationSubType(url)
      window.history.pushState(
        null,
        null,
        `/investor-status?acc-type=${accreditationType}&acc-sub-type=${url}`
      )
    }
  }

  const handleSubmitPlaid = async () => {
    setLoading(true)
    const res = await dispatch(submitAssetsAccounts())
    const { meta } = res
    if (meta.requestStatus === 'fulfilled') {
      setLoading(false)
      dispatch(getInvestorStatus())
      setSubmitPlaidBankAccounts(true)
    }
  }

  const renderInvestorStatusTitle = () => {
    if (window.ReactNativeWebView) {
      return null
    }
    return (
      <div className='giant_2' id='investor-status-title'>
        Investor Profile
      </div>
    )
  }

  const renderEditProilfeDetailsButton = () => {
    if (
      accreditationRegion === 'USA' ||
      accreditationRegion === 'NONE' ||
      restrictedAccreditationOptions
    ) {
      if (navType === 'nav') {
        if (progressBarStep === 3 && !accreditationType) {
          return <EditProfileDetailsButton />
        }
      }
    }
    return null
  }

  const renderAccreditationComponent = () => {
    if (
      accreditationSubType &&
      (accreditationRegion === 'USA' ||
        accreditationRegion === 'NONE' ||
        restrictedAccreditationOptions)
    ) {
      switch (accreditationSubType) {
        case 'EMAIL_FINANCIAL_ADVISOR':
          return <FinancialAdvisor />
        case 'UPLOAD_DOCUMENT':
        case 'NET_UPLOAD_DOCUMENT':
          return <ManualUploadDocument type={accreditationType} />
        case 'SECURITIES_LICENSE':
          return <CRDRevamp />
        case 'PLAID_ASSETS':
          return <PlaidAssetsRevamp handleSubmitPlaid={handleSubmitPlaid} loading={loading} />
        case 'FINANCIAL_SOPHISTICATION':
          return (
            <FinanciallySophisticatedTestRevamp
              setShowAccreditationTypesModal={setShowAccreditationTypesModal}
            />
          )
        default:
          return null
      }
    } else {
      if (restrictedAccreditationOptions === false) {
        switch (accreditationRegion) {
          case 'EU_SELF':
            return <EUResidentsSection />
          case 'UK_SELF':
            return <UKResidentsSection />
          case 'OTHER_NOT_REGULATED':
            return <NoRegCountriesSection />
          default:
            return null
        }
      }
    }
  }

  const handleBannerClick = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToInvest')
    } else {
      history.push('/products')
    }
  }

  const redirectToContact = () => {
    if (window.ReactNativeWebView) {
      window.ReactNativeWebView.postMessage('navigateToContact')
    } else {
      Mixpanel.track('Onboarding Click Contact Support')
      history.push('/contact')
    }
  }

  const handleFSNavItem = () => {
    if (!passQuiz) {
      onAccreditationNavClicked(
        'FINANCIAL_SOPHISTICATION',
        accreditationOptions.FINANCIAL_SOPHISTICATION.ACTION
      )
    }
  }

  if (pageLoading || !accreditedStatus) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Accredited Investor')} />
        <PageLoading />
      </>
    )
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Accredited Investor')} />
      <div className='page-container investor-status-container'>
        <div className='inner-container'>
        <ConditionalRender
            isVisible={
              accreditedStatus === 'UNDER_REVIEW' ||
              documentAccreditationIsProcessing ||
              isProcessing
            }
          >
          <div className='flex items-center mt-4 w-full h-full rounded-lg border bg-fill-light-cobalt-tiertary border-logo-cobalt max-w-[358px] md:max-w-full px-4 py-[14px] mb-14'>
            <img src={images.info} alt='info' className='mr-2' />
            <p className='body_1'>
              Thank you for submitting your accreditation documents! Our team is reviewing them and
              will update you within 4 weeks.
            </p>
          </div>
         </ConditionalRender>
          {showPlaidIncomeFailure && (
            <PlaidBankAccountBanner text='We were unable to confirm your $200k accreditation status via your chosen bank. Please use a different method.' />
          )}
          {showAccreditationTypesModal && (
            <AccreditationTypesModal
              setShowAccreditationTypesModal={setShowAccreditationTypesModal}
              status={status}
            />
          )}
          {showPlaidDocumentSuccessModal && (
            <USSuccessModal hideModal={() => setShowPlaidDocumentSuccessModal(false)} />
          )}
          {accreditationSubType === 'PLAID_ASSETS' &&
            wasAccredited === false &&
            assetsAccounts?.length > 0 && (
              <PlaidBankAccountBanner text='We were unable to confirm your $200k accreditation status via your chosen bank. Please use a different method.' />
            )}
          {submitPlaidBankAccounts && (
            <PlaidBankAccountBanner
              text='Your accreditation verification has been submitted. We will send you an email update in a few minutes.'
              link='Explore Investments'
              onClick={handleBannerClick}
            />
          )}
          {documentAccreditationIsProcessing && (
            <PlaidBankAccountBanner
              headline='Documents Under Review.'
              text='We will notify you via email once your accreditation verification is complete.'
              link='Explore Investments'
              onClick={handleBannerClick}
            />
          )}
          {renderInvestorStatusTitle()}
          {suitabilityQuestions ? (
            <NewProgressBar activeStep={8} />
          ) : (
            <ProgressBarRevamp step={progressBarStep} type={progressBarType} />
          )}
          <ConditionalRender
            isVisible={
              (accreditedStatus === 'NOT_VERIFIED' || accreditedStatus === 'FAILED') &&
              !documentAccreditationIsProcessing &&
              !isProcessing
            }
          >
            <ConditionalRender isVisible={navType === 'nav'}>
              <ConditionalRender isVisible={!accreditationType} className='verify-acc'>
                <div className='large_1'>Verify Accreditation</div>
                <div className='medium_1 space-above-sm'>
                  Regulations require you to be accredited to invest on Linqto.{' '}
                  <Button
                    className='underline medium_2 text-text-hyperlink'
                    onClick={() => setShowAccreditationTypesModal(true)}
                    ariaLabel='Learn more about regulations require you to be accredited to invest on Linqto'
                  >
                    Learn more
                  </Button>{' '}
                </div>
              </ConditionalRender>
              <ConditionalRender
                isVisible={
                  AccreditationThroughTestAllowed && (!hasTakenAccreditationQuiz || passQuiz)
                }
                className='verify-acc'
              >
                <div className='large_1 acc-nav-title'>Non-Accredited? (Limited Experience)</div>
                <AccreditationNavItem
                  title={accreditationOptions.FINANCIAL_SOPHISTICATION.TITLE}
                  copy={!passQuiz ? accreditationOptions.FINANCIAL_SOPHISTICATION.COPY : ''}
                  icon={accreditationOptions.FINANCIAL_SOPHISTICATION.ICON}
                  tags={passQuiz ? [{ type: 'quiz-pass', copy: 'Passed Test' }] : []}
                  onClick={() => handleFSNavItem()}
                  clickable={!passQuiz}
                  testId='acc-nav-item-fs'
                />
              </ConditionalRender>
              {(restrictedAccreditationOptions
                ? accreditationNavRegions[accreditationType || 'RESTRICTED']
                : accreditationNavRegions[accreditationType || accreditationRegion]
              )?.OPTIONS && <div className='large_1 acc-nav-title'>{accreditationTypeTitle}:</div>}
              <div className='mb-12'>
                {(restrictedAccreditationOptions
                  ? accreditationNavRegions[accreditationType || 'RESTRICTED']
                  : accreditationNavRegions[accreditationType || accreditationRegion]
                )?.OPTIONS?.map((item, i) => {
                  if (item === 'PLAID_UPLOAD_DOCUMENT') {
                    return (
                      <PlaidDocumentIncomeLink
                        key={i}
                        title={accreditationOptions[item]?.TITLE}
                        copy={accreditationOptions[item]?.COPY}
                        icon={accreditationOptions[item]?.ICON}
                        tags={accreditationOptions[item]?.TAGS}
                        testId={`acc-nav-item-${i + 1}`}
                        setShowPlaidDocumentSuccessModal={setShowPlaidDocumentSuccessModal}
                      />
                    )
                  }
                  if (item === 'PLAID_INCOME') {
                    return (
                      <PlaidIncomeLink
                        key={i}
                        title={accreditationOptions[item]?.TITLE}
                        copy={accreditationOptions[item]?.COPY}
                        icon={accreditationOptions[item]?.ICON}
                        tags={accreditationOptions[item]?.TAGS}
                        testId={`acc-nav-item-${i + 1}`}
                        setShowPlaidIncomeFailure={setShowPlaidIncomeFailure}
                      />
                    )
                  }
                  return (
                    <AccreditationNavItem
                      key={i}
                      title={accreditationOptions[item]?.TITLE}
                      copy={accreditationOptions[item]?.COPY}
                      icon={accreditationOptions[item]?.ICON}
                      tags={
                        plaidFlowAllowed && (accreditationIncomeFlow || plaidAccreditation)
                          ? accreditationOptions[item]?.TAGS
                          : ''
                      }
                      onClick={() =>
                        onAccreditationNavClicked(item, accreditationOptions[item]?.ACTION)
                      }
                      testId={`acc-nav-item-${i + 1}`}
                    />
                  )
                })}
              </div>
            </ConditionalRender>
            {renderAccreditationComponent()}
            {renderEditProilfeDetailsButton()}
          </ConditionalRender>
          <ConditionalRender
            isVisible={
              accreditedStatus === 'UNDER_REVIEW' ||
              documentAccreditationIsProcessing ||
              isProcessing
            }
          >
            <UnderReviewStatusView
              accreditationTypeData={accreditationOptions[accreditationType]}
              accreditationSubTypeData={accreditationOptions[accreditationSubType]}
            />
          </ConditionalRender>
          <ConditionalRender isVisible={accreditedStatus === 'ACCREDITED'}>
            <AccreditationVerified />
          </ConditionalRender>
          <ConditionalRender
            isVisible={
              accreditationSubType !== 'FINANCIAL_SOPHISTICATION' &&
              restrictedAccreditationOptions === false
            }
          >
            <div className='medium_1 space-above-lg'>
              Terms of accreditation are determined by your country of{' '}
              {accreditedCountrySource === 'RESIDENCE' ? 'residence' : 'citizenship'}:{' '}
              <span className='medium_2'>{accreditedCountry}</span>
            </div>
          </ConditionalRender>
          <span className='flex mt-10 text-text-default medium_1'>
            Need help?&nbsp;
            <Button className='text-text-hyperlink inline-text-link' onClick={redirectToContact}>
              Contact Us
            </Button>
          </span>
        </div>
      </div>
    </>
  )
}

export default InvestorStatus
