import TextInput from 'components/Global/Inputs/TextInput'
import PasswordInput from 'components/Global/Inputs/PasswordInput'
import { useFormContext } from 'react-hook-form'
import Checkbox from 'components/Global/Inputs/Checkbox'
import Button from 'components/Global/Button'
import AccreditationOptions from './AccreditationOptions'
import SignupTerms from './SignupTerms'
import { useSelector } from 'react-redux'
import EmailInput from 'components/Global/Inputs/EmailInput'
import SignUpOAuthInfo from './SignUpOAuthInfo'
import SignInLink from './SignInLink'
import { images } from 'assets'
import useWindowSize from 'hooks/useWindowSize'
import { MOBILE_WIDTH_SIZE } from 'utils'

const SignUpForm = ({
  onSubmit,
  isFromOrigination,
  setShowModal,
  submitLoading,
  oAuthToken,
  isFromReferFriend = false
}) => {
  const { err, errMsg } = useSelector(
    (state) => state.userSlice
  )
  const { handleSubmit } = useFormContext()
  const showNormalError = err && errMsg !== 'REGISTRATION_EMAIL_ALREADY_ACTIVATED'
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE
  return (
    <>
      <form className='grid-old' style={{marginTop: 0, marginBottom:0}} onSubmit={handleSubmit(onSubmit)} noValidate>
        {!oAuthToken &&
        <>
          <div className='column sixteen eight-mobile'>
            <TextInput
              name='firstName'
              label='First Name'
              ariaLabel='firstName'
              disabled={submitLoading}
            />
          </div>
          <div className='column sixteen eight-mobile'>
            <TextInput
              name='lastName'
              label='Last Name'
              ariaLabel='lastName'
              disabled={submitLoading}
            />
          </div>
          <div className='column sixteen'>
            <EmailInput
              name='email'
              label='Email'
              ariaLabel='email'
              disabled={submitLoading}
              id='signUpEmail'
            />
          </div>
          <div className='column sixteen'>
            <PasswordInput
              name='password'
              label='Password'
              ariaLabel='password-input'
              disabled={submitLoading}
              autoComplete='new-password'
            />
          </div>
        </>
        }
        {oAuthToken &&
          <SignUpOAuthInfo err={err} oAuthType={oAuthToken?.type} />
        }
        <div className='column sixteen'>
          <Checkbox
            label='I would like to receive updates on new investment opportunities and market insights.'
            name='marketingOptIn'
            ariaLabel='marketingOptIn'
            checkboxClass='marketing-checkbox'
          />
        </div>
        {isFromOrigination && (
          <AccreditationOptions setShowModal={setShowModal} />
        )}
        <div className='mt-2 column sixteen'>
          <SignupTerms isFromOrigination={isFromOrigination} setShowModal={setShowModal} />
        </div>
        {errMsg === 'REGISTRATION_EMAIL_ALREADY_ACTIVATED' && <div className='mt-5 column sixteen'>
          <span className='medium_1 text-text-error'>An account already exists using this email address. Please <a className='underline' href='/signin'>sign in</a>.</span>
        </div>}
        {showNormalError && <div className='mt-5 column sixteen'>
          <span className='medium_1 text-text-error'>Something went wrong when trying to retrieve your data from Apple/Google. Please <a className='underline' href='/contact' aria-label='contact us'>contact us</a> or try our normal <a className='underline' href='/signup' aria-label='sign up'>sign up</a>.</span>
        </div>}
        <div className='column sixteen'>
          <div className='flex'>
            <Button
              type='submit'
              loading={submitLoading}
              disabled={submitLoading}
              customClass='full-width'
            >
              {isFromOrigination ? 'Create Account' : err === 500 && oAuthToken ? 'Use Different Email' : 'Continue'}
            </Button>
          </div>
        </div>
        {!oAuthToken && 
          <>
            <div className='column sixteen'>
              <SignInLink />
              <div className='ml-auto mr-auto text-center mt-9 body_1 text-text-secondary'>For Accredited Investors only.</div>
            </div>
          </>}
      </form>
      {isMobile && !isFromReferFriend && <div className='mt-[50px] border-t border-stroke-on-white-light test-form-container bg-background-default'>
          <div className='mt-[50px] text-center'>
            <div className=''>
              <div className='text-center border rounded-xl bg-background-default border-stroke-on-white-light'>
                <h6 className='my-8'>Join our Community</h6>
                <div className='flex flex-col items-center mb-8'>
                  <div className='flex items-center mb-2'>
                    <h1 className='mb-0 giant_1 source-serif-4'>$464.6M</h1>
                    <img alt='blue-arrow-up' src={images['blue-arrow-up']} />
                  </div>
                  <span className='body_1'>Total Investments</span>
                </div>
                <div className='flex flex-col items-center'>
                  <div className='flex items-center mb-2'>
                    <h1 className='mb-0 giant_1 source-serif-4'>126</h1>
                    <img alt='blue-arrow-up' src={images['blue-arrow-up']} />
                  </div>
                  <span className='body_1'>Companies Offered Since Inception</span>
                  <p className='mt-4 small_1 text-text-secondary'>Data as of 3/10/2025</p>
                </div>
                <img alt='wave' src={images.wave} />
              </div>
            </div>
          </div>
        </div>}
    </>
  )
}

export default SignUpForm
