import { formatDecimal } from 'utils'

const UpholdWalletAccountsItem = ({ account }) => {
  return (
    <div className='flex items-center justify-between border-b border-stroke-on-white-light py-4 px-0 ml-7 lg:ml-0'>
      <div className='flex items-center'>
        <img className='w-6 h-6 mr-2' alt='usd' src={account.iconUrl} />
        <span>{account.label}</span>
      </div>
      <div>
        <span>{formatDecimal(account.usdBalance)}</span>
      </div>
    </div>
  )
}

export default UpholdWalletAccountsItem
