import { ConditionalRender } from 'components'
import TextInput from 'components/Global/Inputs/TextInput'
import { useSelector } from 'react-redux'
import { useFormContext } from 'react-hook-form'
import { Mixpanel } from 'utils'
import SummaryFormCTA from './SummaryFormCTA'

const SummaryForm = ({ onSubmit, cancelAddFunds, showOtpRequired, type, hasError = false }) => {
  const { otpRequired, loading } = useSelector(
    (state) => state.walletSlice
  )
  const { handleSubmit, clearErrors } = useFormContext()

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <ConditionalRender
        isVisible={type === 'Uphold' && (otpRequired || showOtpRequired)}
        className='place-order-box__details medium_1 verification add-summary-verification'
      >
        <div className='line'></div>
        <span className='medium_2'>Uphold Wallet 2-Step Verification</span>
        <span className='medium_1'>
          Please enter the code generated by your authenticator app.
        </span>
        <TextInput
          autoComplete='one-time-code'
          maxLength={8}
          name='upholdOTP'
          disabled={loading}
          ariaLabel='Uphold OTP'
          onChange={() => {
            clearErrors('upholdOTP')
          }}
        />
        <span className='medium_1 uphold-support'>
          Problems with the authentication?{' '}
          <a
            onClick={() =>
              Mixpanel.track('Click Get Help on Add Funds Summary Page')
            }
            href='https://support.uphold.com/hc/en-us/sections/360008206291-2-factor-authentication'
            target='_blank'
            rel='noopener noreferrer'
            className='link text-text-hyperlink'
          >
            Get help
          </a>
        </span>
      </ConditionalRender>
      <SummaryFormCTA onSubmit={onSubmit} cancelAddFunds={cancelAddFunds} type={type} hasError={hasError}/>
    </form>
  )
}

export default SummaryForm
