import { formatDecimal } from 'utils'
import { useSelector } from 'react-redux'

const SummaryDetailsPlaid = () => {
  const { amount, fromBankName, fromAccountNumberSafe } = useSelector(
    (state) => state.walletSlice
  )
  const { featureFlags: { InstantAchFundingEnabled } } = useSelector(state => state.userSlice)

  return (
    <div className='add-funds-summary'>
      <div className='add-funds-summary-row'>
        <div>Amount to Transfer</div>
        <div>{formatDecimal(amount)}</div>
      </div>
      <div className='add-funds-summary-row'>
        <div className='transfer-from'>Transfer From</div>
        <div className='bank-name'>{fromBankName} (*{fromAccountNumberSafe})</div>
      </div>
      <div className='mt-12 add-funds-summary-row text-text-default' >
        <div>Linqto does not charge fees for ACH transfers. {InstantAchFundingEnabled ? 'Funds will be immediately available for purchasing but may not be immediately available for withdrawal.' : 'Funds become available in 1-3 business days.'} </div>
      </div>
    </div>
  )
}

export default SummaryDetailsPlaid
