import React from 'react'
import { Button } from 'components'
import { images } from 'assets'

const LinkedAccount = ({ isConnected, connect, disconnect, copy, title, linkCopy, disconnectCopy, image, accountConntected = false, testID = '' }) => {
  if (!accountConntected) {
    return (
      <div className='mt-5'>
        <div className='flex items-center justify-between medium_1'>
          <div className='flex justify-between items-center lg:w-auto w-[60%]'>
            <img className='w-8 mr-4 lg:w-10 lg:h-10' src={images[image]} alt='uphold' />
            <span className='w-full text-left medium_2 text-text-default'>{title}</span>
          </div>
          <div>
            {!isConnected && linkCopy === 'Under Review' && (
              <span className='cursor-default no-wrap link text-text-default hover:text-text-default'>
                {linkCopy}
              </span>
            )}
            {!isConnected && linkCopy !== 'Under Review' && (
              <Button className='no-wrap link text-text-hyperlink' onClick={connect}>
                {linkCopy}
              </Button>
            )}
            {isConnected && (
              <Button className='no-wrap link text-text-hyperlink' onClick={disconnect} data-testid={`disconnectBtn-${testID}`}>
                {disconnectCopy}
              </Button>
            )}
          </div>
        </div>
        <div className='mx-0 my-5 medium_1'>{copy}</div>
      </div>
    )
  } else {
    return (
      <div className='mt-6 text-center medium_1'>
        <Button className='link text-text-hyperlink' onClick={connect}>Add an Account</Button>
      </div>)
  }
}

export default LinkedAccount
