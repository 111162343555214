import { images } from 'assets'
import { Button } from 'components'
import { Mixpanel } from 'utils'

const EntityDropDown = ({ groupBy, setGroupBy }) => {
  const handleChange = (type) => {
    setGroupBy(type)
    Mixpanel.track('Click Change Portfolio View', { Type: type === 'account' ? 'Show by account' : 'Show by investment' })
  }

  return (
    <div className="flex h-[65px] relative">
      <div className="flex h-full">
        <div className="relative group">
          <Button className="flex items-center justify-center w-10 h-10 rounded-lg hover:bg-background-light-gray" 
                 ariaLabel='Open Show By dropdown'>
            <img alt='Show By Dropdown' src={images['show-by-account']} />
          </Button>
          <div className='absolute right-0 shadow-drop-shadow top-10 bg-background-default  rounded-xl hidden group-hover:block w-[250px]' aria-haspopup='listbox'>
            <a className='flex items-center gap-3 px-4 py-3 item hover:bg-background-light-gray rounded-t-xl' 
               onClick={() => handleChange('account')} 
               role='button' 
               tabIndex={0} 
               aria-label='Show by account' 
               aria-selected={groupBy === 'account'}>
              {groupBy === 'account' && (
                <img alt='Show by account' src={images['show-by_selected']} />
              )}
              Show by account
            </a>
            <a className='flex items-center gap-3 px-4 py-3 item hover:bg-background-light-gray rounded-b-xl' 
               onClick={() => handleChange('investment')} 
               role='button' 
               tabIndex={1} 
               aria-label='Show by investment' 
               aria-selected={groupBy === 'investment'}>
              {groupBy === 'investment' && (
                <img alt='Show by investment' src={images['show-by_selected']} />
              )}
              Show by investment
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EntityDropDown
