import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import useContentful from 'hooks/useContentful'
import { PageLoading } from 'components'
import SeoMeta from 'components/Global/SeoMeta'
import { seoTitleTemplate } from 'utils'
import FaqListItem from './partials/FaqListItem'
import { contentfulContentIds } from 'components/data'
import ArticleListItem from './partials/ArticleListItem'
import useHelpCenter from './useHelpCenter'

/**
 * Help Center component that displays FAQs and Articles
 * @component
 * @returns {React.ReactElement} The Help Center page with FAQ and Article sections
 */
const HelpCenter = () => {
  const history = useHistory()
  const { isSignedIn, isUserReturned } = useSelector((state) => state.userSlice)
  const [isAuthenticating, setIsAuthenticating] = useState(true)
  const { isLoading } = useContentful(contentfulContentIds.helpcCenterId, {}, { isPrivate: true, isSignedIn })
  const { articleLists, faqLists, title, announcementsTitle } = useHelpCenter()

  useEffect(() => {
    if (isUserReturned && !isSignedIn) {
      history.push('/signin', { from: '/help-center' })
    }
    if (isUserReturned) {
      setIsAuthenticating(false)
    }
  }, [isUserReturned, isSignedIn])

  if (isAuthenticating || isLoading) {
    return (
      <>
        <SeoMeta title={seoTitleTemplate('Help Center')} />
        <PageLoading />
      </>
    )
  }

  const handleAnnouncementClick = (url) => {
    history.push(`/help-center/announcements/${url}`)
  }

  const handleArticleClick = (article) => {
    history.push({
      pathname: `/help-center/article/${article?.fields?.url}`,
      state: { article }
    })
  }

  return (
    <>
      <SeoMeta title={seoTitleTemplate('Help Center')} />
      <div className='page-container'>
        <div className='inner-container'>
          {!window.ReactNativeWebView && (
            <div className='flex items-center justify-between mb-8'>
              <h1>{title}</h1>
            </div>
          )}
          <h2 className='large_1'>{announcementsTitle}</h2>
          <div className='mt-4 space-y-4'>
            {faqLists.map((item, index) => (
              <FaqListItem
                key={index}
                item={item}
                onClick={handleAnnouncementClick}
              />
            ))}
          </div>

          {articleLists.map((list, index) => (
            <div key={index} className='mt-10'>
              <h2 className='large_1'>{list.fields.lebel}</h2>
              <div className='mt-4 space-y-4'>
                {list.fields.articlesList?.map((article, articleIndex) => (
                  <ArticleListItem
                    key={articleIndex}
                    title={article.fields.title}
                    url={article.fields.url}
                    category={article.fields.category}
                    onClick={() => handleArticleClick(article)}
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  )
}

export default HelpCenter
