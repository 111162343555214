import { useSelector } from 'react-redux'
import TransactionColumns from './TransactionColumns'
import TransactionItem from './TransactionItem'

const TransactionList = () => {
  const { entryHistory } = useSelector((state) => state.walletSlice)
  // eslint-disable-next-line no-unsafe-optional-chaining
  const sortedEntryHistory = [...entryHistory?.filter((item) => (item.status === 'PENDING')), ...entryHistory?.filter((item) => !(item.status === 'PENDING'))]

  return (
    <div className='nonclickable-grid-container'>
      <div className='pb-2 border-b medium_2 border-b-stroke-on-white-dark md:pb-0 md:border-none'>History</div>
      {entryHistory.length === 0 && (
        <div className='border-t text-text-secondary border-t-stroke-on-white-dark nonclickable-grid-container__no-data large_1'>Add funds to begin investing today.</div>
      )}
      {entryHistory.length > 0 && (
        <div className='nonclickable-grid-container__list'>
          <TransactionColumns />
          {sortedEntryHistory.map((t, i) => (
            <TransactionItem key={i} transaction={t} />
          ))}
        </div>
      )}
    </div>
  )
}

export default TransactionList
