import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'
import { Mixpanel, getDevice, MOBILE_WIDTH_SIZE, Fullstory } from 'utils'
import useWindowSize from 'hooks/useWindowSize'
import { images } from 'assets'
import { ConditionalRender } from 'components'
import ProgressBarRevamp from 'components/InvestorStatus/partials/ProgressBarRevamp'
import Modal from 'components/Global/Modal'
import { useState, useEffect } from 'react'
import USRegulationsContainerCopy from 'components/Profile/partials/USRegulationsContainerCopy'

const IdentityCTA = ({ showQrCode, openPlaid, idvFeatureValue, kycStatus, plaidFail }) => {
  const history = useHistory()
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_WIDTH_SIZE
  const isPlaid = idvFeatureValue === 'plaid'
  const [showPendingModal, setShowPendingModal] = useState(false)
  
  useEffect(() => {
    if (kycStatus === 'PENDING_AUTO_VERIFICATION' || kycStatus === 'PENDING_MANUAL_VERIFICATION') {
      setShowPendingModal(true)
    }
  }, [kycStatus])


  const openMicroBlink = () => {
    isMobile ? history.push('/identity/auto-verification/1') : showQrCode()
  }

  const handleVerifyClick = () => {
    Mixpanel.register({ Device: getDevice() })
    Mixpanel.track('Click Begin ID Verification')
    Fullstory.track('Click Begin ID Verification')
    if (idvFeatureValue === 'plaid') {
      openPlaid()
    } else if (idvFeatureValue === 'persona') {
      openMicroBlink()
    } else {
      openMicroBlink()
    }
  }

  return (
    <div className='page-container'>
      <div className='inner-container'>
        <h1>Investor Profile</h1>
        <ProgressBarRevamp step={1} type='half' />
        <ConditionalRender isVisible={kycStatus === 'FAILED'}>
          <div className='min-h-[84px] rounded-lg bg-background-mid-gray flex justify-start items-start p-4 max-w-[540px] m-auto -mb-4'>
            <img className='w-6 h-6 mr-2' src={images.identity_error} alt='Identity Verification Failed' aria-label='verification-failed'/>
            <div>
              <div className='medium_2'>Verification Failed</div>
              <div>
                <ConditionalRender isVisible={!plaidFail} className='medium_1 text-text-default'>
                  { isPlaid ? 'We’re having some trouble verifying your identity. Please try again.' : 'We couldn’t verify your identity. Please try again or submit your information for manual verification.'}
                </ConditionalRender>
                <ConditionalRender isVisible={plaidFail} className='medium_1 text-text-default'>
                  Sorry, we couldn’t verify your identity. Please  <a
                    onClick={() => history.push('/contact')}
                    className='link text-text-hyperlink contact-us in-line'
                  >Contact Us</a> for help
                </ConditionalRender>
              </div>
            </div>
          </div>
        </ConditionalRender>
        <ConditionalRender isVisible={kycStatus === 'PENDING_AUTO_VERIFICATION' || kycStatus === 'PENDING_MANUAL_VERIFICATION'}>
          <div className='min-h-[84px] rounded-lg bg-background-mid-gray flex justify-start items-start p-4 max-w-[540px] m-auto -mb-4'>
            <div>
              <div className='mb-1 medium_2'>Identity verification pending review</div>
              <div className='body_1 text-text-default'>
              You’ll receive an email when reviewed.
              </div>
            </div>
          </div>
        </ConditionalRender>
        <div className='rounded-lg border-[0.5px] border-stroke-on-white-light max-w-[540px] lg:py-10 lg:px-16 py-10 px-5 m-auto text-center mt-11 mb-4'>
          <div className='px-4 py-0 large_1'>Help us verify your identity</div>
          <img className='mt-6 mb-6 ml-auto mr-auto' src={isPlaid ? images.identity_plaid : images.identity_micoblink} alt='Verify Your ID' aria-label='verification-image'/>
          <div className='flex items-start justify-start mb-8 text-left mt-9 text-text-secondary body_1 lg:mb-6'>
            <img className='!my-0 !mr-2' src={images['lock']} alt='Secure' aria-label='lock'/>
            {isPlaid ? 'Linqto uses Plaid, trusted by over 8,000 leading brands, to securely verify your identity.' : 'You will need to take a picture of your government-issued ID and a selfie.'}
          </div>
          <div className='flex items-start justify-start mb-8 text-left text-text-secondary body_1 lg:mb-6'>
            <img className='!my-0 !mr-2' src={images['perm_phone_msg']} alt='Secure' aria-label='perm_phone_msg text-text-secondary'/>
            By providing a telephone number and submitting the form you are consenting to be contacted by SMS text message. Message & data rates may apply. Reply STOP to opt out of further messaging.
          </div>
          <div className='inline-flex justify-center'>
            <Button
              aria-label='verification-btn'
              onClick={() => handleVerifyClick()}
              mode={kycStatus === 'FAILED' && !isPlaid ? 'secondary' : 'primary'}
              customClass='full-width'
              disabled={kycStatus === 'PENDING_AUTO_VERIFICATION' || kycStatus === 'PENDING_MANUAL_VERIFICATION' || plaidFail}>
              {kycStatus === 'FAILED' && !isPlaid ? 'Try Again' : `Verify with ${isPlaid ? 'Plaid' : 'Microblink'}`}
            </Button>
            {!isPlaid && kycStatus === 'FAILED' &&
              <Button aria-label='manual-verification-btn' onClick={() => history.push('/identity/manual-verification')} customClass='full-width'>Manual Verification</Button>}
          </div>
        </div>
        <div className='flex justify-center'>
          <USRegulationsContainerCopy multilineCopy />
        </div>
        {showPendingModal && <Modal>
          <div className='flex flex-col items-center'>
            <p className='mb-10 text-text-default giant_1'>Pending Review</p>
            <p className='mb-6 text-text-default medium_1'>Thank you for verifying your identity. You’ll receive an email when we have finished reviewing your credentials.</p>
            <Button onClick={() => setShowPendingModal(false)} ariaLabel='Close Modal'>Ok</Button>
          </div>
        </Modal>
        }
      </div>
    </div>
  )
}

export default IdentityCTA
