import { useRef, useState, useEffect } from 'react'
import { images } from 'assets'
import Button from '../Button'

const renderFileName = (name, file) => {
  return (name === '') ? file[0]?.name.slice(0, 100) : name
}
const deleteAction = (e, handleDelete) => {
  e.stopPropagation()
  handleDelete(e)
}
const renderDelete = (handleDelete, ariaLabel) => {
  return (
    <Button className='delete-icon' onClick={(e) => deleteAction(e, handleDelete)} ariaLabel={`Delete ${ariaLabel}`}>
      <svg
        width='16'
        height='18'
        viewBox='0 0 16 18'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
        className='stroke-stroke-on-white-dark hover:stroke-logo-cobalt'
      >
        <line
          x1='1'
          y1='-1'
          x2='20.5418'
          y2='-1'
          transform='matrix(0.677334 0.735675 -0.677334 0.735675 0 2.15234)'
          strokeWidth='2'
          strokeLinecap='round'
        />
        <line
          x1='1'
          y1='-1'
          x2='20.5418'
          y2='-1'
          transform='matrix(0.677334 -0.735675 0.677334 0.735675 1.40918 17.8479)'
          strokeWidth='2'
          strokeLinecap='round'
        />
      </svg>
    </Button>
  )
}

const RenderLoadingUpload = ({ progress }) => {
  const dropZone = useRef()
  const [width, setWidth] = useState(0)
  useEffect(() => {
    setWidth(dropZone.current.offsetWidth)
  }, [dropZone])
  return (
    <>
      <div className='border bg-background-default border-stroke-on-white-dark dropzone-new dropzone-loading' ref={dropZone}>
        <div className='left-group'>
          <span className='pl-0 medium_2 text-text-secondary'>Uploading... {progress}%</span>
        </div>
      </div>
      <div className='bg-logo-cobalt upload-progress-bar' style={{ width: (progress / 100) * width }}/>
    </>
  )
}

const RenderErrorUpload = ({ enableView, uploadErrorTitleMessage, uploadErrorMessage, handleDelete }) => {
  return (
    <>
      <div className='border bg-background-default dropzone-new border-stroke-alert-red dropzone-error'>
        <div className='left-group'>
          <span className='medium_2 text-text-default doc-title'>{uploadErrorTitleMessage}</span>
        </div>
        {!enableView && renderDelete(handleDelete, 'Document')}
      </div>
      {<div className='medium_2 err-msg upload-err-ms text-text-default'>{uploadErrorMessage}</div>}
    </>
  )
}
const handleViewClick = (enableView, url) => {
  if (enableView) {
    window.open(url)
  }
}
const RenderSuccessUpload = ({ enableView, name, fileUploaded, handleDelete, url, allowDelete, ariaLabel, onViewDocument = () => {}, disabled = false }) => {
  return (
    <>
      <div className={`bg-background-default border border-stroke-on-white-dark dropzone-new ${enableView && 'click-to-view'} ${disabled && 'bg-background-mid-gray'}`} aria-label={`${ariaLabel}, PDF Document`} onClick={() => {
        onViewDocument()
        handleViewClick(enableView, url)
      }}>
        <div className='left-group'>
          <img alt='PDF Document' className='upload-img' src={images.pdf} />
          <span className='medium_2 text-text-default doc-title'>{renderFileName(name, fileUploaded)}</span>
        </div>
        {allowDelete && !disabled && renderDelete(handleDelete, ariaLabel)}
        {!allowDelete && enableView && <img src={images['upload-right-arrow']} alt={`View ${name} document`} className='upload-arrow' />}
      </div>
    </>
  )
}

const RenderIntitalUpload = ({ placeHolder, dragging, getRootProps, getInputProps, open, uploadError = false, disabled = false }) => {
  return (
    <>
      <div {...getRootProps({ className: `bg-background-default border border-stroke-on-white-dark dropzone-new hover:border-stroke-cobalt-blue hover:border ${uploadError && 'border border-stroke-alert-red dropzone-error'} ${dragging && 'border border-stroke-cobalt-blue'} ${disabled && 'bg-background-mid-gray'}` })}>
        <input {...getInputProps()} aria-label='Upload your document' disabled={disabled}/>
        <div className='medium_1'>{placeHolder}</div>
        <Button type='button' className='medium_1 bg-background-mid-gray text-text-default browse-button' onClick={open} ariaLabel='Browse to upload document'>
          Browse
        </Button>
      </div>
    </>
  )
}

export {
  renderFileName,
  RenderLoadingUpload,
  RenderErrorUpload,
  RenderSuccessUpload,
  RenderIntitalUpload
}
