import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'
import { useHistory } from 'react-router-dom'

const ConfirmCancellationModal = ({
  setShowConfimModal,
  submitCancellation,
  loading
}) => {
  const history = useHistory()
  return (
    <>
      <Modal
        crossToClose
        modalHeader='Are you sure?'
        hideModal={() => setShowConfimModal(false)}
      >
        <div className='medium_1 content'>
          Cancelling your recurring investment will remove any future investments you have scheduled.
        </div>
        <div className='items-center ml-auto mr-auto md:flex'>
          <Button mode='secondary' onClick={() => setShowConfimModal(false)}>
            Never Mind
          </Button>
          <Button
            onClick={() => submitCancellation()}
            loading={loading}
          >
            Confirm Cancellation
          </Button>
        </div>
        <div className='medium_1 space-above-sm'>
        Need help?{' '}
          <Button
            onClick={() => history.push('/contact')}
            className='link text-text-hyperlink contact-us'
          >
            Contact us
          </Button>
        </div>
      </Modal>
    </>
  )
}

export default ConfirmCancellationModal
