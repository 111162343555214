import { images } from 'assets'
import { useState, useRef, useEffect } from 'react'
import { processRichText } from 'utils';
import { getHelpCenterFormattedDate } from 'utils'

const FAQBox = ({ faq }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [height, setHeight] = useState(0)
  const contentRef = useRef(null)

  if (!faq?.fields) {
    return null
  }

  const formattedDate = getHelpCenterFormattedDate(faq)
  const formatedAnswer = processRichText(faq.fields.answer, {}, false)

  
  useEffect(() => {
    if (isOpen) {
      setHeight(contentRef.current.scrollHeight)
    } else {
      setHeight(0)
    }
  }, [isOpen])
  
  const toggleOpen = () => {
    setIsOpen(!isOpen)
  }
  
  return (
    <div 
      className='flex flex-col cursor-pointer w-full rounded-lg border border-stroke-on-white-light md:max-w-[358px] md:max-w-full p-6 my-4'
      data-testid='faq-box'
      onClick={toggleOpen}
    >
      <div className='flex justify-between items-center w-full'>
        <p className='medium_2'>{faq.fields.question}</p>
        <img 
          src={images['chevron-down']} 
          alt='expand or collapse' 
          className={`transition-transform duration-300 ml-4 ${isOpen ? 'rotate-180' : ''}`} 
        />
      </div>
      <div 
        ref={contentRef}
        className="overflow-hidden w-full transition-all duration-300 ease-in-out"
        style={{ maxHeight: `${height}px`, opacity: isOpen ? 1 : 0 }}
      >
        <p className='mt-6 medium_1 text-text-secondary'>{formattedDate}</p>
        <p className='mt-2 medium_1 faq-box'>{formatedAnswer}</p>
      </div>
    </div>
  )
}

export default FAQBox
