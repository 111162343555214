import { useEffect, useState } from 'react'

const ITEMS_PER_PAGE = 4

// watchlist section to handle pagination and display of watchlist items (web and mobile)
const useWatchlistSection = (list) => {
  const [currentPage, setCurrentPage] = useState(1)
  const [currentCompanies, setCurrentCompanies] = useState([])

  useEffect(() => {
    if (list && list.length) {
      // Calculate the index of the first and last item on the current page
      const indexOfLastItem = currentPage * ITEMS_PER_PAGE
      const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE

      // Slice the companies array to get the companies for the current page
      const companies = list?.slice(indexOfFirstItem, indexOfLastItem) || []

      if (companies.length === 0 && currentPage > 1) {
        setCurrentPage(currentPage - 1)
      }

      setCurrentCompanies(companies)
    } else {
      setCurrentCompanies([])
    }
  }, [list, currentPage])

    // Calculate the total number of pages
    const totalPages = Math.ceil(list?.length / ITEMS_PER_PAGE)
    
    const nextPage = () => {
      if (currentPage < totalPages) {
        setCurrentPage(currentPage + 1)
      }
    }

    const prevPage = () => {
      if (currentPage > 1) {
        setCurrentPage(currentPage - 1)
      }
    }

    return [currentCompanies, currentPage, totalPages, nextPage, prevPage]
}

export default useWatchlistSection