import { useHistory } from 'react-router-dom'
import { Button } from 'components'

const SignInLink = () => {
  const history = useHistory()
  return (
    <span className='flex justify-center items-center mt-1 text-text-default medium_1'>
      Already have an account?
      <Button onClick={() => history.push('/signin')} className='link text-text-hyperlink left-padded medium_2'>Sign In</Button>
    </span>
  )
}

export default SignInLink
