import { Keypad, Button, NumberHeaderAmount, PurchaseMessagingBanner, Tooltip } from 'components'
import { useState } from 'react'
import { MAX_UPHOLD_LIMIT } from 'components/FBOAddFunds/FBOAddFunds'
import useAddFundsRedesign from 'components/FBOAddFunds/AddFundsRedesign/useAddFundsRedesign'
import { images } from 'assets'
import { formatDecimal } from 'utils'

const AddFundsRedesignKeypad = ({ hideModal }) => {
  const { contentLabels, achLimit, selectedAccount } = useAddFundsRedesign()
  const [keypadValue, setKeypadValue] = useState('')
  const [cents, setCents] = useState('')
  const [showHover, setShowHover] = useState(false)
  const showErrorBanner =
    +keypadValue > (selectedAccount?.method === 'Uphold' ? MAX_UPHOLD_LIMIT : achLimit)

  return (
    <div
      className='fixed inset-0 z-[1000] overflow-scroll bg-white'
      role='dialog'
      aria-modal='true'
      tabIndex={-1}
      aria-label='Purchase amount keypad'
      onKeyDown={e => e.key === 'Escape' && hideModal()}
    >
      <button
        onClick={hideModal}
        className='flex items-center justify-center w-6 h-6 mt-4 mr-4 rounded-full bg-background-light-gray justify-self-end'
      >
        <img
          src={images['remove-X']}
          alt={contentLabels?.addFundsIcon?.media?.title}
          className='flex h-3 justify-self-end'
        />
      </button>
      <div className='h-full p-4 mt-4 overflow-auto'>
        <img
          src={contentLabels?.addFundsIcon?.media?.url}
          alt={contentLabels?.addFundsIcon?.media?.title}
          className='flex h-12 justify-self-center'
        />
        <p className='mb-7 large_1 mt-0.5 text-center'>{contentLabels?.addFunds}</p>
        <div className='flex items-start justify-center mb-4 '>
          <p className='text-center medium_1'> {contentLabels?.depositAmount}</p>{' '}
          <Tooltip
            header={contentLabels?.depositAmountTooltip?.tooltipTitle}
            content={contentLabels?.depositAmountTooltip?.tooltipBody}
            showHover={showHover}
            setShowHover={setShowHover}
          />
        </div>
        <NumberHeaderAmount value={keypadValue} cents={cents} ariaLabel='addFundsAmount' />
        <PurchaseMessagingBanner isVisible={showErrorBanner} color='red'>
          <div className='flex items-center'>
            <img src={images.exclaim} alt='exclaim' className='mr-2' />
            <p className='body_1'>
              Amount cannot exceed{' '}
              {selectedAccount?.method === 'Uphold'
                ? formatDecimal(MAX_UPHOLD_LIMIT)
                : formatDecimal(achLimit)}
            </p>
          </div>
        </PurchaseMessagingBanner>
        <div className='mt-24'>
          <Keypad
            value={keypadValue}
            setValue={setKeypadValue}
            cents={cents}
            setCents={setCents}
            maxDigits={7}
          />
          <Button
            customClass=' w-full'
            onClick={() => console.log('to do')}
            disabled={showErrorBanner}
          >
            {contentLabels?.reviewButton}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default AddFundsRedesignKeypad
