import { useFormContext } from 'react-hook-form'
import TooltipLabel from '../TooltipLabel'
import ErrorMessage from './ErrorMessage'

const Select = ({
  name,
  label,
  field,
  content = '',
  disabled = false,
  ariaLabel = '',
  className = '',
  options = [],
  optionsWithLabel = [],
  defaultOption = null,
  handleChange = () => {},
  extraLabel = '',
  textFormat = null,
  onClick = () => {}
}) => {
  const {
    formState: { errors },
    setValue,
    clearErrors
  } = useFormContext()

  return (
    <div className={`input-group ${errors[name] && 'error'} ${className}`}>
      <TooltipLabel content={content} label={label} extraLabel={extraLabel} htmlFor={name} />
      <select
        {...field}
        disabled={disabled}
        name={name}
        aria-label={ariaLabel}
        onClick={onClick}
        onFocus={onClick}
        onChange={(e) => {
          setValue(name, e.target.value, { shouldDirty: true })
          handleChange(e.target.value)
          clearErrors(name)
        }}
        id={name}
        className={`border bg-background-default  text-text-default hover:outline-none focus:border-stroke-cobalt-blue active:border-stroke-cobalt-blue ${errors[name] ? 'border-stroke-alert-red hover:border-stroke-alert-red focus:border-stroke-alert-red' : 'border-stroke-on-white-dark'}`}
      >
        {defaultOption && (
          <option value={defaultOption.value}>{defaultOption.label}</option>
        )}
        {options &&
          options.length > 0 &&
          options.map((e, i) => (
            <option value={e} key={`${i}-${e}`} data-testid={`${name}-${i}`}>
              {textFormat ? textFormat(e) : e}
            </option>
          ))}
        {optionsWithLabel &&
          optionsWithLabel?.map((e, i) => (
            <option value={e.value} key={`${i}-${e}`}>
              {textFormat ? textFormat(e.label) : e.label}
            </option>
          ))}
      </select>
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default Select