import { useState } from 'react'
import Modal from 'components/Global/Modal'
import Button from 'components/Global/Button'
import { ConditionalRender, ButtonSelect } from 'components'
import { images } from 'assets'
import { suitabilityQuestions, InvestmentHorizon, RiskTolerance, InvestmentExperience, suitabilityAnswers } from 'components/data'
import { updateUserProfile } from 'slices/profileSlice'
import { useDispatch } from 'react-redux'

const SuitabilityQuestionsModal = ({ hideModal, showCloseX = true }) => {
  const dispatch = useDispatch()
  const [questionIndex, setQuestionIndex] = useState(0)
  const [investmentHorizon, setInvestmentHorizon] = useState(InvestmentHorizon.UNKNOWN)
  const [riskTolerance, setRiskTolerance] = useState(RiskTolerance.UNKNOWN)
  const [investmentExperience, setInvestmentExperience] = useState(InvestmentExperience.UNKNOWN)
  const [showComplete, setShowComplete] = useState(false)
  const buttonDisabled = (questionIndex === 0 && investmentHorizon === InvestmentHorizon.UNKNOWN) ||
    (questionIndex === 1 && riskTolerance === RiskTolerance.UNKNOWN) ||
    (questionIndex === 2 && investmentExperience === InvestmentExperience.UNKNOWN)
  const answers = suitabilityAnswers[questionIndex]
  const options = Object.keys(answers).filter(o => o !== 'UNKNOWN')
  const [selected, setSelected] = useState(null)
  const [submitAnswersLoading, setSubmitAnswersLoading] = useState(false)

  const selectOption = (index) => {
    setSelected(index)
    onSelectedAnswer(options[index])
  }

  const onHandleNext = () => {
    if (questionIndex !== 2) {
      setSelected(null)
    }
    handleNext()
  }

  const handleNext = async () => {
    if (questionIndex < 2) {
      return setQuestionIndex(questionIndex + 1)
    }
    if (questionIndex === 2) {
      // Update user profile with answers, then close the modal
      setSubmitAnswersLoading(true)
      const res = await dispatch(updateUserProfile({ investmentHorizon, riskTolerance, investmentExperience }))
      setSubmitAnswersLoading(false)
      if (res?.meta?.requestStatus === 'fulfilled') {
        // Show complete order modal
        setShowComplete(true)
      }
    }
  }

  const onSelectedAnswer = (answer) => {
    switch (questionIndex) {
    case 0:
      setInvestmentHorizon(answer)
      break
    case 1:
      setRiskTolerance(answer)
      break
    case 2:
      setInvestmentExperience(answer)
      break
    }
  }

  return (
    <Modal
      crossToClose={showCloseX}
      modalHeader=''
      innerStyle='success-modal'
      hideModal={hideModal}
    >
      <ConditionalRender isVisible={showComplete}>
        <div className='flex flex-col items-center'>
          <img className='size-20' src={images['complete-suitability-icon']} alt='complete-suitability-icon' />
          <span className='mt-6 large_1 text-text-default'>Complete</span>
          <span className='my-5 medium_1 text-text-default max-w-[330px]'>Your Investor Profile is now up to date! You can now continue to place your order.</span>
          <div className='btn-mt-2 centered'>
            <Button onClick={() => hideModal('complete')}>
              Done
            </Button>
          </div>
        </div>
      </ConditionalRender>
      <ConditionalRender isVisible={!showComplete}>
        <div className='flex flex-col'>
          <span className='mb-6 large_1 text-text-default'>Update Investor Profile</span>
          <div className='flex justify-evenly'>
            <div className={`h-1 w-[30%] border rounded-lg ${questionIndex >= 1 ? 'bg-fill-cobalt-primary border-text-text-hyperlink' : 'bg-background-mid-gray border-stroke-on-white-light'}`} />
            <div className={`h-1 w-[30%] border rounded-lg ${questionIndex >= 2 ? 'bg-fill-cobalt-primary border-text-text-hyperlink' : 'bg-background-mid-gray border-stroke-on-white-light'}`} />
            <div className={`h-1 w-[30%] border rounded-lg ${showComplete ? 'bg-fill-cobalt-primary border-text-text-hyperlink' : 'bg-background-mid-gray border-stroke-on-white-light'}`} />
          </div>
          <span className='px-[0.8rem] mt-5 mb-4 text-start medium_4 text-text-default'>{suitabilityQuestions[questionIndex]}</span>
          {questionIndex !== 0 && <span className='px-[0.8rem] mb-6 text-text-default text-start body_1'>Select an option to learn more</span>}
          <div className='grid-old'>
            {options.map((o, i) => (
              <div key={o} className={`mb-2 column sixteen-mobile ${questionIndex === 0 ? 'four' : 'three'}`}>
                <ButtonSelect smallFont ariaLabel={`option-${i}`} selected={selected === i} selectItem={() => selectOption(i)}>
                  <span className='body_1 text-text-default'>{answers[options[i]].type}</span>
                </ButtonSelect>
              </div>
            ))}
          </div>
          {((questionIndex === 1 && riskTolerance !== RiskTolerance.UNKNOWN) || (questionIndex === 2 && investmentExperience !== InvestmentExperience.UNKNOWN)) && <div className='flex p-4 mx-[0.8rem] mt-2 mb-4 border rounded-lg md:mb-2 min-h-16 bg-background-mid-gray border-stroke-on-white-light'>
            {questionIndex === 1 && <span className='body_1 text-text-default text-start'><span className='body_2 text-text-default'>{answers[riskTolerance].type}</span>: {answers[riskTolerance].tooltip}</span>}
            {questionIndex === 2 && <span className='body_1 text-text-default text-start'><span className='body_2 text-text-default'>{answers[investmentExperience].type}</span>: {answers[investmentExperience].tooltip}</span>}
          </div>}
          <div className='mt-2 centered'>
            <Button loading={submitAnswersLoading} disabled={buttonDisabled} onClick={onHandleNext}>
              Next
            </Button>
          </div>
        </div>
      </ConditionalRender>
    </Modal>
  )
}

export default SuitabilityQuestionsModal
