import React from 'react'
import { formatDecimal, MOBILE_WIDTH_SIZE } from 'utils'
import { images } from 'assets'
import { useHistory } from 'react-router-dom'
import useWindowSize from 'hooks/useWindowSize'

const AccountItem = ({ icon, accountName, balance, goTo, tag = null }) => {
  const { width } = useWindowSize()
  const isMobile = width < MOBILE_WIDTH_SIZE

  const history = useHistory()
  return (
    <div className='border border-stroke-on-white-light current-investment-item' onClick={() => history.push(goTo)} role='button' tabIndex={0} aria-label={`Navigate to ${accountName}`}>
      <div className='current-investment-item_full'>
        <div className='medium_2 current-investment-item_left'>
          <img src={images[icon]} alt={`Navigate to ${accountName}`} />
          <span className='current-investment-item-copy'>{accountName} {!isMobile ? tag : null}</span>
        </div>
        <div className='current-investment-item_right'>
          <div className='current-investment-item_right shares-total-price-container'>
            <span className='medium_2 current-investment-item_right total-price'>{formatDecimal(balance)}</span>
          </div>
          <img className='current-investment-item_right tab-arrow' src={images['right-arrow']} alt={`Navigate to ${accountName}`}/>
        </div>
      </div>
      {isMobile ? tag : null}
    </div>
  )
}

export default AccountItem
