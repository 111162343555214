import Select from 'components/Global/Inputs/Select'
import { Controller, useFormContext } from 'react-hook-form'
import FBOAddBankCTA from './FBOAddBankCTA'
import Upload from 'components/Global/Upload/Upload'
import { useEffect, useState } from 'react'
import ErrorMessage from 'components/Global/Inputs/ErrorMessage'
import { Austrailia, Canada, EuropeanUnion, International, UKIreland, UnitedStates } from './FBOBankFields'
import { bankCodes, countries } from './bankCountries'
import { Mixpanel } from 'utils'

const bankValues = Object.values(countries)

const FBOAddBankForm = ({ onSubmit }) => {
  const [document, setDocument] = useState('')
  const {
    handleSubmit,
    control,
    setValue,
    watch,
    clearErrors,
    formState: { errors },
    reset
  } = useFormContext()

  const bankCountry = watch('bankCountry')
  const accountType = watch('accountType')

  const setDocumentFile = file => {
    setDocument(file)
    clearErrors('document')
    setValue('document', file[0]?.name)
  }

  useEffect(() => {
    reset()
    setValue('bankCountry', bankCountry)
    setValue('accountType', accountType)
    setDocument('')
  }, [bankCountry])

  useEffect(() => {
    Mixpanel.track('View Add Account for Withdrawals Page')
  }, [])

  const onSubmitForm = (data) => {
    const bankCode = bankCodes[data.bankCountry]
    const type = accountType.toLowerCase()
    onSubmit({ ...data, bankCountry: bankCode, accountType: type, document })
  }

  let bankFields = null
  if (bankCountry === countries.USA) {
    bankFields = <UnitedStates />
  } else if (bankCountry === countries.CAN) {
    bankFields = <Canada />
  } else if (bankCountry === countries.AUS) {
    bankFields = <Austrailia />
  } else if (bankCountry === countries.UK) {
    bankFields = <UKIreland />
  } else if (bankCountry === countries.EU) {
    bankFields = <EuropeanUnion />
  } else if (bankCountry === countries.INTL) {
    bankFields = <International />
  }

  return (
    <form onSubmit={handleSubmit(onSubmitForm)}>
      <Controller
        control={control}
        name='accountType'
        setValue={setValue}
        render={({ field }) => (
          <Select
            field={field}
            label='Account Type'
            name='accountType'
            ariaLabel='accountType'
            options={['Checking', 'Savings']}
            setValue={setValue}
          />
        )}
      />
      <Controller
        control={control}
        name='bankCountry'
        setValue={setValue}
        render={({ field }) => (
          <Select
            field={field}
            label='Bank Country'
            name='bankCountry'
            ariaLabel='bankCountry'
            options={bankValues}
            setValue={setValue}
          />
        )}
      />
      {bankFields}
      <div className='input-group'>
        <label>Copy of Account Statement</label>
        <Upload
          customClass='full-width noBottomMargin'
          uploadError={errors?.document}
          setFileUploaded={setDocumentFile}
          fileUploaded={document}
        />
        {errors?.document && <ErrorMessage message={errors?.document?.message} />}
        <label className='text-text-default'>The account owner on the statement must match the name on your Linqto account.</label>
      </div>
      <FBOAddBankCTA />
    </form>
  )
}
export default FBOAddBankForm
