import React from 'react'
import { useFormContext } from 'react-hook-form'
import TooltipLabel from '../TooltipLabel'
import ErrorMessage from './ErrorMessage'

const PassCodeInput = ({
  name,
  label,
  placeholder,
  content = '',
  disabled = false,
  ariaLabel = '',
  onChange = () => {},
  onBlur = () => {},
  maxLength = null,
  autoFocus,
  className = '',
  inputClassName = ''
}) => {
  const {
    register,
    formState: { errors }
  } = useFormContext()
  return (
    <div className={`input-group ${className} ${errors[name] && 'error'}`}>
      <TooltipLabel content={content} label={label} htmlFor={name} />
      <input
        disabled={disabled}
        type='tel'
        placeholder={placeholder}
        {...register(name, {
          onChange,
          onBlur
        })}
        aria-label={ariaLabel}
        maxLength={maxLength}
        autoFocus={autoFocus}
        id={name}
        className={`${inputClassName} ${errors[name] ? 'border border-stroke-alert-red' : ''}`}
      />
      {errors[name] && <ErrorMessage message={errors[name].message} />}
    </div>
  )
}

export default PassCodeInput
