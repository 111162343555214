import { contentfulContentIds } from 'components/data'
import useContentful from 'hooks/useContentful'
import { getLabelContent } from 'utils/Contentful'

/**
 * @function useHelpCenter hook
 * Handles data fetching and logic for the Help Center component
 * @param {string} [faqId] - Optional FAQ list ID to filter by
 * @returns {Object} Help center data and loading state
 */
const useHelpCenter = (faqId) => {
  const { content, isLoading } = useContentful(contentfulContentIds.helpcCenterId)

  const faqLists = content?.fields?.items?.filter(item => 
    item.sys.contentType.sys.id === 'faQsList'
  ) || []

  // If faqId is provided, filter the specific FAQ list
  const filteredFaqList = faqId 
    ? faqLists.find(item => item.fields.url === faqId)
    : null

  const articleLists = content?.fields?.items?.filter(item =>
    item.sys.contentType.sys.id === 'articleList'
  ) || []

  const title = getLabelContent('title', content?.fields?.items || [])
  const announcementsTitle = getLabelContent('announcementsTitle', content?.fields?.items || [])

  return {
    isLoading,
    content,
    faqLists: filteredFaqList ? [filteredFaqList] : faqLists,
    articleLists,
    title,
    announcementsTitle,
    currentFaq: filteredFaqList
  }
}

export default useHelpCenter
