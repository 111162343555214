import ProgressBar from '../../Profile/partials/ProgressBar'
import NewProgressBar from 'components/Profile/partials/NewProgressBar'
import AccreditationStatus from './AccreditationStatus'
import { useSelector } from 'react-redux'
import Tooltip from 'components/Global/Tooltip'
import { useState } from 'react'
import ConditionalRender from 'components/Global/ConditionalRender'
import { useLDFlags } from 'utils'

const content = 'Your legal name is used for identity verification, tax reporting, and other legal documents. Contact support for questions.'

const InvestorStatusHeader = ({
  accreditedStatus,
  showProgress = true,
  showHeader = true,
  showAccreditedHeader = true
}) => {
  const { email, legalName } = useSelector(state => state.investorStatusSlice)
  const { suitabilityQuestions } = useLDFlags(['suitabilityQuestions'])

  const [show, setShow] = useState(false)
  return (
    <div className='investor-status-header-container'>
      <div className='profile-progress-container'>
        <h1 id='investor-status-title' className='subinfo'>
          {showHeader ? 'Investor Profile' : ''}
          <div className='medium_1 web-account-email'>
            <span>
              Account Email: <span className='text-text-default'>{email}</span>
            </span>
            <br />
            <span>
              Legal Name: <span className='text-text-default'>{legalName}</span>
            </span>
            <Tooltip
              content={content}
              showHover={show}
              setShowHover={setShow}
              configurable={false}
            />
          </div>
        </h1>
        <ConditionalRender isVisible={showProgress}>
          <hr className='title-separator' />
          {suitabilityQuestions ? <NewProgressBar activeStep={8} /> : <ProgressBar activeStep='Verify' />}
        </ConditionalRender>
      </div>
      <ConditionalRender isVisible={showAccreditedHeader}>
        <div className='investor-status-sub-title large_1' >
        Verify Accreditation
          <AccreditationStatus accreditedStatus={accreditedStatus} />
        </div>
      </ConditionalRender>
    </div>
  )
}

export default InvestorStatusHeader
