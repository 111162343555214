import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ConditionalRender, Button } from 'components'
import { Mixpanel } from 'utils'
import { images } from 'assets'

const ProfileEntity = () => {
  const history = useHistory()
  const { userProfile, isAccredited, isRetailUser } = useSelector((state) => state.profile)
  const { entities } = userProfile

  const handleAddEntity = () => {
    history.push('/entity')
    Mixpanel.track('Click Add an Entity')
  }

  return (
    <div className='border border-stroke-on-white-light profile-box'>
      <div className='medium_4'>Investment Entities</div>
      <div className='text-text-secondary medium_1 space-above-xsm'>Fund your Linqto accounts with Corporations (S-, C-, B-), Partnerships, LLCs, Trusts (Family, Revocable, etc.), Solo 401ks, Self-Directed IRAs, Non-Profits, and more.</div>
      <div className='border border-stroke-on-white-light profile-list'>
        <div className='border-b border-b-stroke-on-white-light profile-list-header text-text-secondary medium_2'>
          My Entities
        </div>
        <ConditionalRender isVisible={isAccredited || isRetailUser}>
          {entities?.length === 0 &&
          <div className='profile-list-empty-state'>
            <div className='medium_2 text-text-default'>Invest through your company, trust, solo 401k, self-directed IRA, etc.</div>
            <Button onClick={handleAddEntity} mode='secondary'>Add Entity</Button>
          </div>
          }
          {entities?.length > 0 && <>
            {entities?.map((e, i) => {
              return (
                <div key={`entity-${i}`} className='border-b profile-list-item border-b-stroke-on-white-light entity'>
                  <span className='medium_1 text-text-default'>{e.name}</span>
                  {e.status === 'PENDING_REVIEW' && <span className='body_1 text-text-default list-status bg-background-mid-gray'>Under Review</span>}
                </div>)
            })}
            <Button className='min-w-full border-b profile-list-item border-b-stroke-on-white-dark action hover:bg-background-mid-gray' onClick={handleAddEntity} ariaLabel='Add Entity'>
              <span className='medium_2 text-text-hyperlink list-action'><img alt='Add Entity' src={images['icon-list-add']} />Add Entity</span>
            </Button>
          </>}
        </ConditionalRender>
        <ConditionalRender isVisible={!isAccredited && !isRetailUser}>
          <div className='profile-list-empty-state'>
            <div className='medium_2 text-text-secondary'>Complete your accreditation verification to be able to add a investment entity</div>
            <Button disabled>Add Entity</Button>
          </div>
        </ConditionalRender>
      </div>
    </div>
  )
}

export default ProfileEntity
