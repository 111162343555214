import useWindowSize from 'hooks/useWindowSize'
import { useSelector } from 'react-redux'
import { MOBILE_WIDTH_SIZE, Mixpanel } from 'utils'
import { Button } from 'components'
import { useLDFlags } from 'utils/LaunchDarkly'

const InvestDetailTabs = ({ activeIndex, setActiveIndex }) => {
  const { width } = useWindowSize()
  const { details: { name } } = useSelector(state => state.investSlice)
  const { showPriceHistoryChart } = useLDFlags(['showPriceHistoryChart'])

  const getTabIndex = (baseIndex) => {
    return !showPriceHistoryChart ? baseIndex - 1 : baseIndex
  }

  const isTabActive = (tabIndex) => {
    return !showPriceHistoryChart 
      ? activeIndex === getTabIndex(tabIndex)
      : activeIndex === tabIndex
  }

  const updateActiveIndexAndTrack = (num, tab, id) => {
    setActiveIndex(getTabIndex(num))
    Mixpanel.track('Click Product Tab', { 'Tab Name': tab, 'Company Name': name })

    if (width <= MOBILE_WIDTH_SIZE) {
      const element = document?.querySelector(id)
      const y = element.getBoundingClientRect().top + window.scrollY - 90
      window.scrollTo({ top: y, behavior: 'smooth' })
    } else {
      document?.querySelector(id)?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  const tabs = [
    {
      id: 'pricingHistoryTab',
      label: 'Price History',
      ariaLabel: 'Pricing History tab',
      anchor: '#pricingHistory',
      index: 0,
      showCondition: showPriceHistoryChart
    },
    {
      id: 'aboutTab',
      label: 'About',
      ariaLabel: 'About tab',
      anchor: '#about',
      index: 1
    },
    {
      id: 'summaryTab',
      label: 'Summary',
      ariaLabel: 'Summary tab',
      anchor: '#summary',
      index: 2
    },
    {
      id: 'valuationTab',
      label: 'Valuation',
      ariaLabel: 'Valuation tab',
      anchor: '#valuation',
      index: 3
    }
  ]

  const renderTab = (tab) => {
    if (tab.showCondition === false) return null

    return (
      <Button
        key={tab.id}
        data-testid={tab.id}
        ariaLabel={tab.ariaLabel}
        className={`medium_2 tab whitespace-nowrap min-w-fit ${isTabActive(tab.index) ? 'text-logo-cobalt border-b-[3px] border-b-logo-cobalt active' : 'text-text-default'}`}
        onClick={() => updateActiveIndexAndTrack(tab.index, tab.label, tab.anchor)}
      >
        {tab.label}
      </Button>
    )
  }

  return (
    <div className='relative w-full invest-tabs-container'>
      <div className='flex space-x-4 overflow-x-auto scrolling-touch border-b scrollbar-hide invest-tabs-container__page-tabs border-b-stroke-on-white-dark'>
        {tabs.map(renderTab)}
      </div>
    </div>
  )
}

export default InvestDetailTabs