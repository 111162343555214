import { useSelector } from 'react-redux'
import Button from 'components/Global/Button'
import { images } from 'assets'

const ActAsMyselfButton = ({ viewAs }) => {
  const { masterUser } = useSelector((state) => state.ria)

  return (
    <div className='flex justify-end'>
      <div className='text-right'>
        <Button
          size='sm'
          ariaLabel='Act as myself'
          onClick={() => viewAs(masterUser.userId)}>
          Act as myself
          <img
            alt=' Act as myself'
            src={images.arrow_white_right}
            className='ml-4'
          />
        </Button>
        {masterUser.isCurrentIdentity && (
          <span className='body_1 text-text-secondary -mt-1'>Currently active</span>
        )}
      </div>
    </div>
  )
}

export default ActAsMyselfButton
