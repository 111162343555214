import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { handleServerError } from 'slices/commonSlice'
import api from '../apis/private.js'
import parseErrorMessages from 'actions/parsing/parseErrorMessages'

const initialState = {
  bundleId: 0,
  bundleName: '',
  isAccredited: false,
  totalAmount: 0,
  documents: [],
  companies: [],
  accounts: [],
  buyOrderTotal: 0,
  ordersList: [],
  sellSharesDetail: {},
  amountToCashAccount: 0,
  sellOrdersList: [],
  totalBundleQuantityAvailable: 1
}

/**
 * Accepts an integer bundleId
 * Calls /confirmBundleOrder API with bundle id, sell company id, entiy id & sell company id to get bundle order data
 * Returns bundle data
 * @function confirmBundleOrder
 * @param {int} bundleId
 * @param {int} sellCompanyId
 * @param {int} entityId
 * @returns {Object}
 */
export const confirmBundleOrder = createAsyncThunk('confirmBundleOrder', ({ bundleId, sellCompanyId, entityId, quantityRequested }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  return api
    .post('/page/confirmBundleOrder', { bundleId, sellCompanyId, entityId, quantityRequested })
    .then(res => fulfillWithValue(res.data))
    .catch(err => {
      if (err.response && err.response.status === 500) {
        const data = err.response.data
        return rejectWithValue(parseErrorMessages(data))
      } else {
        dispatch(handleServerError(err.response.data.error))
        return rejectWithValue(err.response)
      }
    })
})

/**
 * Accepts an integer bundleId
 * Calls /confirmBundleOrder/commit API with bundle id, entity id & sell company id to commit the bundle order
 * Returns trade id
 * @function commitBundleOrder
 * @param {int} bundleCode
 * @param {int} entityId
 * @param {int} sellCompanyId
 * @param {int} sellShares
 * @returns {int}
 */
export const commitBundleOrder = createAsyncThunk('commitBundleOrder', async ({ bundleCode, entityId, sellCompanyId, sellShares, bundleQuantity, bundleAmount }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await api.post('/page/confirmBundleOrder/commit', { bundleCode, entityId, sellCompanyId, sellShares, bundleQuantity, bundleAmount })
    return fulfillWithValue(response.data)
  } catch (error) {
    if (error?.response && error?.response?.status === 500) {
      return rejectWithValue(parseErrorMessages(error?.response?.data))
    } else {
      dispatch(handleServerError(error?.response?.data?.error))
      return rejectWithValue(error.response)
    }
  }
})

/**
 * Accepts an integer tradeId
 * Calls /tradeConfirmed API with trade id to get bundle order information
 * Returns order object
 * @function getBundleOrderConfirmedPage
 * @param {int} tradeId
 * @returns {object}
 */
export const getBundleOrderConfirmedPage = createAsyncThunk('getBundleOrderConfirmedPage', async ({ tradeId }, { dispatch, fulfillWithValue, rejectWithValue }) => {
  try {
    const response = await api.get(`/page/tradeConfirmed/${tradeId}`)
    return fulfillWithValue(response.data)
  } catch (error) {
    dispatch(handleServerError(error?.response?.data?.error))
    return rejectWithValue(error?.response?.data)
  }
})

export const bundleOrderSlice = createSlice({
  name: 'bundleOrder',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(confirmBundleOrder.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
      .addCase(confirmBundleOrder.rejected, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
      .addCase(getBundleOrderConfirmedPage.fulfilled, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
      .addCase(getBundleOrderConfirmedPage.rejected, (state, { payload }) => {
        return {
          ...state,
          ...payload
        }
      })
      .addCase(commitBundleOrder.rejected, (state, { payload }) => {
        return { ...state, placeOrderError: payload }
      })
  }
})

export default bundleOrderSlice.reducer
