import Upload from 'components/Global/Upload/Upload'
import { ConditionalRender } from 'components'

const DocumentSection = ({ title, emptyState, documents, onViewDocument }) => {
  return (
    <div className='document-section'>
      <h2 className='large_1'>{title}</h2>
      <ConditionalRender isVisible={documents.length === 0}>
        <div className='medium_1 text-text-secondary'>{emptyState}</div>
      </ConditionalRender>
      <ConditionalRender isVisible={documents.length > 0}>
        <div className='margined documents-list'>
          {documents.map((doc) => {
            return (
              <Upload
                key={doc.name}
                enableView={true}
                {...doc}
                ariaLabel={doc.name}
                onViewDocument={onViewDocument}
              />
            )
          })}
        </div>
      </ConditionalRender>
    </div>
  )
}

export default DocumentSection
