export const CONFIG_BRAZE = 'configBraze';

/**
 *
 * @param {Config} config
 * @returns {BrazeConfig}
 * @constructor
 */
function BrazeConfig(config) {
  Object.assign(this, config.get(CONFIG_BRAZE) || {})
  return this;
}

export default BrazeConfig;

BrazeConfig.prototype.getApiKey = function() {
  return this.apiKey
}

BrazeConfig.prototype.getBaseUrl = function() {
  return this.baseUrl
}