export const CONFIG_AI_AUTH = 'configAiAuth'
/**
 *
 * @param {Config} config
 * @constructor
 */
function AiAuthConfig(config) {
  Object.assign(this, config.get(CONFIG_AI_AUTH) || {})
  return this;
}

export default AiAuthConfig

AiAuthConfig.prototype.getRedirectUrl = function(){
  return this.redirectUrl
}

AiAuthConfig.prototype.isValid = function(){
  return !!this.getRedirectUrl()
}