import { Thumbnail } from 'components/Global/ProductItem'

const MobileHorizontalWatchlistSection = ({ companies, listType, hideTag, onItemClick, showWatchList = false }) => (
  <div className={`py-4 ${companies.length > 1 && '-mx-6'}`}>
    <div className='overflow-x-auto whitespace-nowrap scroll-smooth' style={{ WebkitOverflowScrolling: 'touch', scrollbarWidth: 'none' }}>
      <div className='inline-flex gap-4 space-x-4'>
        {companies.map((item, i) => <div key={`list_${i}`} className={i === companies.length - 1 ? '!mr-2 !pr-4' : i === 0 ? 'ml-6' : null}>
          <Thumbnail listType={listType} item={item} hideTag={hideTag} onItemClick={onItemClick} index={i} showWatchList={showWatchList} />
        </div>)}
      </div>
    </div>
  </div>
)
export default MobileHorizontalWatchlistSection
