import React from 'react'
import { formatDecimal } from 'utils'
import { images } from 'assets'
import { useHistory } from 'react-router-dom'

const CashBalanceItem = ({ totalCash, groupBy, entityId, hasEntities }) => {
  const history = useHistory()
  let urlName = ''
  if (groupBy === 'investment') {
    if (hasEntities) {
      urlName = '/linqto-wallet'
    } else {
      urlName = '/cash-account'
    }
  } else {
    urlName = `/cash-account${entityId ? `?entityId=${entityId}` : ''}`
  }
  return (
    <div className='border border-stroke-on-white-light current-investment-item' onClick={() => history.push(urlName)} role='button' tabIndex={0} aria-label='Cash Balance'>
      <div className='current-investment-item_full'>
        <div className='current-investment-item_left'>
          <img src={images['cash-icon-dollar']} alt='Cash Balance' />
          <span className='medium_1'>Cash Balance</span>
        </div>
        <div className='current-investment-item_right'>
          <div className='current-investment-item_right shares-total-price-container'>
            <span className='current-investment-item_right shares'> </span>
            <span className='medium_2 current-investment-item_right total-price'>{formatDecimal(totalCash)}</span>
          </div>
          <img className='current-investment-item_right tab-arrow' src={images['right-arrow']} alt='Cash Balance'/>
        </div>
      </div>
    </div>
  )
}

export default CashBalanceItem
